import { Select, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import {
    assignCase
} from "../../../../../../../Adapters/AdminCalls";
import { userState } from "../../../../../../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { usersList } from "../../../../../../../RecoilState/usersList";
import { useFetchUserData } from "../../../UserHooks/useFechUserDataAdmin";
import { useNavigate } from "react-router";

const { Option } = Select;

const MergeCaseOfUser = ({
    user,
    role,
    onMergeConfirmed,
    onCancel,
    setModalFooter,
    disabled,
    navigateToSelection,
    RoleClubbedUsers
}) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const { fetchUserData } = useFetchUserData();

    let navigate = useNavigate();

    useEffect(() => {
        if (isConfirming) {
            setModalFooter(
                <>
                    <Button
                        onClick={() => {
                            onCancel();
                            navigateToSelection();
                        }}>
                        Cancel
                    </Button>
                    <Button type="primary" onClick={handleMerge}>
                        Confirm
                    </Button>
                </>
            );
        } else if (isConfirming === false) {
            setModalFooter(
                <>
                    <Button
                        onClick={() => {
                            navigateToSelection();
                        }}>
                        Back
                    </Button>
                    <Button
                        disabled={
                            !RoleClubbedUsers || RoleClubbedUsers.length === 0
                        }
                        type="primary"
                        onClick={() => {
                            setIsConfirming(true);
                        }}>
                        Next
                    </Button>
                </>
            );
        } else {
            setModalFooter(null); // Reset to your default footer or whatever action you need here
        }
    }, [isConfirming, RoleClubbedUsers]);

    const handleUserChange = (userId) => {
        const user = RoleClubbedUsers.find((u) => u.Email === userId);
        setSelectedUser(user);
    };

    const handleMerge = () => {
        const matchingRole = selectedUser?.roles.find(
            (userRole) =>
                userRole.role === role.role &&
                userRole.factory_fk === role.factory_fk
        );

        if (matchingRole) {
            assignCase("merge", role.id, matchingRole.id)
                .then((response) => {
                    if (response.data?.message) {
                        message.success(response.data.message);
                        onMergeConfirmed();
                        fetchUserData();
                        setIsConfirming(false);
                        setSelectedUser(null);
                        navigate("/AdminTableView?Key=3");
                    } else {
                        message.error(
                            response?.data?.errorMessage ||
                                "An error occurred while merging the cases."
                        );
                        onCancel();
                        navigateToSelection();
                    }
                })
                .catch((error) => {
                    message.error(error?.response?.data?.errorMessage);
                    onCancel();
                    throw error;
                });
        } else {
            console.error("Matching role not found for selected user");
        }
    };

    if (isConfirming) {
        return (
            <div>
                {user.email} will no longer be able to access the Dashboard of{" "}
                {role.Code} - {role.Location} as a {role.role}
            </div>
        );
    }

    return (
        <div>
            Select the User you want to merge the case of this user with
            <Select
                style={{ width: 300 }}
                disabled={disabled}
                showSearch
                placeholder="Search Email"
                optionFilterProp="children"
                onChange={handleUserChange}>
                {!RoleClubbedUsers || RoleClubbedUsers.length > 0 ? (
                    RoleClubbedUsers?.map((filteredUser) => (
                        <Option
                            key={filteredUser.id}
                            value={filteredUser.Email}>
                            {filteredUser.username} ({filteredUser.Email})
                        </Option>
                    ))
                ) : (
                    <Option value="noUser" disabled>
                        No other users exist
                    </Option>
                )}
            </Select>
        </div>
    );
};

export default MergeCaseOfUser;
