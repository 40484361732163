import React from "react";
import styles from "../../CaseReport.module.css";

export default function CaseReportTabItem({ tabName, tabValue }) {
    let newTabValue = tabValue;
    let newTabName = tabName;

    if (tabName === "Date") {
        newTabValue = tabValue?.split("T")[0];
    } else if (tabName === "Time") {
        newTabValue = tabValue?.substring(11, 16);
        newTabName = "Time";
    } else if (tabName === "CaseStatus") {
        newTabName = "Status";
        newTabValue = tabValue;
    } else if (tabName === "CaseNumber") {
        newTabName = "Case Number";
    } else if (tabName === "SMS_Data") {
        newTabName = "SMS Content";
    } else if (tabName === "ReportingMedium") {
        newTabName = "Medium";
    }
    return (
        <div className={styles.tabItem}>
            <h4>{newTabName}</h4>
            <p>{newTabValue}</p>
        </div>
    );
}
