import { Col, Row, Button, message, Spin } from "antd";
import Dropdown from "../../@setproduct-ui/core/Dropdown";
import Layout from "antd/lib/layout/layout";
import React from "react";
import CollapsableMenu from "../../components/CollapsableMenu";
import ViewCalendar from "./ViewCalendar";
import { Link } from "react-router-dom";
import ButtonCard from "../../components/Home/TableContainer/ButtonContainer/ButtonCard";
import { useState, useEffect } from "react";
import BulkUploadCalender from "./UploadCalender/BulkUpload";
import { selectedMonthYearRecoil } from "../../RecoilState/HolidayCalender/selectedMonthYearRecoil";
import { useRecoilState } from "recoil";
import { common_axios } from "../../utils/AxiosSettings";
import FactoryListMenu from "../../components/FactoryListMenu";
import { userState } from "../../RecoilState/userState";
import { factoryState } from "../../RecoilState/FactoryState";
import useEventMetricsUpdater from "../../hooks/useEventMetricsUpdater";
import { FactoryData } from "../../RecoilState/FactoryData";
import { debounce } from "lodash";

const HolidayCalendar = () => {
    const [uploadHolidayFlag, setUploadHolidayFlag] = useState(false);
    const [selectedMonthYear, setSelectedMonthYear] = useRecoilState(
        selectedMonthYearRecoil
    );
    const [user] = useRecoilState(userState);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const { updateEventMetrics } = useEventMetricsUpdater();
    useEffect(() => {
        // Factory API call
        if (!FactorySelected) {
            if (user.role !== "REGIONAL_ADMIN" && user.role !== "SUPER_ADMIN") {
                setFactorySelected({
                    id: user.factory_fk,
                });
            } else {
                setFactorySelected(FactoryDataList[0]);
            }
        }
    }, [user]);

    function setUploadFalse() {
        setUploadHolidayFlag(false);
    }
    useEffect(() => {
        //setting filter value as default current month and year
        const currentDate = new Date();
        const defaultMonthYear = currentDate
            .toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-");
        setSelectedMonthYear(defaultMonthYear);
    }, [uploadHolidayFlag]);

    const handleClick = async (eventName, actionType) => {
        const apiUrl = selectedMonthYear.includes("-")
            ? `${process.env.REACT_APP_BASE_URL_API}/api/accounts/downloadPDF?month=${selectedMonthYear}&Factory=${FactorySelected?.id}`
            : `${process.env.REACT_APP_BASE_URL_API}/api/accounts/downloadPDF?year=${selectedMonthYear}&Factory=${FactorySelected?.id}`;

        try {
            // Make the API request using Axios
            const response = await common_axios.get(apiUrl, {
                responseType: "blob",
            });
            message.info("The Selected Dates Holidays CSV is being Downloaded");
            // Trigger the download by creating a temporary link element
            const downloadUrl = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `${selectedMonthYear}-HolidayCalender.pdf`;
            link.click();
            updateEventMetrics(eventName, actionType);
        } catch (error) {
            // Handle any network or other errors
            message.error(`Request Error: ${error.message}`);
        }
    };
    const debouncedhandler = debounce(handleClick, 500);
    return (
        <>
            <Layout style={{ display: "flex", flexDirection: "row" }}>
                <CollapsableMenu></CollapsableMenu>

                <div style={{ width: "100%" }}>
                    <Row
                        justify="space-between"
                        style={{
                            margin: "1rem 1% 0 0",
                        }}>
                        <Col span={6}>
                            <h1
                                style={{
                                    textAlign: "start",
                                    padding: "1rem 0 0 2rem",
                                }}>
                                Holiday Calendar
                            </h1>
                        </Col>

                        <Col flex={3}>
                            <Row justify="end" gutter={[8, 8, 8]}>
                                {" "}
                                <Col>
                                    {!uploadHolidayFlag &&
                                        (user.role === "SUPER_ADMIN" ||
                                            user.role === "REGIONAL_ADMIN") && (
                                            <div
                                                style={{
                                                    float: "right",
                                                    marginRight: "1rem",
                                                    marginTop: "1.2rem",
                                                }}>
                                                {FactoryDataList ? (
                                                    <Dropdown
                                                        style={{
                                                            height: "fit-Content",
                                                            padding: "1.3rem",
                                                        }}
                                                        view="filled"
                                                        color="primary"
                                                        text={
                                                            FactorySelected &&
                                                            `${FactorySelected?.Code} - ${FactorySelected?.Location}`
                                                        }
                                                        position="bottom-left"
                                                        content={
                                                            <FactoryListMenu
                                                                factoryData={
                                                                    FactoryDataList
                                                                }
                                                            />
                                                        }
                                                        dense="false"
                                                        //className={styles.dropdown}
                                                    />
                                                ) : (
                                                    <Spin />
                                                )}
                                            </div>
                                        )}
                                </Col>
                                <Col>
                                    {!uploadHolidayFlag && (
                                        <Col>
                                            <div
                                                style={{
                                                    float: "right",
                                                    marginRight: "1 rem",
                                                }}
                                                onClick={() => {
                                                    debouncedhandler(
                                                        "holidayCalendarDownload",
                                                        "Holiday Calendar download"
                                                    );
                                                }}>
                                                <ButtonCard
                                                    key="Download"
                                                    text="Download"
                                                    colorLogo="purple"
                                                    icon="/assets/images/Incentive/download.svg"
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Col>
                                {(user.role === "SUPER_ADMIN" ||
                                    user.role === "REGIONAL_ADMIN") && (
                                    <Col>
                                        {!uploadHolidayFlag && (
                                            <Col>
                                                <div
                                                    style={{
                                                        float: "right",
                                                        marginRight: "1 rem",
                                                    }}
                                                    onClick={() => {
                                                        setUploadHolidayFlag(
                                                            true
                                                        );
                                                    }}>
                                                    <ButtonCard
                                                        key="Upload Bulk Holiday "
                                                        text="Bulk Upload"
                                                        colorLogo="purple"
                                                        icon="/assets/images/AwarenessProgramme/upload.svg"
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {uploadHolidayFlag === false ? (
                                <ViewCalendar
                                    FactorySelected={FactorySelected?.id}
                                />
                            ) : (
                                <BulkUploadCalender
                                    FactorySelected={FactorySelected?.id}
                                    setUploadFalse={setUploadFalse}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </Layout>
        </>
    );
};

export default HolidayCalendar;
