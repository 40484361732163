import { Col, Image, Row } from "antd";
import React from "react";
import DateTimeFormatter from "../../../../components/Utils/DateTimeFormatter";
import DocumentViewer from "../../../../components/UploadComponent/ShowDocHeaderFile";

const AwarenessModal = ({ awerenessModalData }) => {
    return (
        <>
            <Row style={{ padding: "2rem .5rem" }}>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <b>Event Name:</b>
                        </Col>
                        <Col span={12}>
                            <p
                                style={{
                                    borderBottom: "1px solid gray",
                                    margin: "0 1rem 1rem 0",
                                }}>
                                {awerenessModalData?.programName}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <b>Start Date & Time:</b>
                        </Col>
                        <Col span={12}>
                            <p
                                style={{
                                    borderBottom: "1px solid gray",
                                    margin: "0 1rem 1rem 0",
                                }}>
                                <DateTimeFormatter
                                    dateTimeString={awerenessModalData?.Date}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <b>Duration (in mins):</b>
                        </Col>
                        <Col span={12}>
                            <p
                                style={{
                                    borderBottom: "1px solid gray",
                                    margin: "0 1rem 1rem 0",
                                }}>
                                {awerenessModalData?.programDuration}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <b>No. of participants:</b>
                        </Col>
                        <Col span={12}>
                            <p
                                style={{
                                    borderBottom: "1px solid gray",
                                    margin: "0 1rem 1rem 0",
                                }}>
                                {awerenessModalData?.participants}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <b>Agenda:</b>
                        </Col>
                        <Col span={12}>
                            <p
                                style={{
                                    borderBottom: "1px solid gray",
                                    margin: "0 1rem 1rem 0",
                                }}>
                                {awerenessModalData?.Agenda}
                            </p>
                        </Col>
                    </Row>
                    {awerenessModalData?.Description === "" ? (
                        <Row>
                            <Col span={12}>
                                <b>Description:</b>
                            </Col>
                            <Col span={12}>-</Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={12}>
                                <b>Description:</b>
                            </Col>
                            <Col span={12}>
                                <p
                                    style={{
                                        borderBottom: "1px solid gray",
                                        margin: "0 1rem 1rem 0",
                                    }}>
                                    {awerenessModalData?.Description}
                                </p>
                            </Col>
                        </Row>
                    )}
                </Col>
                <Col span={12}>
                    <h3>Photos:</h3>

                    <DocumentViewer
                        document_delete_on={false}
                        document_download_on={false}
                        documentIds={awerenessModalData?.Files}
                        model="awareness_program"
                        model_id={awerenessModalData?.id}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AwarenessModal;
