import React, { useState } from "react";
import styles from "../QualityChecker.module.css";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, message } from "antd";
import { Modal } from "antd";
import { Select } from "antd";
import { QCcaseReopener } from "../../../Adapters/QCcalls";

const { Option } = Select;

export default function ReopenCase({ setreopen, reopen, caseData }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [ModalText, setModalText] = useState(
    "Are you sure you want to reopen this case ?"
  );
  const onFinish = (values) => {
    setFormData(values);
    setModalText("Are you sure you want to reopen this case ?");
    showModal();
  };

  const onFinishFailed = (errorInfo) => {
    setModalText(
      "To reopen the case, enter all the fields (Tone of conversation, Quality of evidence, Internal communication & Remarks)"
    );
    showModal();
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {

    if (
      formData.ToneOfConversation &&
      formData.InternalCommunication &&
      formData.QualityOfEvidence &&
      formData.Remarks
    ) {
      QCcaseReopener(formData, caseData.id)
        .then((res) => {
          message.success("Case Reopened Successfully");
          setIsModalVisible(false);
        })
        .catch((err) => {
          message.error("Case reopen failed ! Please try again later !");
        });
    } else {
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (reopen) {
    return (
      <div>
        <Modal
          title="Reopen Case"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ color: "#4318FF" }}
        >
          <p>{ModalText}</p>
        </Modal>

        <>
          <Link to="/home" className="backBtn margin-btn">
            <img src="/assets/images/back/keyboard_arrow_left_24px.svg" />
            <span>Back</span>
          </Link>
          <div className={styles.QC}>
            <div className={styles.Reopencontainer}>
              <h1>Reopen Case</h1>
              <div className={styles.ReopenmainContainer}>
                {["CaseNumber", "Date", "Time"].map((single, index) => {
                  return (
                    <div className={styles.item}>
                      <div className={styles.left}>{single}</div>
                      <div className={styles.right}>{caseData[single]}</div>
                    </div>
                  );
                })}
                <Form
                  labelAlign="left"
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <div className={styles.item + " " + styles.itemInput}>
                    <Form.Item
                      label="Tone of Conversation"
                      name="ToneOfConversation"
                      rules={[
                        {
                          required: true,
                          message: "Please input Tone of Conversation!",
                        },
                      ]}
                    >
                      <Select style={{ width: 300 }}>
                        <Option value="Satisfactory">Satisfactory</Option>
                        <Option value="Unsatisfactory">Unsatisfactory</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={styles.item + " " + styles.itemInput}>
                    <Form.Item
                      label="Internal Communication"
                      name="InternalCommunication"
                      rules={[
                        {
                          required: true,
                          message: "Please input Internal Communication!",
                        },
                      ]}
                    >
                      <Select style={{ width: 300 }}>
                        <Option value="Satisfactory">Satisfactory</Option>
                        <Option value="Unsatisfactory">Unsatisfactory</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={styles.item + " " + styles.itemInput}>
                    <Form.Item
                      label="Quality Of Evidence"
                      name="QualityOfEvidence"
                      rules={[
                        {
                          required: true,
                          message: "Please input Quality Of Evidence!",
                        },
                      ]}
                    >
                      <Select style={{ width: 300 }}>
                        <Option value="Satisfactory">Satisfactory</Option>
                        <Option value="Unsatisfactory">Unsatisfactory</Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className={styles.item + " " + styles.itemInput}>
                    <Form.Item
                      label="Remarks"
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          message: "Please input Remarks!",
                        },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </div>

                  <div className="buttons">
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                    <button
                      onClick={() => {
                        setreopen(false);
                      }}
                      className="grey button"
                    >
                      Cancel{" "}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  } else {
    return null;
  }
}
