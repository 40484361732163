import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { availableUserRoleStates } from "../../../RecoilState/GlobalUserRoleStates/availableUserRoleStates";
import { currentSelectedRoleState } from "../../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import RoleToken from "../../../pages/Login/RoleSelectModal/RoleTokenGetter";
import { tokenState } from "../../../RecoilState/tokenState";
import { useNavigate } from "react-router";
import { Select } from "antd";
import { userState } from "../../../RecoilState/userState";
import { previousRouteData } from "../../../RecoilState/previousRouteData";
import { userMetricState } from "../../../RecoilState/userMerticState";
import { factoryState } from "../../../RecoilState/FactoryState";

const RolesDropdown = () => {
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const [availableUserRoles, setAvailableUserRoles] = useRecoilState(
        availableUserRoleStates
    );
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [isLoading, setIsLoading] = useState("null");
    const [token, setToken] = useRecoilState(tokenState);
    const [roleChanged, setRoleChanged] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    const navigate = useNavigate();
    const { Option } = Select;
    const [userMetricData, setUserMetricData] = useRecoilState(userMetricState);

    const prevUser = useRef();
    const timeRef = useRef();
    // Check if userMetrics.email is empty this will help on refresh the data will not get lost
    if (!userMetricData?.userMetrics?.loginTimestamp) {
        // Retrieve user metric state from local storage
        const storedUserMetricState = JSON.parse(
            localStorage.getItem("userMetricState")
        );

        // Update the user metric state with the local storage data
        setUserMetricData(storedUserMetricState);
    }
    useEffect(() => {
        if (isLoading === "done") {
            navigate("/home", { state: { reset: true } });
            setIsLoading("null");
            setRoleChanged(false); // Reset roleChanged when done
        }
    }, [isLoading, currentSelectedRole]);

    useEffect(() => {
        const storedSelectedRole = JSON.parse(
            localStorage.getItem("current-selected-role")
        );
        if (storedSelectedRole) {
            setCurrentSelectedRole(storedSelectedRole);
        }
    }, [localStorage.getItem("current-selected-role")]);

    useEffect(() => {
        setUser((prevUserDetails) => ({
            ...prevUserDetails,
            factory_fk: currentSelectedRole?.factory_fk,
            role: currentSelectedRole?.role,
            group_permissions: currentSelectedRole?.group_permissions,
            user_permissions: currentSelectedRole?.user_permissions,
            roleId: currentSelectedRole?.roleId,
            code: currentSelectedRole?.Code,
            region: currentSelectedRole?.region,
            region_fk: currentSelectedRole?.region_fk, //added region_fk in global user state
        }));
        // If user is SUPER_ADMIN or REGIONAL_ADMIN and FactorySelected is not set
        if (
            currentSelectedRole?.role === "SUPER_ADMIN" ||
            currentSelectedRole?.role === "REGIONAL_ADMIN"
        ) {
            setFactorySelected();
        }
    }, [currentSelectedRole]);
    useEffect(() => {
        if (user.email && token.expires>=parseInt(Date.now()/1000)) {
            setUserMetricData((prevUserMetricState) => {
                const roleId = user?.roleId;
                const userRole = user?.role;
                const newTimestamp = timeRef.current
                    ? timeRef.current
                    : new Date().toUTCString();
                timeRef.current = null;
                // Check if the user or role has changed
                if (
                    prevUser.current?.roleId === roleId &&
                    prevUser.current?.role === userRole &&
                    prevUser.current?.email === user?.email
                ) {
                    // User or role hasn't changed, return the previous state
                    return prevUserMetricState;
                }

                // Update the ref with the current user
                prevUser.current = {
                    roleId,
                    role: userRole,
                    email: user?.email,
                };
                // Check if the role with the given roleID already exists
                const existingRoleIndex =
                    prevUserMetricState?.userRoles?.findIndex(
                        (role) =>
                            role.roleID === roleId && role.role === userRole
                    );
                if (existingRoleIndex !== -1) {
                    // Role with the given roleID already exists, update roleActive
                    const updatedUserRoles = [...prevUserMetricState.userRoles];
                    updatedUserRoles[existingRoleIndex] = {
                        ...updatedUserRoles[existingRoleIndex],
                        roleActive: [
                            ...(updatedUserRoles[existingRoleIndex]
                                ?.roleActive || []),
                            newTimestamp,
                        ],
                    };
                    const updatedUserMetricState = {
                        ...prevUserMetricState,
                        userMetrics: {
                            ...prevUserMetricState.userMetrics,
                            logoutTimestamp: new Date(token?.expires * 1000).toUTCString(),
                        },
                        userRoles: updatedUserRoles,
                    };

                    // Set the updated user metric state in local storage
                    localStorage.setItem(
                        "userMetricState",
                        JSON.stringify(updatedUserMetricState)
                    );

                    return updatedUserMetricState;
                } else if (user?.roleId) {
                    // Role with the given roleID doesn't exist, add a new role object
                    const newRole = {
                        role: user?.role,
                        roleID: user?.roleId,
                        factoryCode: user?.factory_fk,
                        region: user?.region_fk,
                        company: user?.company_fk,
                        roleActive: [newTimestamp],
                    };
                    const updatedUserMetricState = {
                        ...prevUserMetricState,
                        userMetrics: {
                            ...prevUserMetricState.userMetrics,
                            logoutTimestamp: new Date(token?.expires * 1000).toUTCString()
                        },
                        userRoles: [...prevUserMetricState?.userRoles, newRole],
                    };

                    // Set the updated user metric state in local storage
                    localStorage.setItem(
                        "userMetricState",
                        JSON.stringify(updatedUserMetricState)
                    );

                    return updatedUserMetricState;
                }

                // If user?.role is not present, return the previous state
                return prevUserMetricState;
            });
        } else {
            timeRef.current = new Date().toUTCString();
        }
    }, [user]);
    return (
        <>
            {roleChanged &&
                currentSelectedRole && ( // Check roleChanged in addition to selectedRole
                    <RoleToken
                        role_id={currentSelectedRole.id}
                        tokens={token}
                        setToken={setToken}
                        loading={isLoading}
                        setter={setIsLoading}
                    />
                )}
            <Select
                style={{ width: 250 }}
                placeholder="Select a role"
                value={currentSelectedRole ? currentSelectedRole.id : undefined}
                onChange={(value) => {
                    setPreviousRoute((prev) => ({
                        defaultRoute: prev.defaultRoute,
                        activeTab: null,
                    }));
                    const selected = availableUserRoles.find(
                        (role) => role.id === value
                    );
                    setCurrentSelectedRole(selected);
                    localStorage.setItem(
                        "current-selected-role",
                        JSON.stringify(selected)
                    );
                    setRoleChanged(true);
                    setIsLoading("happening");
                }}>
                {Object.values(availableUserRoles).map((role) => (
                    <Option key={role.id} value={role.id}>
                        {`${role.role} `}
                        {role.Code && ` - ${role.Code}`}
                        {role.Location && ` ${role.Location}`}
                        {!role.Location &&
                            !role.Code &&
                            role.region &&
                            `- ${role.region}`}
                    </Option> // this will be refactor once factory_fk will be removed for RA and SA
                ))}
            </Select>
        </>
    );
};

export default RolesDropdown;
