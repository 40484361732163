export const NestedCategories = {
    Canteen: {
        "Canteen food": [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],
        "Canteen cleanliness & infrastructure": [
            "Minor Grievance (Internal)",
            "Minor Grievance (External)",
        ],
        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    "Workplace Maintenance": {
        "Factory temperature & conditions": [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],

        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    "Machine Maintenance": {
        "Machine maintenance": [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],
        PPE: ["Minor Grievance (Internal)", "Minor Grievance (External)"],

        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    "Works and Grievance": {
        "Shop Floor cleanliness": [
            "Minor Grievance (Internal)",
            "Minor Grievance (External)",
        ],
        "Washroom cleanliness": [
            "Minor Grievance (Internal)",
            "Minor Grievance (External)",
        ],
        "Dispensary facilities": [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],
        Leave: ["Medium Grievance (Internal)", "Medium Grievance (External)"],
        Absenteeism: [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],
        "Conflict with People Officer": ["Major Grievance (Level 1)"],
        "Conflict with co-worker": ["Major Grievance (Level 1)"],
        "Welfare schemes": [
            "Minor Grievance (Internal)",
            "Minor Grievance (External)",
        ],
        "Other facilities": [
            "Minor Grievance (Internal)",
            "Minor Grievance (External)",
        ],
        Transport: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
        Dormitory: ["Minor Grievance (Internal)", "Minor Grievance (External)"],

        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    "Banking and Salary": {
        PF: ["Medium Grievance (Internal)", "Medium Grievance (External)"],
        ESI: ["Medium Grievance (Internal)", "Medium Grievance (External)"],
        "Full and final": [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],
        "Compensation & Benefits": [
            "Medium Grievance (Internal)",
            "Medium Grievance (External)",
        ],
        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    POSH: {
        "Sexual harassment": ["Major Grievance (Level 1)"],
        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    "Special Cases": {
        "Case against influential managers": ["Major Grievance (Level 1)"],
        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
    Others: {
        Others: ["Minor Grievance (Internal)", "Minor Grievance (External)"],
    },
};
