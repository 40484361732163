import React, { useEffect } from "react";
import { message, Button } from "antd"; // Assuming you're using antd for messages
import { assignCase } from "../../../../../../../Adapters/AdminCalls";
import { useLocation } from "react-router";
import { useFetchUserData } from "../../../UserHooks/useFechUserDataAdmin";

const SplitCaseOfUser = ({
    fromRole,
    roleName,
    user,
    fetcher,
    setModalFooter,
    navigateToSelection,
    onCancel,
}) => {
    const { fetchUserData } = useFetchUserData();

    useEffect(() => {
        const footerContent = [
            <Button
                key="back"
                onClick={() => {
                    navigateToSelection();
                }}>
                Back
            </Button>,
            <Button
                onClick={() => {
                    submitSplitCase(fromRole);
                }}>
                Yes, I'm sure
            </Button>,
        ];

        setModalFooter(footerContent);
        return () => setModalFooter(null);
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    const submitSplitCase = async (fromRole) => {
        try {
            const res = await assignCase("split", fromRole);

            if (res?.data?.message) {
                message.success(res.data.message);
                fetcher();
                // if (user.role === {}) {
                //     navigate("/AdminTableView?key=1");
                // }
                onCancel();
                navigateToSelection();
            } else if (res?.data?.message_body) {
                message.success(res.data.message_body);
                onCancel();
                navigateToSelection();
                fetcher();
            } else if (res?.data?.errorMessage) {
                message.error(res?.data?.errorMessage);
                onCancel();
                navigateToSelection();
            } else {
                message.error(
                    res?.data?.errorMessage ||
                        "An error occurred while splitting the case."
                );
                onCancel();
                navigateToSelection();
            }
            fetchUserData();
        } catch (error) {
            message.error(error?.response?.data?.errorMessage);
            onCancel();
            navigateToSelection();
        }
    };

    return (
        <div>
           {`Are you sure about this? This action will revoke access from the
            current user and split the cases among all other ${roleName}s`}
        </div>
    );
};

export default SplitCaseOfUser;
