import { useState, useEffect } from "react";
import React from "react";
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
    Select,
    InputNumber,
    Button,
    Modal,
    message,
} from "antd";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { Upload } from "antd";
import { common_axios } from "../../../../utils/AxiosSettings";
import { useNavigate } from "react-router";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { EditOutlined } from "@ant-design/icons";
import { getAllFactoriesOfCompany } from "../../../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../../../RecoilState/tokenState";
import styles from "../../AdminDashboard.module.css";
import { userState } from "../../../../RecoilState/userState";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { useFetchUserData } from "../../UserDashboard/UserAction/UserHooks/useFechUserDataAdmin";
import useFetchRegion from "../../RegionDashboard/RegionHooks/FetchRegionHook";

const { Dragger } = Upload;
const { Option } = Select;

const EditFactoryButton = ({ props }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [file, setFile] = useState(null);
    const [FactoryDataForm] = Form.useForm();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [user, setUser] = useRecoilState(userState);
    let navigate = useNavigate();
    const [factorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    const [token, setToken] = useRecoilState(tokenState);
    const [fileError, setFileError] = useState(null);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const { fetchUserData } = useFetchUserData();
    const { fetchRegionData } = useFetchRegion();
    useEffect(() => {
        FactoryDataForm.setFieldsValue({
            Company: props.Company,
            Code: props.Code,
            Location: props.Location,
            Number: props.Number,
            requiredAwarenessProgram: props.requiredAwarenessProgram,
        });
    }, [props]);

    function refreshData() {
        getAllFactoriesOfCompany(
            props.Company_id,
            token.access,
            user.role === "REGIONAL_ADMIN" && "region"
        ).then((res) => {
            setFactoryList(res.data.message_body.Factories);
        });
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async (eventName, actionType) => {
        await handleFactoryDataFormSubmit();
        setIsModalVisible(false);
        refreshFields();
        await fetchUserData();
        await fetchRegionData();
        updateEventMetrics(eventName, actionType);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        refreshFields();
        setFile(null);
    };

    function refreshFields() {
        FactoryDataForm.setFieldsValue({
            Company: props.Company,
            Code: props.Code,
            Location: props.Location,
            Number: props.Number,
            Region: props.Region,
        });
    }

    function handleFactoryDataFormSubmit() {
        if (isFormChanged) {
            const processWithoutFile = () => {
                const submitData = {
                    Company: props.Company_id,
                    Code: FactoryDataForm.getFieldValue("Code"),
                    Location: FactoryDataForm.getFieldValue("Location"),
                    Number: FactoryDataForm.getFieldValue("Number"),
                    // Region: FactoryDataForm.getFieldValue("Region"), not required for RA and SA
                    requiredAwarenessProgram: FactoryDataForm.getFieldValue(
                        "requiredAwarenessProgram"
                    ),
                };

                common_axios
                    .put(
                        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/?id=${props.id}`,
                        submitData
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            message.success(response.data.message);
                            setIsModalVisible(false);
                            refreshData();
                        } else {
                            message.error(
                                "An error occurred while processing your request."
                            );
                            refreshData();
                        }
                    })
                    .catch((error) => {
                        message.error(
                            error?.response?.data?.errorMessage ||
                                "An error occurred."
                        );
                        //  refreshData();
                    });
            };

            if (file) {
                if (file.type === "text/csv") {
                    processWithoutFile(); // Process other form data first.

                    const formData = new FormData();
                    formData.append("csv_file", file);
                    formData.append("Company", factorySelected.Company_id);
                    formData.append("Factory", props.id);
                    common_axios
                        .post(
                            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/complainers/`,
                            formData
                        )
                        .then((response2) => {
                            message.success(response2?.data?.message);
                            setIsModalVisible(false);
                            refreshData();
                        })
                        .catch((error) => {
                            message.error(error?.response?.data?.errorMessage);
                            console.error(error?.response?.data?.errorMessage);
                            //  refreshData();
                        });
                } else {
                    message.error(
                        "Please upload Master Data File in CSV format"
                    );
                }
            } else {
                processWithoutFile();
            }
        } else {
            message.info("No changes were made, Thank you");
        }
    }

    function handleFormChange() {
        setIsFormChanged(true);
    }

    const onFileChange = (info) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
            const file = fileList[0].originFileObj;
            setFile(file);
        } else {
            setFile(null);
            setFileError(null); // Reset the error when no file is selected
        }
    };

    return (
        <>
            <Button
                icon={<EditOutlined />}
                type="text"
                onClick={showModal}></Button>
            <Modal
                title="Edit Factory"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="cancel"
                        onClick={handleCancel}
                        className={styles.outlineButton}>
                        Cancel
                    </Button>,
                    <Button
                        key="ok"
                        onClick={() =>
                            handleOk(
                                "editFactory",
                                "Edit Factory- Save Changes"
                            )
                        }
                        className={styles.solidButton}>
                        Save Change
                    </Button>,
                ]}
                width={1400}
                closeIcon>
                <div>
                    <Form
                        name="User Detail Form"
                        form={FactoryDataForm}
                        onValuesChange={handleFormChange}
                        onFinish={handleFactoryDataFormSubmit}
                        initialValues={props}>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item label="Company" name="Company">
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Factory Number"
                                    name="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Factory Number",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9]+$/,
                                            message:
                                                "Factory Number must be alphanumeric",
                                        },
                                    ]}>
                                    <Input
                                        disabled={
                                            user.role === "SUPER_ADMIN" ||
                                            user.role === "REGIONAL_ADMIN"
                                                ? false
                                                : true
                                        }
                                        maxLength={10}></Input>
                                </Form.Item>
                                {/* removing this code block in edit factory as Region field not required for any user */}
                                {/* {user.role === "SUPER_ADMIN" ||
                                user.role === "REGIONAL_ADMIN" ? null : (
                                    <Form.Item
                                        label="Region"
                                        name="Region"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select a Region",
                                            },
                                        ]}>
                                        <Select disabled>
                                            <Option key="North" value="North">
                                                {"North"}
                                            </Option>
                                            <Option key="South" value="South">
                                                {"South"}
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                )} */}
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Upload Master Data"
                                    name="csv_file"
                                    help={fileError} // This will display the error message
                                    validateStatus={fileError ? "error" : ""} // This changes the outline color to red if there's an error
                                >
                                    <Dragger
                                        accept=".csv"
                                        beforeUpload={(file) => {
                                            if (file.type !== "text/csv") {
                                                setFileError(
                                                    "Please upload a correct format of Master Data File, required format is CSV"
                                                );
                                                return false;
                                            } else {
                                                setFileError(null);
                                                setFile(file);
                                                return false; // prevent automatic upload
                                            }
                                        }}
                                        onChange={onFileChange}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to
                                            upload
                                        </p>
                                        <p className="ant-upload-hint">
                                            Support for a single CSV file
                                            upload.
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Location"
                                    name="Location"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Location",
                                        },
                                    ]}>
                                    <Input
                                        disabled={
                                            user.role === "SUPER_ADMIN" ||
                                            user.role === "REGIONAL_ADMIN"
                                                ? false
                                                : true
                                        }
                                        value={props.Location}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                style={{
                                    width: "50%",
                                }}>
                                <Button
                                    className={styles.outlineButton}
                                    onClick={() => {
                                        const url = `/assets/Doc/MasterDataTemplate.csv`;
                                        const link =
                                            document.createElement("a");
                                        link.href = url;
                                        link.download = "template.csv";
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}>
                                    <DownloadOutlined />
                                    Download Sample Template
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Inache Number"
                                    name="Number"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select an Inache Number",
                                        },
                                    ]}>
                                    <Input
                                        value={props.Number}
                                        disabled></Input>
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Mandatory Awareness Programme"
                                    name="requiredAwarenessProgram"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter no of Mandatory Awareness Programme",
                                        },
                                        {
                                            pattern: /^[0-9]+$/,
                                            message:
                                                "Please enter a valid number",
                                        },
                                        {
                                            type: "number",
                                            max: 99,
                                            message:
                                                "Please enter a number below 100",
                                        },
                                        {
                                            type: "number",
                                            min: 0,
                                            message:
                                                "Please enter a positive number",
                                        },
                                    ]}>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        maxLength={3}
                                        min={0}
                                        max={99}
                                        placeholder="00"
                                        pattern="[0-9]*"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default EditFactoryButton;
