import React, { useState } from "react";
import { Tabs, Row, Col, Layout } from "antd";
import styles from "../AdminDashboard.module.css";
import CollapsableMenu from "../../CollapsableMenu";
import { useEffect } from "react";
import LoggedInComponent from "../../../containers/LoggedInComponent/LoggedInComponent";
import CreateFactory from "./CreateFactory/index.jsx";
import CreateUser from "./CreateUser/index.jsx";
import { useRecoilState } from "recoil";
import { FactoryData } from "../../../RecoilState/FactoryData";
import { userState } from "../../../RecoilState/userState";
import { Link, useLocation } from "react-router-dom";
import { availableRoles } from "../../../RecoilState/availableRoles";
import { getAllRoles, getRegionData } from "../../../Adapters/AdminCalls";
import { tokenState } from "../../../RecoilState/tokenState";
const { TabPane } = Tabs;
const CreateUserFactoryView = () => {
    const [user, setUser] = useRecoilState(userState);
    const location = useLocation();
    const [assignedRoles, setAssignedRoles] = useRecoilState(availableRoles);
    const [activeKey, setActiveKey] = useState(
        location.search.split("=")[1] || "1"
    ); // default to key 1 if not specified
    const [token, setToken] = useRecoilState(tokenState);
    const [regionData, setRegionData] = useState([]);
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const handleTabChange = (key) => {
        setActiveKey(key);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRegionData();
                setRegionData(res.data?.message_body.Regions);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        if (user.role) {
            fetchData();
        }
    }, [user.role]);
    useEffect(() => {
        setActiveKey(location.search.split("=")[1]);
    }, [location]);
    useEffect(() => {
        const fetchRoles = async () => {
            if (assignedRoles?.length === 0 && token.access) {
                try {
                    const res = await getAllRoles(token);

                    setAssignedRoles(res.data.Roles);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchRoles();
    }, [assignedRoles]);

    return (
        <Layout>
            <LoggedInComponent>
                <CollapsableMenu></CollapsableMenu>
                <div className={styles.TableContainer}>
                    <Tabs
                        activeKey={activeKey}
                        onChange={handleTabChange}
                        size={"large"}
                        tabBarStyle={{
                            marginLeft: "1%",
                            marginRight: "1%",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                        }}>
                        {user?.group_permissions?.includes(
                            "add_baseusermodel"
                        ) ? (
                            <TabPane
                                tab={
                                    <Link
                                        style={{ color: "inherit" }}
                                        to="/CreateUserFactory?key=1">
                                        Add Users
                                    </Link>
                                }
                                key="1">
                                <div className={styles.TableData}>
                                    <CreateUser regionData={regionData} />
                                </div>
                            </TabPane>
                        ) : null}
                        {user?.group_permissions?.includes("add_factory") ? (
                            <TabPane
                                tab={
                                    <Link
                                        style={{ color: "inherit" }}
                                        to="/CreateUserFactory?key=2">
                                        Add Factories
                                    </Link>
                                }
                                key="2">
                                <div className={styles.TableData}>
                                    <CreateFactory regionData={regionData} />
                                </div>
                            </TabPane>
                        ) : null}
                    </Tabs>
                </div>
            </LoggedInComponent>
        </Layout>
    );
};

export default CreateUserFactoryView;
