/* Generates a menu with list of factories*/
import Menu from "../../@setproduct-ui/core/Menu";
import MenuItem from "../../@setproduct-ui/core/Menu/MenuItem";
import React from "react";
import { useRecoilState } from "recoil";
import { factoryState } from "../../RecoilState/FactoryState";

export default function FactoryListMenu({ factoryData }) {
    const [factory, setFactory] = useRecoilState(factoryState);
    return (
        <Menu
            type="def"
            view="smooth"
            color="primary"
            style={{ maxHeight: "500px", overflow: "auto" }}>
            {factoryData.map((factory, index) => {
                return (
                    <MenuItem
                        type="def"
                        view="smooth"
                        color="primary"
                        text={`${factory.Code} - ${factory.Location}`}
                        key={index}
                        onClick={() => {
                            setFactory(factory);
                        }}
                    />
                );
            })}
        </Menu>
    );
}
