/*
 Main App Component with Routing and pages definitions
*/
import Login from "./pages/Login";
import IncentiveDashboard from "./components/IncentiveDashboard/IncentiveDashboard.jsx";
import Header from "./components/Header";
import Home from "./components/Home";
import React, { useEffect, useRef } from "react";
import ResetPassword from "./pages/ResetPassword";
import BroadcastMessage from "./pages/BroadcastMessage";
import { useRecoilState } from "recoil";
import CaseUpload from "./components/CaseUpload";
import BroadcastMsg from "./pages/BroadcastMsg";
import Generatereport from "./components/Generatereport";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import CaseReport from "./pages/CaseReport/index";
import ViewLog from "./pages/ViewLog";
import MainHome from "./components/Home/MainHome/MainHome.jsx";
import "antd/dist/antd.css";
import QualityChecker from "./pages/QualityChecker";
import ReportsDashboard from "./components/ReportsDashboard/ReportsDashboard.jsx";
import ErrorBoundary from "./containers/ErrorBoundary";
import ResetLink from "./pages/Login/ResetLink/ResetLink";
import AdminTableView from "./components/AdminDashboards/AdminTableView";
import CreateUserFactoryView from "./components/AdminDashboards/CreateUser&Factory/CreateUserFactoryView";
import AwarnessProgramme from "./pages/AwarnessProgramme";
import HolidayCalendar from "./pages/HolidayCalendar";
import PageNotfound from "./pages/ErrorPages/404Error";
import ServerError from "./pages/ErrorPages/500Error";
import CaseDashboard from "./components/AdminDashboards/CaseDashboard";
import LanguagePreference from "./pages/Login/LangaugePreference";
import { userState } from "./RecoilState/userState";
import EditUserForm from "./components/AdminDashboards/UserDashboard/UserAction/EditUser/EditUserForm";
import EditRegion from "./components/AdminDashboards/RegionDashboard/RegionAction/EditRegion";
import { userMetricState } from "./RecoilState/userMerticState";
import { pathName } from "./PageMatricsPath";
import { Cookies } from "react-cookie";
import { common_axios } from "./utils/AxiosSettings.js";
import { tokenState } from "./RecoilState/tokenState.js";

function App() {
    const location = useLocation();
    const state = location.state;
    const isServerErrorRoute = location.pathname === "/ServerError";
    const [user] = useRecoilState(userState);
    const [token] = useRecoilState(tokenState);
    const [userMetricsData, setUserMetricsData] =
        useRecoilState(userMetricState);
    const prevRole = useRef();

    const navigate = useNavigate();
    const cookies = new Cookies();
    // Check if the user is accessing the root page ("/")
    const isAccessingRootPage = window.location.pathname === "/";
    useEffect(() => {
        common_axios.defaults.headers.common["Authorization"] = `Bearer ${
            cookies.get("role-token-cookie")?.access
        }`;
        if (location.pathname !== "/resetPassword/") {
            if (!cookies.get("role-token-cookie")) {
                navigate("/");
            }
        }
    }, [cookies.get("role-token-cookie")]);

    useEffect(() => {
        // Set the state to trigger the redirect when appropriate
        const storedData = cookies.get("role-token-cookie");
        if (location.pathname !== "/resetPassword/") {
            if (isAccessingRootPage && storedData) {
                navigate("/home");
            }
        }
    }, [isAccessingRootPage, location.pathname]);

    useEffect(() => {
        const storedPath =
            JSON.parse(localStorage.getItem("userMetricState")) ||
            userMetricsData;

        if (user.role && (prevRole.current != user.role || !state?.reset)) {
            let path = pathName(location.pathname, location.search, user.role);
            if (path && token.expires > parseInt(Date.now() / 1000)) {
                if (
                    storedPath?.pageMetrics?.pagesVisited.length > 0 &&
                    storedPath.pageMetrics?.pagesVisited.includes(path)
                ) {
                    const pageVisitTimeData = JSON.parse(
                        JSON.stringify(
                            storedPath.pageMetrics.pageVisitTimestamps
                        )
                    );
                    pageVisitTimeData[path].push(new Date().toUTCString());
                    setUserMetricsData((prev) => {
                        const updatedUserMetricState = {
                            ...storedPath,
                            pageMetrics: {
                                ...storedPath.pageMetrics,
                                pageVisitTimestamps: {
                                    ...pageVisitTimeData,
                                },
                            },
                        };
                        localStorage.setItem(
                            "userMetricState",
                            JSON.stringify(updatedUserMetricState)
                        );
                        return updatedUserMetricState;
                    });
                } else {
                    const visited = storedPath.pageMetrics.pagesVisited;
                    const pageVisitTimeData = JSON.parse(
                        JSON.stringify(
                            storedPath.pageMetrics.pageVisitTimestamps
                        )
                    );
                    pageVisitTimeData[path] = [new Date().toUTCString()];
                    setUserMetricsData((prev) => {
                        const updatedUserMetricState = {
                            ...storedPath,
                            pageMetrics: {
                                pagesVisited: [...visited, path],
                                pageVisitTimestamps: {
                                    ...pageVisitTimeData,
                                },
                            },
                        };
                        localStorage.setItem(
                            "userMetricState",
                            JSON.stringify(updatedUserMetricState)
                        );
                        return updatedUserMetricState;
                    });
                }
            }
            prevRole.current = user.role;
        }
    }, [location, user.role]);

    return (
        <ErrorBoundary>
            <div className="App">
                {!isServerErrorRoute && <Header />}
                <Routes>
                    {/* Login Page Route */}
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={<Home />}>
                        {/* Main table component with User details and cases */}
                        <Route index element={<MainHome />} />
                        <Route
                            path="incentive"
                            element={<IncentiveDashboard />}
                        />
                        <Route path="reports" element={<ReportsDashboard />} />
                        <Route
                            path="BroadcastMsg"
                            element={<BroadcastMessage />}
                        />
                        <Route
                            path="AwarenessProgramme"
                            element={<AwarnessProgramme />}
                        />
                        <Route
                            path="HolidayCalendar"
                            element={<HolidayCalendar />}
                        />
                        <Route path="AllCases" element={<CaseDashboard />} />
                    </Route>

                    <Route
                        path="AdminTableView"
                        element={<AdminTableView />}></Route>

                    <Route path="EditUser/:userId" element={<EditUserForm />} />

                    <Route
                        path="CreateUserFactory"
                        element={<CreateUserFactoryView />}></Route>

                    <Route path="UpdateRegion" element={<EditRegion />} />

                    {/* Case Uploading Route */}
                    <Route path="CaseUpload" element={<CaseUpload />} />
                    <Route path="ViewLogs/:caseID" element={<ViewLog />} />
                    <Route path="GenerateReport" element={<Generatereport />} />
                    <Route path="CaseReport/:caseID" element={<CaseReport />} />
                    <Route path="BroadcastMsg" element={<BroadcastMsg />} />
                    <Route path="QC" element={<QualityChecker />} />
                    <Route path="QC/:caseID" element={<QualityChecker />} />
                    <Route path="resetLink" element={<ResetLink />} />

                    <Route path="resetPassword" element={<ResetPassword />} />
                    <Route
                        path="languagePreference"
                        element={<LanguagePreference />}
                    />
                    <Route index element={<Login />} />
                    <Route path="*" element={<PageNotfound />} />
                    <Route path="/ServerError" element={<ServerError />} />
                </Routes>
            </div>
        </ErrorBoundary>
    );
}

export default App;
