import React, { useEffect } from "react";
import ButtonCard from "./ButtonCard";
import styles from "./ButtonContainer.module.css";
import Modal from "react-modal";
import CaseUpload from "../../../CaseUpload";
import Generatereport from "../../../Generatereport";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userState } from "../../../../RecoilState/userState";
import { flagState } from "../../../../RecoilState/flagState";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import FactoryListMenu from "../../../FactoryListMenu";
import { factoryState } from "../../../../RecoilState/FactoryState";
import Dropdown from "../../../../@setproduct-ui/core/Dropdown";

Modal.setAppElement("#root");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const buttonData = [
    {
        text: "Case Upload",
        color: "purple",
        icon: "/assets/images/ButtonLogos/icon/action/note_add_24px.svg",

        clicker: "openCaseUploadModal",
    },
];

export default function ButtonContainer() {
    const [UploadmodalIsOpen, setUploadIsOpen] = React.useState(false);
    const [GenerateisOpen, setGenerateIsOpen] = React.useState(false);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [user] = useRecoilState(userState);
    const [flag, setFlagState] = useRecoilState(flagState);
    let navigate = useNavigate();
    const location = useLocation();
    function openUploadModal() {
        setUploadIsOpen(true);
    }

    function openGenerateModal() {
        setGenerateIsOpen(true);
    }

    function closeModal() {
        setUploadIsOpen(false);
        setFlagState(!flag);
    }
    function closeGenerateModal() {
        setGenerateIsOpen(false);
    }

    function handleButtonClick(clicked) {
        if (clicked == "openGenerateReportModal") {
            openGenerateModal();
        } else if (clicked == "openCaseUploadModal") {
            openUploadModal();
        } else {
            navigate(`/BroadcastMsg`);
        }
    }

    return (
        <div className={styles.ButtonContainer}>
            <Modal
                isOpen={UploadmodalIsOpen}
                onRequestClose={closeModal}
                // style={customStyles}
                style={{
                    content: {
                        inset: "120px",
                        position: "absolute",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "16px",
                        outline: "none",
                        padding: "0px",
                        height: "63%",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
                    },
                }}
                contentLabel="Example Modal"
            >
                <CaseUpload modalCloser={closeModal} />
            </Modal>
            <Modal
                isOpen={GenerateisOpen}
                onRequestClose={closeGenerateModal}
                style={customStyles}
                contentLabel="Example Modal">
                <Generatereport modalCloser={closeGenerateModal} />
            </Modal>
            <div style={{ marginRight: "10em" }}>
                {user.role !== "SUPER_ADMIN" &&
                    user.role !== "FACTORY_ADMIN" &&
                    user.role !== "REGIONAL_ADMIN"
                    ? buttonData.map((button, index) => {
                        return (
                            <div
                                key={button.text}
                                onClick={() => {
                                    handleButtonClick(button.clicker);
                                }}>
                                <ButtonCard
                                    key={button.text}
                                    text={button.text}
                                    colorLogo={button.color}
                                    icon={button.icon}
                                />
                            </div>
                        );
                    })
                    : (user.role === "SUPER_ADMIN" ||
                        user.role === "REGIONAL_ADMIN") &&
                    location.search === "?critical=false" && (
                        <Dropdown
                            view="filled"
                            color="primary"
                            text={
                                FactorySelected
                                    ? `${FactorySelected?.Code} - ${FactorySelected?.Location}`
                                    : `${FactoryDataList[0]?.Code} - ${FactoryDataList[0]?.Location}`
                            }
                            position="bottom-left"
                            content={
                                <FactoryListMenu
                                    factoryData={FactoryDataList}
                                />
                            }
                            dense="false"
                            className={styles.dropdown}
                        />
                    )}
            </div>
        </div>
    );
}
