import React from "react";
import styles from "./ButtonCard.module.css";
export default function ButtonCard({ text, colorLogo, icon }) {
  return (
    <div className={styles.ButtonCard}>
      <div className={styles.logoContainer}>
        <img
          src={`/assets/images/ButtonLogos/Ellipse ${colorLogo}.svg`}
          alt="Snow"
          height={56}
          width={56}
          className={styles.ButtonLogo}
        />
        <img
          src={icon}
          alt="Snow"
          height={32}
          width={32}
          className={styles.ButtonIcon}
        />
      </div>
      <h3>{text}</h3>
    </div>
  );
}
