import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import {
    MailOutlined,
    HomeOutlined,
    BarChartOutlined,
    GiftOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";

export default function CollapsableMenu() {
    const {  Sider} = Layout;
    const [collapsed, setCollapsed] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [user, setUser] = useRecoilState(userState);
    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }
    const formatTitle = (conditions) => {
        const titles = conditions
            .filter((condition) => condition.check)
            .map((condition) => condition.title);

        if (titles.length > 1) {
            return `${titles.slice(0, -1).join(", ")} and ${
                titles[titles.length - 1]
            }`;
        }

        return titles.join("");
    };

    const key = user?.group_permissions?.includes("add_factoryregion")
        ? "1"
        : user?.group_permissions?.includes("add_factory")
        ? "2"
        : user?.group_permissions?.includes("change_baseusermodel")
        ? "3"
        : null;

    const title = formatTitle([
        {
            check: user?.group_permissions?.includes("add_factoryregion"),
            title: "Regions",
        },
        {
            check: user?.group_permissions?.includes("add_factory"),
            title: "Factories",
        },
        {
            check: user?.group_permissions?.includes("change_baseusermodel"),
            title: "Users",
        },
    ]);
    useEffect(() => {
        const items = [];
        if (
            user.role !== "REGIONAL_ADMIN" &&
            user.role !== "SUPER_ADMIN" &&
            user.role !== "FACTORY_ADMIN"
        ) {
            items.push(
                getItem(
                    <Link to="/home" state={{ tabKey: "1" }}>
                        Home
                    </Link>,
                    "home",
                    <HomeOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        if (
            user.role === "REGIONAL_ADMIN" ||
            user.role === "SUPER_ADMIN" ||
            user.role === "FACTORY_ADMIN"
        ) {
            items.push(
                getItem(
                    <Link
                        to="/home/AllCases?critical=false"
                        state={{ tabKey: "2" }}>
                        All Cases
                    </Link>,
                    "Critical Cases",
                    <img
                        src="/assets/images/icon/adminDashboard/allcases.svg"
                        style={{
                            height: "auto",
                            width: "10%",
                        }}
                    />
                )
            );
        }
        if (user.role === "REGIONAL_ADMIN") {
            items.push(
                getItem(
                    <Link
                        to="/home/AllCases?critical=true"
                        state={{ tabKey: "1" }}>
                        Critical Cases
                    </Link>,
                    "Critical Cases",
                    <img
                        src="/assets/images/icon/adminDashboard/critical-case.svg"
                        style={{
                            height: "auto",
                            width: "10%",
                        }}
                    />
                )
            );
        }
        if (user?.user_permissions?.includes("view_incentives")) {
            items.push(
                getItem(
                    <Link to="/home/incentive">Incentives</Link>,
                    "incentive",
                    <GiftOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        //Analytics is visible for all the user
        if (user?.user_permissions?.includes("view_analytics")) {
            items.push(
                getItem(
                    <Link to="/home/reports">Analytics</Link>,
                    "analytics",
                    <BarChartOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        if (user?.group_permissions?.includes("change_factory")) {
            items.push(
                getItem(
                    <Link to={`/AdminTableView?key=${key}`}>{title}</Link>,
                    { title },
                    <img
                        src="/assets/images/icon/adminDashboard/manage.svg"
                        style={{
                            height: "auto",
                            width: "10%",
                        }}
                    />
                )
            );
        }

        if (
            user?.user_permissions?.includes("add_broadcastmessage") ||
            user?.group_permissions?.includes("add_broadcastmessage")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/BroadcastMsg"}>Broadcast Message</Link>,
                    "Broadcast Message ",
                    <MailOutlined style={{ fontSize: "32" }} />
                )
            );
        }
        if (
            user?.user_permissions?.includes("view_awarenessprogram") ||
            user?.group_permissions?.includes("view_awarenessprogram")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/AwarenessProgramme"}>
                        Awareness Programme
                    </Link>,
                    " Awareness Programme ",
                    <img
                        src="/assets/images/AwarenessProgramme/awarnessP.svg"
                        style={{
                            height: "auto",
                            width: "20px",
                        }}
                    />
                )
            );
        }
        //change the user_permissions?.includes("add_awareness_program"))
        if (
            user?.user_permissions?.includes("view_holidaycalendar") ||
            user?.group_permissions?.includes("view_holidaycalendar")
        ) {
            items.push(
                getItem(
                    <Link to={"/home/HolidayCalendar"}>Holiday Calendar</Link>,
                    " Holiday Calendar",
                    <img
                        src="/assets/images/HolidayCalendar/calendar.svg"
                        style={{
                            height: "auto",
                            width: "20px",
                        }}
                    />
                )
            );
        }
        setMenuItems(items);
    }, []);

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            theme="light">
            <Menu
                theme="light"
                defaultSelectedKeys={["1"]}
                mode="inline"
                items={menuItems}
            />
        </Sider>
    );
}
