import React from "react";

const DateTimeFormatter = ({ dateTimeString }) => {
    const formatDate = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const amPm = hours >= 12 ? " pm" : " am";

        // Convert from 24-hour to 12-hour format
        hours %= 12;
        hours = hours || 12;

        const formattedTime = `${hours}:${minutes
            .toString()
            .padStart(2, "0")}${amPm}`;
        return `${day}/${month}/${year} ${formattedTime}`;
    };
    const formattedDate = formatDate(dateTimeString);

    return <span>{formattedDate}</span>;
};

export default DateTimeFormatter;
