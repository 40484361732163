import { common_axios } from "../utils/AxiosSettings";

//api to get all the incentive data
export async function getTotalCaseData(startDate, endDate, factory, companyID) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/cases/sepcount`,
        {
            from: startDate,
            to: endDate,
            Factory: factory,
            Company: companyID,
        }
    );

    return res;
}

export async function getWinnerOfFactoryByIncentive(companyID) {
    if (companyID !== null || companyID !== undefined) {
        let url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/winnerIncentives?Company=${companyID}`;
        const res = common_axios.get(url);
        return res;
    } else {
        // Throw an error with a message
        throw new Error("Company ID is required to make the API call.");
    }
}
