/* View Log component with list of logs which makes api request to /logs */

import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";
import React from "react";
import { useState, useParams, useEffect } from "react";
import { getViewLog } from "../../Adapters/CaseCRUDCalls";
import { useRecoilState } from "recoil";
import { Link, useLocation } from "react-router-dom";
import { tokenState } from "../../RecoilState/tokenState";
import { Row, Col, Timeline, Divider, Result, Spin } from "antd";
import { SmileOutlined } from "@ant-design/icons";

import CaseSendMessage from "../../components/CaseSendMessage";
import styles from "./ViewLog.module.css";
import DateTimeFormatter from "../../components/Utils/DateTimeFormatter";
import { useNavigate } from "react-router-dom";

export default function ViewLog() {
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useRecoilState(tokenState);
    const [viewLogData, setViewLogData] = useState([]);
    const location = useLocation();
    const [visible, setVisible] = React.useState(false);
    const from = location?.state?.from;
    const navigate = useNavigate();

    //View Log data is fetched from the getviewlog API call here, where we provide the access token and case id for it to query.
    //and the data is then saved in an array using object.values or else if there's no reponse then the error is consoled.
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await getViewLog(from.id, token.access);
                if (res && res?.data) {
                    const sorted = Object.values(res?.data).sort(function (
                        x,
                        y
                    ) {
                        return new Date(x.timestamp) - new Date(y.timestamp);
                    });
                    setLoading(false);
                    setViewLogData(sorted);
                } else {
                    setLoading(false);
                    console.error("No data received from API");
                    setViewLogData([]);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error received from API:", error);
                setViewLogData([]);
            }
        };
        fetchData();
    }, [from.id, token.access]);

    return (
        <div>
            <LoggedInComponent>
                {/* send message modal has been added here */}
                <CaseSendMessage
                    caseData={from}
                    visible={visible}
                    makeItVisible={() => {
                        setVisible(true);
                    }}
                    hideModal={() => {
                        setVisible(false);
                    }}
                />

                <div className={styles.casereportContainer}>
                    <div className={styles.mainContainer}>
                        <div className={styles.header}>
                            <h4>
                                {/* back button to go back to the case report */}
                                <Link
                                    to={`/CaseReport/${from.id}`}
                                    state={{ myData: from }}>
                                    <span>
                                        <img
                                            src="/assets/images/back/Group 4495.svg"
                                            alt="back-logo"
                                        />
                                    </span>
                                </Link>
                                <span>View Logs</span>
                            </h4>
                        </div>
                        <div className={styles.bottomContainer}>
                            <div
                                style={{
                                    paddingLeft: 20,
                                    fontFamily: "Poppins,sans-serif",
                                    color: "#2B3674",
                                }}>
                                <h2>Case Number - {from.CaseNumber}</h2>
                            </div>
                            <Divider />
                            {/* used Timeline componenet provided by Antd to easily manage timeline */}
                            {loading && <Spin />}
                            <Timeline style={{ padding: 20, paddingRight: 10 }}>
                                {viewLogData.length > 0 ? (
                                    viewLogData.map((item, index) => (
                                        <Timeline.Item
                                            key={index}
                                            dot={
                                                <img
                                                    style={{ height: "8px" }}
                                                    src="/assets/images/ViewLogs/RectangleViewLog.png"
                                                />
                                            }>
                                            <Row
                                                style={{ marginBottom: 20 }}
                                                justify="space-around"
                                                align="middle">
                                                <Col span={12}>
                                                    <h2>{item.title}</h2>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "spaceAround",
                                                        }}>
                                                        <b>Timestamp :</b>
                                                        <h4
                                                            style={{
                                                                margin: "0px 0px 10px 10px",
                                                            }}>
                                                            <DateTimeFormatter
                                                                dateTimeString={
                                                                    item.timestamp
                                                                }></DateTimeFormatter>
                                                        </h4>
                                                    </div>
                                                    {item.message && (
                                                        <h4>
                                                            <b>Message:</b>{" "}
                                                            {item.message}
                                                        </h4>
                                                    )}
                                                </Col>
                                                <Col span={12}>
                                                    {/* The recording url is converted to a audio componenet here */}
                                                    {item.recording_url && (
                                                        <audio
                                                            onContextMenu={(
                                                                e
                                                            ) =>
                                                                e.preventDefault()
                                                            }
                                                            style={{
                                                                width: "70%",
                                                            }}
                                                            controlsList="nodownload"
                                                            controls
                                                            src={
                                                                item.recording_url
                                                            }>
                                                            Your browser does
                                                            not support the
                                                            audio tag.
                                                        </audio>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* If any varchanged identifier exists then the value is shown using this code block by iteritating through the variables array */}
                                                {item.identifier ===
                                                "VARCHANGED" ? (
                                                    <div>
                                                        {Object.entries(
                                                            item.variables
                                                        ).map(
                                                            ([key, value]) => (
                                                                <div key={key}>
                                                                    <div
                                                                        style={{
                                                                            marginRight:
                                                                                "0.5rem",
                                                                            display:
                                                                                "inline",
                                                                            fontWeight:
                                                                                "bold",
                                                                        }}>
                                                                        {key}:
                                                                    </div>
                                                                    {value[0]}{" "}
                                                                    {"->"}{" "}
                                                                    {value[1]}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : null}
                                            </Row>
                                        </Timeline.Item>
                                    ))
                                ) : (
                                    <Result
                                        icon={<SmileOutlined />}
                                        title="No data found"
                                    />
                                )}
                            </Timeline>
                        </div>
                    </div>
                </div>
            </LoggedInComponent>
        </div>
    );
}
