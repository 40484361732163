import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Layout } from "antd";
import styles from "./AdminDashboard.module.css";
import FactoryDashboard from "./FactoryDashboard";
import UserDashboard from "./UserDashboard";
import CollapsableMenu from "../CollapsableMenu";
import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";
import { useLocation, useNavigate } from "react-router";
import { availableRoles } from "../../RecoilState/availableRoles";
import { Link } from "react-router-dom";
import RegionTableView from "./RegionDashboard/RegionTableView/RegionTableView";
import { tokenState } from "../../RecoilState/tokenState";

const { TabPane } = Tabs;
const AdminTableView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    const [assignedRoles, setAssignedRoles] = useRecoilState(availableRoles);
    const initialTabKey =
        location.search.split("=")[1] || getFirstAvailableTabKey();
    const [activeTab, setActiveTab] = useState(initialTabKey);

    const getFirstAvailableTabKey = () => {
        if (user?.group_permissions?.includes("add_factoryregion")) return "1";
        if (user?.group_permissions?.includes("add_factory")) return "2";
        if (user?.group_permissions?.includes("change_baseusermodel"))
            return "3";
        return null; // default case if no tabs are available
    };

    // Handle tab change
    const handleTabChange = (key) => {
        setActiveTab(key);
        navigate(`?key=${key}`, { replace: true });
    };

    useEffect(() => {
        const urlTabKey = location.search.split("=")[1];
        if (
            (urlTabKey === "1" &&
                user?.group_permissions?.includes("add_factoryregion")) ||
            (urlTabKey === "2" &&
                user?.group_permissions?.includes("change_baseusermodel")) ||
            (urlTabKey === "3" &&
                user?.group_permissions?.includes("add_factory"))
        ) {
            setActiveTab(urlTabKey);
        } else {
            setActiveTab(getFirstAvailableTabKey());
        }
    }, [location, user]);

    return (
        <Layout>
            <LoggedInComponent>
                <CollapsableMenu></CollapsableMenu>
                <div style={{ width: "90%" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            margin: " 1% 2% 0 2%",
                        }}>
                        <h1>Manage</h1>
                        {activeTab === "3" ? (
                            <Link to={"/CreateUserFactory?key=1"}>
                                <button className="primaryButton">
                                    Add User
                                </button>
                            </Link>
                        ) : activeTab === "2" &&
                          user?.group_permissions?.includes("add_factory") ? (
                            <Link to={"/CreateUserFactory?key=2"}>
                                <button className="primaryButton">
                                    Add Factory
                                </button>
                            </Link>
                        ) : activeTab === "1" &&
                          user?.group_permissions?.includes(
                              "add_factoryregion"
                          ) ? (
                            <Link to={"/UpdateRegion"}>
                                <button className="primaryButton">
                                    Create Region
                                </button>
                            </Link>
                        ) : null}
                    </div>
                    <div className={styles.TableContainer}>
                        <Tabs
                            activeKey={activeTab}
                            onChange={handleTabChange}
                            size={"large"}
                            tabBarStyle={{
                                marginLeft: "1%",
                                marginRight: "1%",
                                fontFamily: "Poppins",
                                fontWeight: "400",
                            }}>
                            {user?.group_permissions?.includes(
                                "add_factoryregion"
                            ) ? (
                                <TabPane
                                    tab={
                                        <Link
                                            style={{ color: "inherit" }}
                                            to="/AdminTableView?key=1">
                                            Region
                                        </Link>
                                    }
                                    key="1">
                                    <div className={styles.TableData}>
                                        <RegionTableView />
                                    </div>
                                </TabPane>
                            ) : null}
                            {user?.group_permissions?.includes(
                                "add_factory"
                            ) ? (
                                <TabPane
                                    tab={
                                        <Link
                                            style={{ color: "inherit" }}
                                            to="/AdminTableView?key=2">
                                            Factories
                                        </Link>
                                    }
                                    key="2">
                                    <div className={styles.TableData}>
                                        <FactoryDashboard />
                                    </div>
                                </TabPane>
                            ) : null}
                            {user?.group_permissions?.includes(
                                "change_baseusermodel"
                            ) ? (
                                <TabPane
                                    tab={
                                        <Link
                                            style={{ color: "inherit" }}
                                            to="/AdminTableView?key=3">
                                            Users
                                        </Link>
                                    }
                                    key="3">
                                    <div className={styles.TableData}>
                                        <UserDashboard />
                                    </div>
                                </TabPane>
                            ) : null}
                        </Tabs>
                    </div>
                </div>
            </LoggedInComponent>
        </Layout>
    );
};

export default AdminTableView;
