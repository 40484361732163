import React, { useState } from "react";
import { Form, Row, Col, Select, Button, message } from "antd"; // Assuming you've imported Ant Design components
import "antd/dist/antd.css"; // Import Ant Design CSS

import { useNavigate } from "react-router-dom";
import { atLoginOperations } from "../../../Adapters/userCalls";
import styles from "./languagepage.module.css";
import { Cookies } from "react-cookie";
const { Option } = Select;

const LanguagePreference = () => {
    const navigate = useNavigate();

    const cookies = new Cookies();
    // Access the token value from cookies object

    const [languages, setLanguages] = useState({
        listeningLanguages: [],
        readingLanguages: [],
    });

    const postCallForLanaguage = async () => {
        const options = {
            headers: {
                Authorization: `Bearer ${
                    cookies.get("role-token-cookie").access
                }`,
            },
        };
        const data = {
            Languages: {
                listeningLanguages: languages.listeningLanguages,
                readingLanguages: languages.readingLanguages,
            },
        };
        try {
            const res = await atLoginOperations("language", data, options);
            message.success(res?.data?.message);
            navigate("/home");
        } catch (error) {
            console.error("API Error:", error);
        }
    };
    const allLanguages = [
        "English",
        "Hindi",
        "Marathi",
        "Kannada",
        "Malyalam",
        "Telugu",
        "Tamil",
        "Oriya",
        "Bengali",
    ];

    const handleLanguageChange = (type, values) => {
        setLanguages({
            ...languages,
            [type]: values,
        });
    };

    const handleSubmit = (values) => {
        postCallForLanaguage();
    };
    return (
        <div>
            <div className={styles.container}>
                <img
                    src="assets/images/LoginPage/Emailbackground.png"
                    className={styles.backgroundImage}
                />
                <img
                    src="assets/images/LoginPage/Emailbackground.png"
                    className={styles.backgroundImagerorate}
                />

                <div className={styles.formContainer}>
                    <div className={styles.formContent}>
                        <div className={styles.formHeader}>
                            <p className={styles.welcomeText}>
                                Welcome to Inache admin!
                            </p>
                            <p className={styles.titleText}>
                                Language Preference
                            </p>
                        </div>
                        <Form onFinish={handleSubmit}>
                            <Row gutter={[16, 16]}>
                                <Col span={22}>
                                    <Form.Item
                                        label="Listening Languages"
                                        name="listeningLanguages"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select listening languages",
                                            },
                                        ]}>
                                        <Select
                                            mode="multiple"
                                            placeholder="Select listening languages"
                                            onChange={(values) =>
                                                handleLanguageChange(
                                                    "listeningLanguages",
                                                    values
                                                )
                                            }
                                            value={
                                                languages.listeningLanguages
                                            }>
                                            {allLanguages.map((language) => (
                                                <Option
                                                    key={language}
                                                    value={language}>
                                                    {language}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="Reading Languages"
                                        name="readingLanguages"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select reading languages",
                                            },
                                        ]}>
                                        <Select
                                            mode="multiple"
                                            placeholder="Select reading languages"
                                            onChange={(values) =>
                                                handleLanguageChange(
                                                    "readingLanguages",
                                                    values
                                                )
                                            }
                                            value={languages.readingLanguages}>
                                            {allLanguages.map((language) => (
                                                <Option
                                                    key={language}
                                                    value={language}>
                                                    {language}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button
                                    className="primaryButton"
                                    type="primary"
                                    htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LanguagePreference;
