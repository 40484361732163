import React, { useState, useEffect } from "react";
import styles from "../../pages/CaseReport/CaseReport.module.css";
import { Row, Col, Input } from "antd";
import MultiOutputTranslation from "./MultiOutputTranslation";
const MultiTranslationDynamicVariable = ({
    item,
    language,
    setCount,
    isTemplateValue,
    setTranslatedDataObj,
    translatedDataObj,
    prefilledInputValue,
    draftFlag,
    setCountTranslatedMsg,
    setInputChangeFlag,
    inputChangeFlag,
}) => {
    const [varInput, setVarInput] = useState("");
    const [countChange, setCountChange] = useState(true);
    const [changeFlag, setChangeFlag] = useState(true);
    const [countChangeOfTranslatedMsg, setCountChangeOfTranslatedMsg] =
        useState(true);

    useEffect(() => {
        {
            draftFlag ? setVarInput(prefilledInputValue) : setVarInput("");
        }
    }, []);

    useEffect(() => {
        {
            draftFlag ? setVarInput(prefilledInputValue) : setVarInput("");
        }

        setCountChange(true);
        setCountChangeOfTranslatedMsg(true);
        setTranslatedDataObj({});
    }, [isTemplateValue]);
    useEffect(() => {
        //handeling the showmessage only when all the input varible are translated
        if (countChangeOfTranslatedMsg) {
            if (Object.keys(translatedDataObj).length > 0) {
                setCountTranslatedMsg((prev) => prev + 1);
                setCountChangeOfTranslatedMsg(false);
            }
        }
    }, [language, Object.keys(translatedDataObj)]);
    return (
        <div key={item} style={{ margin: "0 0 2rem 0 " }}>
            <Row gutter={[16, 24]} align="middle" justify="center">
                <Col span={10}>
                    <label
                        name={Object.keys(item)}
                        htmlFor={Object.keys(item)}
                        className={styles.dynamicVariablesBox}>
                        <h3
                            style={{
                                color: "#1b2559 ",
                                fontSize: "16px",
                            }}>
                            {Object.keys(item)}{" "}
                            <span style={{ color: "red" }}>*</span>
                        </h3>
                        <Input.TextArea
                            id={Object.keys(item)}
                            defaultValue={
                                draftFlag ? "prefilledInputValue" : ""
                            }
                            value={varInput}
                            name={Object.keys(item)}
                            onChange={(e) => setVarInput(e.target.value)}
                            showCount
                            rows={4}
                            cols={50}
                            maxLength={30}
                            style={{ width: "70%" }}
                        />
                    </label>
                </Col>
                <Col span={4}>
                    <button
                        disabled={varInput?.trim().length > 0 ? false : true}
                        className={
                            varInput?.trim().length > 0
                                ? styles.translateBtn
                                : styles.disabledBtn
                        }
                        onClick={() => {
                            setChangeFlag(!changeFlag);
                            setInputChangeFlag(!inputChangeFlag);
                            //handeling the count of button click of all the mapped variable to showm message, when all the input variable are translated and all the translated button are clicked once
                            if (countChange) {
                                setCount((prev) => prev + 1);
                                setCountChange(false);
                            }
                        }}>
                        Translate
                    </button>
                </Col>
                <Col span={10}>
                    {language?.map((val, i) => {
                        return (
                            <MultiOutputTranslation
                                key={i}
                                language={val}
                                variable={item[Object.keys(item)]}
                                varInput={varInput}
                                changeFlag={changeFlag}
                                placeHolder={val}
                                setTranslatedDataObj={setTranslatedDataObj}
                                translatedDataObj={translatedDataObj}
                                isTemplateValue={isTemplateValue}
                            />
                        );
                    })}
                </Col>
            </Row>
        </div>
    );
};

export default MultiTranslationDynamicVariable;
