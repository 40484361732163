import Menu from "../../../@setproduct-ui/core/Menu";
import MenuItem from "../../../@setproduct-ui/core/Menu/MenuItem";
import MenuDivider from "../../../@setproduct-ui/core/Menu/MenuDivider";
import React, { useState } from "react";
import styles from "../Header.module.css";
import AccountModal from "./AccountModal";
import useLogout from "../../../hooks/useLogout.js";

const ProfileModal = React.lazy(() => import("./ProfileModal.jsx"));
export default function UserProfileMenu(props) {
    const [openAccount, setOpenAccount] = React.useState(false);
    const { handleLogout } = useLogout();
    async function handleUserLogout() {
        if (localStorage.getItem("flag")) {
            localStorage.setItem("flag", false);
        }
       await handleLogout("Proper Logout");
    }

    const [modalVisible, setModalVisible] = React.useState(false);
    const handleOpenModal = () => {
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    return (
        <>
            <ProfileModal visible={modalVisible} onCancel={handleCloseModal} />
            <AccountModal
                showAccount={openAccount}
                setshowAccount={setOpenAccount}
            />
            {props.showDropper && (
                <div className={styles.dropMenuUserProfile}>
                    <Menu type="def" view="smooth" color="primary">
                        <MenuItem
                            type="def"
                            view="smooth"
                            color="primary"
                            text="My profile"
                            onClick={handleOpenModal}
                        />
                        <MenuDivider type="def" view="smooth" color="primary" />
                        <MenuItem
                            type="def"
                            view="smooth"
                            color="primary"
                            text="Log out"
                            onClick={handleUserLogout}
                        />
                    </Menu>
                </div>
            )}
        </>
    );
}
