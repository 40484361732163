/* Quality checker main entry point component*/
/* Reopen Case component is self explanatory */

import React from "react";
import { Link } from "react-router-dom";
import styles from "./QualityChecker.module.css";
import ReopenCase from "./ReopenCase";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { caseReviewDoneCall } from "../../Adapters/QCcalls";
import { getCasereportData } from "../../Adapters/CaseReportCalls";
import { message } from "antd";
import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";

export default function QualityChecker() {
  const location = useLocation();
  const { state } = location;
  const [reopen, setreopen] = React.useState(false);
  const [caserepData, setcaserepData] = React.useState([]);
  const [caseData, setcaseData] = React.useState(state.from);

  function caseReviewDone() {
    caseReviewDoneCall(state.from.id)
      .then((res) => {
        message.success("Case Reviewed Successfully");
      })
      .catch((err) => {
        message.error("Case review failed, please try again later !");
      });
  }

  useEffect(() => {
    getCasereportData(state.from.id)
      .then((res) => {
        setcaserepData(res);
      })
      .catch((err) => {
        message.error("Case report data not found !");
      });
    return () => {};
  }, []);

  return (
    <LoggedInComponent>
      {!reopen && (
        <div>
          <Link to="/home" className="backBtn margin-btn">
            <img src="/assets/images/back/keyboard_arrow_left_24px.svg" />
            <span>Back</span>
          </Link>
          <div className={styles.QC}>
            <div className={styles.container}>
              <h1>Case Report Info</h1>
              <div className={styles.mainContainer}>
                {[
                  "CaseNumber",
                  "ReportingMedium",
                  "Date",
                  "Time",
                  "CaseCategory",
                  "SubCategory",
                  "Priority",
                  "CaseStatus",
                  "CaseDescription",
                  "Comments",
                  "CaseReporter",
                  "CaseManager",
                  "CaseTroubleShooter",
                ].map((single, index) => {
                  return (
                    <div className={styles.item}>
                      <div className={styles.left}>{single}</div>
                      <div className={styles.right}>{caseData[single]}</div>
                    </div>
                  );
                })}
              </div>
              {caserepData ? <h3>Case Closing Report</h3> : ""}

              {Object.entries(caserepData).map(([key, value]) => {
                let keyDisplay;
                if (key == "CCRWhen") {
                  keyDisplay = "When";
                } else if (key == "CCRWhere") {
                  keyDisplay = "Where";
                } else if (key == "CCRWhathappened") {
                  keyDisplay = "What Happened";
                } else if (key == "TimeToResolveCase") {
                  keyDisplay = "Time to resolve";
                } else if (key == "Case") {
                  keyDisplay = "Case";
                } else if (key == "CCRremarks") {
                  keyDisplay = "Remarks";
                } else if ((key = "Who")) {
                  keyDisplay = "Who";
                }

                return (
                  <div className={styles.item}>
                    <div className={styles.left}>{keyDisplay}</div>
                    <div className={styles.right}>{value}</div>
                  </div>
                );
              })}

              <div className="buttons">
                <button
                  onClick={() => {
                    setreopen(true);
                  }}
                  className="button"
                >
                  Reopen
                </button>
                <button onClick={caseReviewDone} className="grey button">
                  Reviewed{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ReopenCase caseData={caseData} reopen={reopen} setreopen={setreopen} />
    </LoggedInComponent>
  );
}
