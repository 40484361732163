import {
    Modal,
    Form,
    Radio,
    Input,
    Switch,
    message,
    Button,
    Select,
} from "antd";
import React, { useEffect, useState } from "react";
import {
    getAllRoles,
    patchRoleDataOfUser,
    postNewRole,
} from "../../../../../../Adapters/AdminCalls";
import { factoryState } from "../../../../../../RecoilState/FactoryState";
import { availableRoles } from "../../../../../../RecoilState/availableRoles";

import { getAllFactoriesOfCompany } from "../../../../../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../../../../../RecoilState/tokenState";
import { useRecoilState } from "recoil";
import ExistingDashboardSelector from "./DashboardSelectorComponent/ExistingDashboardSelector";
import { userState } from "../../../../../../RecoilState/userState";
import { regionState } from "../../../../../../RecoilState/regionState";
import useFetchRegion from "../../../../RegionDashboard/RegionHooks/FetchRegionHook";
import { useFetchUserData } from "../../UserHooks/useFechUserDataAdmin";

const { Option } = Select;
const AddRoleModal = ({
    userSelected,
    role,
    visible,
    onCancel,
    onSave,
    fetcher,
}) => {
    const [addRoleForm] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDashboardComponent, setShowDashboardComponent] = useState(false);
    const [assignedRoles, setAssignedRoles] = useRecoilState(availableRoles);
    const [dashboardUser, setDashboardUser] = useState([]);
    const [dashboardEmail, setDashboardEmail] = useState("");
    const [FactoryList, setFactoryList] = useState([]);
    const [token, setToken] = useRecoilState(tokenState);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [user, setUser] = useRecoilState(userState);
    const [roleSelected, setRoleSelected] = useState(
        addRoleForm.getFieldValue("Assign Role")
    );

    const [stateforRoleFactory, setStateforRoleFactory] = useState({
        role: null,
        factory_fk: null,
        region_fk: null,
    });
    const [regions, setRegions] = useRecoilState(regionState);
    const {
        fetchRegionData,
        data: regionData,
        loading,
        error,
    } = useFetchRegion();

    const { fetchUserData, Loading } = useFetchUserData();

    useEffect(() => {
        if (regions.length === 0) {
            fetchRegionData();
            if (regionData) {
                setRegions(regionData);
            }
        }
    }, []);

    useEffect(() => {
        const fetchRoles = async () => {
            if (assignedRoles?.length === 0 && token.access) {
                try {
                    const res = await getAllRoles(token);
                    setAssignedRoles(res.data.Roles);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchRoles();
    }, [assignedRoles]);

    // Factory API call

    useEffect(() => {
        setStateforRoleFactory({
            role: addRoleForm?.getFieldValue("Assign Role"),
            factory_fk: addRoleForm?.getFieldValue("Factory"),
            region_fk: addRoleForm?.getFieldValue("Region"),
        });
    }, [
        addRoleForm.getFieldValue("Assign Role"),
        addRoleForm.getFieldValue("Factory"),
        addRoleForm.getFieldValue("Region"),
    ]);

    useEffect(() => {
        if (user?.role === "SUPER_ADMIN" || user?.role === "REGIONAL_ADMIN") {
            setFactorySelected(FactoryList[0]);
        } else {
            setFactorySelected(
                FactoryList &&
                    FactoryList.filter((f) => f.id === user?.factory_fk)[0]
            );
        }
        addRoleForm.setFieldsValue({
            Factory: FactorySelected ? FactorySelected.id : user?.factory_fk,
        });
    }, [FactoryList]);

    function handleFormChange(changedValues, allValues) {
        if (changedValues.dashboard && changedValues.dashboard === "existing") {
            setShowDashboardComponent(true);
        } else if (
            changedValues.dashboard &&
            changedValues.dashboard === "new"
        ) {
            setShowDashboardComponent(false);
        }
        setIsFormChanged(true);
    }
    function handleCancel() {
        if (isFormChanged) {
            setShowConfirmModal(true);
        } else {
            onCancel();
        }
    }
    useEffect(() => {
        if (user.company_fk != undefined) {
            getAllFactoriesOfCompany(
                user.company_fk,
                token.access,
                user.role === "REGIONAL_ADMIN" && "region"
            ).then((res) => {
                setFactoryList(res.data.message_body.Factories);
            });
        }
    }, [user.role]);

    useEffect(() => {
        if (
            roleSelected === "REGIONAL_ADMIN" ||
            roleSelected === "SUPER_ADMIN"
        ) {
            addRoleForm.setFieldsValue({
                hasAccess_Awareness_Program: true,
                hasAccess_BroadCast_Message: true,
                hasAccess_Holiday_Calender: true,
            });
        } else if (roleSelected === "FACTORY_ADMIN" || roleSelected === "CM") {
            addRoleForm.setFieldsValue({
                hasAccess_Awareness_Program: false,
                hasAccess_BroadCast_Message: false,
                hasAccess_Holiday_Calender: false,
            });
        } else if (roleSelected === "CR" || roleSelected === "CT") {
            addRoleForm.setFieldsValue({
                hasAccess_Awareness_Program: false,
                hasAccess_BroadCast_Message: false,
                hasAccess_Holiday_Calender: false,
            });
        }
    }, [roleSelected]);

    async function handleRoleDataFormSubmit() {
        await addRoleForm.validateFields();
        setIsLoading(true);
        const formData = {
            user_name: userSelected.user_name,
            email: userSelected.email,
            role: addRoleForm.getFieldValue("Assign Role"),
            company_fk: userSelected.company_fk,
            // factory_fk: addRoleForm.getFieldValue("Factory"),
        };
        if (
            addRoleForm.getFieldValue("Assign Role") === "REGIONAL_ADMIN" ||
            addRoleForm.getFieldValue("Assign Role") === "SUPER_ADMIN"
        ) {
            formData.user_permissions = {
                hasAccess_Awareness_Program: "false", //thought the Regional admin will get all permissions
                hasAccess_BroadCast_Message: "false",
                hasAccess_Holiday_Calender: "false",
            };
            formData.region_fk = addRoleForm.getFieldValue("Region");
        } else if (
            addRoleForm.getFieldValue("Assign Role") === "FACTORY_ADMIN" ||
            addRoleForm.getFieldValue("Assign Role") === "CM"
        ) {
            formData.user_permissions = {
                hasAccess_Awareness_Program: "false",
                hasAccess_BroadCast_Message: "false",
                hasAccess_Holiday_Calender: "false",
            };
            formData.factory_fk = addRoleForm.getFieldValue("Factory");
        } else if (
            addRoleForm.getFieldValue("Assign Role") === "CT" ||
            addRoleForm.getFieldValue("Assign Role") === "CR"
        ) {
            formData.user_permissions = {
                hasAccess_Awareness_Program: addRoleForm.getFieldValue(
                    "hasAccess_Awareness_Program"
                )
                    ? "true"
                    : "false",
                hasAccess_BroadCast_Message: "false",
                hasAccess_Holiday_Calender: "false",
            };
            formData.factory_fk = addRoleForm.getFieldValue("Factory");
        } else {
            formData.user_permissions = {
                hasAccess_Awareness_Program: addRoleForm.getFieldValue(
                    "hasAccess_Awareness_Program"
                )
                    ? "true"
                    : "false",
                hasAccess_BroadCast_Message: addRoleForm.getFieldValue(
                    "hasAccess_BroadCast_Message"
                )
                    ? "true"
                    : "false",
                hasAccess_Holiday_Calender: addRoleForm.getFieldValue(
                    "hasAccess_Holiday_Calender"
                )
                    ? "true"
                    : "false",
            };
        }
        try {
            const response = await postNewRole(formData);
            fetchUserData();
            if (response.data?.message) {
                setIsLoading(false);
                message.success(
                    response.data.message || "Role added successfully."
                );
                fetcher();
                onCancel();
            } else {
                setIsLoading(false);
                message.error(
                    response?.data?.errorMessage || "Failed to add role."
                );
            }
        } catch (error) {
            setIsLoading(false);
            message.error(
                error?.response?.data?.errorMessage || "Failed to add role."
            );
        }
    }
    return (
        <>
            <Modal
                title="Add Role"
                visible={visible}
                confirmLoading={isLoading}
                onCancel={onCancel}
                footer={[
                    <button
                        key="Cancel"
                        onClick={onCancel}
                        className="secondaryButton">
                        Cancel
                    </button>,
                    <button
                        key="Save"
                        onClick={handleRoleDataFormSubmit}
                        className="primaryButton">
                        Save
                    </button>,
                ]}>
                <Form
                    form={addRoleForm}
                    onValuesChange={handleFormChange}
                    onFinish={handleRoleDataFormSubmit}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please select a Role",
                            },
                        ]}
                        label="Assign Role"
                        name="Assign Role">
                        <Select onChange={(role) => setRoleSelected(role)}>
                            {Array.isArray(assignedRoles) &&
                                assignedRoles.map((role) => (
                                    <Select.Option value={role}>
                                        {role}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    {roleSelected !== "REGIONAL_ADMIN" ? (
                        <Form.Item
                            name="Factory"
                            label="Factory"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a Factory Number",
                                },
                            ]}>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={user?.role === "FACTORY_ADMIN"}>
                                {Object.values(FactoryList).map((factory) => (
                                    <Option
                                        key={factory.id}
                                        value={factory.id}
                                        label={`${factory.Code} - ${factory.Location}`}>
                                        {`${factory.Code} - ${factory.Location}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="Region"
                            label="Region"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a Region",
                                },
                            ]}>
                            <Select>
                                {regions.map((region) => (
                                    <Option key={region.id} value={region.id}>
                                        {region.Name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item label="Dashboard" name="dashboard">
                        <Radio.Group>
                            <Radio value="new">New Role</Radio>
                            <Radio value="existing">Existing Role</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {showDashboardComponent && (
                        <ExistingDashboardSelector
                            key={JSON.stringify(stateforRoleFactory)} //added this becasue the component wasn't rendering again.
                            searchbyRole={true}
                            role={stateforRoleFactory}
                            currentUser={userSelected}
                            setUser={setDashboardUser}
                            onEmailChange={(value) => {
                                setDashboardEmail(value);
                            }}
                            factoryData={FactoryList}
                        />
                    )}
                    <Form.Item
                        label="Enable Awareness Programme"
                        name="hasAccess_Awareness_Program"
                        valuePropName="checked">
                        <Switch
                            disabled={
                                roleSelected === "CM" ||
                                roleSelected === "REGIONAL_ADMIN" ||
                                roleSelected === "SUPER_ADMIN" ||
                                roleSelected === "FACTORY_ADMIN"
                                    ? true
                                    : false
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Enable broadcast message"
                        name="hasAccess_BroadCast_Message"
                        valuePropName="checked">
                        <Switch
                            disabled={
                                roleSelected === "CR" ||
                                roleSelected === "CM" ||
                                roleSelected === "CT" ||
                                roleSelected === "REGIONAL_ADMIN" ||
                                roleSelected === "SUPER_ADMIN" ||
                                roleSelected === "FACTORY_ADMIN"
                                    ? true
                                    : false
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        label="Upload Holiday Calendar"
                        name="hasAccess_Holiday_Calender"
                        valuePropName="checked">
                        <Switch
                            disabled={
                                roleSelected === "CR" ||
                                roleSelected === "CM" ||
                                roleSelected === "CT" ||
                                roleSelected === "REGIONAL_ADMIN" ||
                                roleSelected === "SUPER_ADMIN" ||
                                roleSelected === "FACTORY_ADMIN"
                                    ? true
                                    : false
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {showConfirmModal && (
                <Modal
                    title="Unsaved Changes"
                    visible={true}
                    closeIcon={true}
                    footer={[
                        <button
                            key="Cancel"
                            onClick={() => setShowConfirmModal(false)}
                            className="secondaryButton">
                            Cancel
                        </button>,
                        <button
                            key="Save"
                            onClick={() => {
                                setShowConfirmModal(false);
                                onCancel();
                            }}
                            className="primaryButton">
                            Exit
                        </button>,
                    ]}>
                    <p>Are you sure you want to discard the changes?</p>
                </Modal>
            )}
        </>
    );
};

export default AddRoleModal;
