/* Table data based on user or factory admin dashboard depends on user.role*/
import React, { useEffect } from "react";
import styles from "./TableContainer.module.css";
import ButtonContainer from "./ButtonContainer";
import WelcomeHeading from "./WelcomeHeading";
import TableData from "./TableData";
import notifier from "../../../utils/notifier";
import { useLocation } from "react-router";
export default function TableContainer({ user, selectedFactory }) {
    const location = useLocation();
    return (
        <div className={styles.TableContainer}>
            <>
                {user.role === "REGIONAL_ADMIN" &&
                location.search === "?critical=true" ? (
                    <div className={styles.heading}>
                        <h1>POSH & Special cases</h1>
                    </div>
                ) : (
                    <WelcomeHeading role={user.role}>
                        {user.name ? user.name : user.email}
                    </WelcomeHeading>
                )}
                <ButtonContainer />
            </>

            <TableData user={user} />
        </div>
    );
}
