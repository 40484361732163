/* Displays saved messages */
import React, { useEffect } from "react";
import TableComponent from "../TableComponent";
import styles from "../BroadcastMsg.module.css";
import NewMessage from "../NewMessage";
import axios from "axios";
import { getDraftMessages } from "../../../Adapters/BroadcastmessageCalls";
export default function DraftMessage() {
  const [openMessage, setOpenMessage] = React.useState(false);
  const [draftData, setDraftData] = React.useState([]);
  const [openedMessagedata, setOpenedMessagedata] = React.useState(null);
  let mappedData;
  const handleClickOpen = (data) => {
    setOpenMessage(true);
    setOpenedMessagedata(data);
  };
  useEffect(() => {
    getDraftMessages().then((res) => {
      setDraftData(res.data);
    });
    return () => {};
  }, []);

  if (draftData) {
    mappedData = draftData.map((item, index) => {
      return [
        "12/12/2019",
        "12:12",
        item.Factory,
        item.Regionalmessage,
        item.Englishmessage,
      ];
    });
  }
  return (
    <div className={styles.rightContainer}>
      {openMessage && (
        <div
          className={styles.backBtn}
          onClick={() => {
            setOpenMessage(false);
          }}
        >
          <img src="/assets/images/back/keyboard_arrow_left_24px.svg" />
          <span>Back</span>
        </div>
      )}

      {openMessage ? (
        <NewMessage openedMessagedata={openedMessagedata} />
      ) : (
        <TableComponent
          tableHeadings={[
            "Date",
            "Time",
            "Factory",
            "Regional Language",
            "English",
          ]}
          tableData={mappedData}
          tableOf="broadcastmessage"
          handleClickOpen={handleClickOpen}
        />
      )}
    </div>
  );
}
