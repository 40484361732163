/* Will display messges sent */
import React from "react";
import TableComponent from "../TableComponent";
import styles from "../BroadcastMsg.module.css";
import { useEffect } from "react";
import { getSentMessages } from "../../../Adapters/BroadcastmessageCalls";

export default function SentMessage() {
  const [sentData, setSentData] = React.useState([]);
  let mappedData;

  useEffect(() => {
    getSentMessages().then((res) => {
      setSentData(res.data);
    });
    return () => {};
  }, []);

  if (sentData) {
    mappedData = sentData.map((item, index) => {
      return [
        "12/12/2019",
        "12:12",
        item.Factory,
        item.Regionalmessage,
        item.Englishmessage,
      ];
    });
  }
  return (
    <div className={styles.rightContainer}>
      <TableComponent
        tableHeadings={[
          "Date",
          "Time",
          "Factory",
          "Reginal Language",
          "English",
        ]}
        tableData={mappedData}
        tableOf="broadcastmessage"
      />
    </div>
  );
}
