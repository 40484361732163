/* Will display Attachments of case */

import React from "react";
import DocumentViewer from "../../../components/UploadComponent/ShowDocHeaderFile";
export default function ATTACHMENTcomponent({ caseData }) {
    return (
        <div className="flex-row">
            {caseData?.File.length == 0 ? (
                "No Attachments available"
            ) : (
                <DocumentViewer
                    document_delete_on={false}
                    document_download_on={false}
                    documentIds={caseData?.File}
                    model="case"
                    model_id={caseData?.id}
                />
            )}
        </div>
    );
}
