/* Case Closing report */
import React, { useEffect, useState } from "react";
import { ClosingState } from "../../../../RecoilState/ClosingState";
import styles from "../../CaseReport.module.css";
import { useRecoilState } from "recoil";
import { getCasereportData } from "../../../../Adapters/CaseReportCalls";
import {
    Select,
    Input,
    Form,
    Divider,
    Row,
    Col,
    Popover,
    Spin,
    message,
} from "antd";
import CaseReportBlock from "../../../../components/Utils/CaseReportBlock";
import { getCounterFromCaseStatus } from "../../../../hooks/getCounterFromCaseStatus";
import {
    getSentMessagePreviewData,
    getTemplateData,
    postTemplateData,
} from "../../../../Adapters/SendMessage";
import MultiTranslationDynamicVariable from "../../../../components/Utils/MultiTranslationDynamicVariable";
import { SendMessageFlag } from "../../../../RecoilState/SendMessageFlag";
import { previousRouteData } from "../../../../RecoilState/previousRouteData";

export default function CaseClosing({
    user,
    caseData,
    showModalClose,
    showModalDraft,
    showModalResolve,
    setIsReopenModalVisible,
    closingReportExistsFalse,
    closingReportExistsTrue,
    setTranslatedDataObj,
    translatedDataObj,

    setRAFieldflagValues,
}) {
    const [sendMessageFlag, setSendMessageFlag] =
        useRecoilState(SendMessageFlag);
    const [ClosingReport, setClosing] = useRecoilState(ClosingState);
    const [isLoading, setIsLoading] = useState(false);
    const [inputChangeFlag, setInputChangeFlag] = useState(true);
    const [previewScreenData, setPreviewScreenData] = useState();

    const [variableData, setVariableData] = useState("");
    const [allTemplates, setAllTemplates] = useState({});
    const [language, setLanguage] = React.useState("");
    const [template, setTemplate] = React.useState("");
    const [templateID, setTemplateID] = useState("");
    const [prefilledInputValue, setPrefilledInputValue] = useState("");
    const [draftFlag, setDraftFlag] = useState(false);
    //states to handel show message only when all the input field varible are translated
    const [count, setCount] = useState(0);
    const [isTemplateValue, setIsTemplateValue] = useState(false);
    const [countTraslatedMsg, setCountTranslatedMsg] = useState(0);
    const [caseClosingForm] = Form.useForm();
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const handleCaseClosingSubmission = (eventName, actionType) => {
        setClosing({
            ...ClosingReport,
            when: caseClosingForm.getFieldValue("when"),
            who: caseClosingForm.getFieldValue("who"),
            where: caseClosingForm.getFieldValue("where"),
            what: caseClosingForm.getFieldValue("what"),
            remarks: caseClosingForm.getFieldValue("remarks"),
            CCRComments_RA: caseClosingForm.getFieldValue("CCRComments_RA"),
        });
    };

    const handleCaseClosingDraft = (eventName, actionType) => {
        setClosing({
            ...ClosingReport,
            when: caseClosingForm.getFieldValue("when"),
            who: caseClosingForm.getFieldValue("who"),
            where: caseClosingForm.getFieldValue("where"),
            what: caseClosingForm.getFieldValue("what"),
            remarks: caseClosingForm.getFieldValue("remarks"),
            CCRComments_RA: caseClosingForm.getFieldValue("CCRComments_RA"),
        });
    };

    useEffect(() => {
        setCountTranslatedMsg(0);
    }, [template]);

    useEffect(() => {
        if (template && language && Object.keys(allTemplates).length > 0)
            getVariableData();
        setClosing({
            ...ClosingReport,
            template: template,
            language: language,
            message: previewScreenData?.body
                ? previewScreenData?.body
                : ClosingReport.message,

            templateID: templateID,
        });
    }, [
        template,
        language,
        previewScreenData?.body,
        ClosingReport.message,
        caseData,
        allTemplates,
        sendMessageFlag,
    ]);

    useEffect(() => {
        getCasereportData(caseData.id)
            .then((res) => {
                // if the case is Under Investigation, set the local ClosingReport to empty rather than the previous data
                if (
                    getCounterFromCaseStatus(caseData.CaseStatus) === 5 &&
                    caseData.reopened &&
                    caseData.CurrentStatus !== "Draft"
                ) {
                    setClosing({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                    });
                    caseClosingForm.setFieldsValue({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                    });
                    closingReportExistsTrue(true);
                } else {
                    setClosing({
                        when: res?.message_body?.CCRWhen,
                        where: res?.message_body?.CCRWhere,
                        remarks: res?.message_body?.CCRremarks,
                        who: res?.message_body?.CCRWho,
                        what: res?.message_body?.CCRWhathappened,
                        template: res?.message_body?.CCTemplate,
                        language: res?.message_body?.CCLanguage,
                        message: res?.message_body?.CCMessage,
                        templateID: res?.message_body?.CCTemplateID,
                        CCRComments_RA: res?.message_body?.CCRComments_RA,
                    });

                    setLanguage(res?.message_body?.CCLanguage);
                    setTemplate(res?.message_body?.CCTemplate);
                    setPrefilledInputValue(res?.message_body?.variables);

                    caseClosingForm.setFieldsValue({
                        when: res?.message_body?.CCRWhen,
                        where: res?.message_body?.CCRWhere,
                        remarks: res?.message_body?.CCRremarks,
                        who: res?.message_body?.CCRWho,
                        what: res?.message_body?.CCRWhathappened,
                        template: res?.message_body?.CCTemplate,
                        language: res?.message_body?.CCLanguage,
                        message: res?.message_body?.CCMessage,
                        CCRComments_RA: res?.message_body?.CCRComments_RA,
                    });
                    closingReportExistsTrue(true);
                }
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    caseClosingForm.setFieldsValue({
                        when: "",
                        where: "",
                        who: "",
                        what: "",
                        remarks: "",
                        template: "",
                        language: "",
                        message: "",
                        templateID: "",
                        CCRComments_RA: "",
                    });
                    closingReportExistsFalse(false);
                }
            });
    }, [caseData.Company]); // caseData contains id, Company and CaseStatus properties. It should be used as a dependency in useEffect to avoid stale data.
    const onFormLayoutChange = (values) => {
        if ("template" in values) {
            setTemplate(values.template);
            setIsTemplateValue(!isTemplateValue);
        } else if ("language" in values) {
            setLanguage(values.language);
        }
    };
    useEffect(() => {
        caseData.CurrentStatus === "Draft"
            ? setDraftFlag(true)
            : setDraftFlag(false);
        if (
            caseData.Complainer &&
            getCounterFromCaseStatus(caseData.CaseStatus) <= 5
        ) {
            let queryUrl = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template`
            );

            queryUrl.searchParams.append("companyID", caseData.Company);
            //setting the template category as send message for testing replace it to Resolving Message
            if (
                user.role === "REGIONAL_ADMIN" &&
                caseData.CaseCategory === "POSH"
            )
                queryUrl.searchParams.append("temp_category", [
                    "Posh Message",
                    "Posh Unresponsive Message",
                ]);
            else if (
                user.role === "REGIONAL_ADMIN" &&
                caseData.CaseCategory === "Special Cases"
            ) {
                queryUrl.searchParams.append("temp_category", [
                    "Resolving Message",
                    "Unresponsive Message",
                ]);
            } else {
                queryUrl.searchParams.append(
                    "temp_category",
                    "Resolving Message"
                );
            }
            try {
                getTemplateData(queryUrl)
                    .then((res) => {
                        setAllTemplates(res?.data.message_body?.templates);
                    })
                    .catch((error) => {
                        message.error(error.response.data.errorMessage);
                    });
            } catch (error) {
                alert("error in response retry again");
            }
        }
    }, [caseData]);

    const getVariableData = () => {
        const id =
            template &&
            language &&
            Object.keys(allTemplates).length > 0 &&
            allTemplates.hasOwnProperty(template) &&
            allTemplates[template][language];
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/template/`
        );
        setTemplateID(id);
        queryUrl.searchParams.append("templateID", id);
        queryUrl.searchParams.append("caseID", caseData?.id);
        queryUrl.searchParams.append("language", language);
        try {
            postTemplateData(queryUrl).then((res) => {
                setVariableData(res?.data?.message_body);
            });
        } catch (error) {
            console.log("error in res");
        }
    };
    const getPreviewScreensDataHandler = async (translatedDataObj) => {
        setIsLoading(true);
        let queryUrl = new URL(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/varmap/`
        );
        let objectString = JSON.stringify(translatedDataObj);

        queryUrl.searchParams.append("inputs", objectString);
        try {
            const res = await getSentMessagePreviewData(queryUrl).catch(
                (error) => message.error(error?.response?.data?.errorMessage)
            );

            setPreviewScreenData(res?.data.message_body[0]);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const template = caseClosingForm.getFieldValue("template");

        const templateFlags = {
            "Lack of response": {
                CBRRMandatory: false,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            "POSH case closure": {
                CBRRMandatory: true,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            "POSH case closure (No response)": {
                CBRRMandatory: false,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            "POSH Lack of response": {
                CBRRMandatory: true,
                closeModalFlag: true,
                closingRemarkMandatory: false,
            },
            default: {
                CBRRMandatory: false,
                closeModalFlag: false,
                closingRemarkMandatory: true,
            },
        };

        setRAFieldflagValues((prevState) => ({
            ...prevState,
            ...(templateFlags[template] || templateFlags.default),
        }));
    }, [caseClosingForm.getFieldValue("template")]);

    useEffect(() => {
        setCount(0);
        setTranslatedDataObj({});
    }, [isTemplateValue]);

    useEffect(() => {
        if (variableData !== "") {
            if (
                count === Object.keys(variableData).length &&
                Object.keys(translatedDataObj).length === count
            ) {
                getPreviewScreensDataHandler(translatedDataObj);
            }
        }
    }, [
        count,
        translatedDataObj,
        variableData,
        isTemplateValue,
        language,
        inputChangeFlag,
    ]);

    return (
        <div>
            <div className={styles.caseReportHeading}>
                {user.role === "REGIONAL_ADMIN" &&
                caseData.CaseCategory === "POSH"
                    ? "Remarks"
                    : "Case Resolving Report"}
            </div>

            <Form
                name="Case Closing Form"
                form={caseClosingForm}
                onValuesChange={onFormLayoutChange}
                onFormFinish={() => handleCaseClosingSubmission()}>
                {user.role === "REGIONAL_ADMIN" &&
                caseData.CaseCategory === "POSH" ? (
                    <Row gutter={[16, 48]} className={styles.topPadding}>
                        <Col span={12} style={{ paddingRight: "2rem" }}>
                            {getCounterFromCaseStatus(caseData.CaseStatus) >=
                            6 ? (
                                <CaseReportBlock
                                    label="Comment by Regional Admin"
                                    displayMessage={
                                        ClosingReport.CCRComments_RA
                                    }
                                />
                            ) : (
                                <Form.Item
                                    label="Comment by Regional Admin"
                                    name="CCRComments_RA"
                                    rules={[
                                        {
                                            required:
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) !== "Lack of response" &&
                                                caseClosingForm.getFieldValue(
                                                    "template"
                                                ) !==
                                                    "POSH case closure (No response)",
                                            message:
                                                "Please enter Comment by Regional Admin",
                                        },
                                    ]}
                                    initialValue={ClosingReport.CCRComments_RA}>
                                    <Input.TextArea
                                        name="CCRComments_RA"
                                        readOnly={
                                            getCounterFromCaseStatus(
                                                caseData.CaseStatus
                                            ) >= 6
                                        }
                                        rows="4"
                                        cols="50"
                                        maxLength={300}
                                        showCount
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row gutter={[16, 48]} className={styles.topPadding}>
                            <Col span={12} style={{ paddingRight: "2rem" }}>
                                {getCounterFromCaseStatus(
                                    caseData.CaseStatus
                                ) >= 6 ? (
                                    <CaseReportBlock
                                        label="When"
                                        displayMessage={ClosingReport.when}
                                    />
                                ) : (
                                    <Form.Item
                                        label="When"
                                        name="when"
                                        rules={[
                                            {
                                                required:
                                                    caseClosingForm.getFieldValue(
                                                        "template"
                                                    ) !== "Lack of response",
                                                message:
                                                    "Please enter When it happened",
                                            },
                                        ]}
                                        initialValue={ClosingReport.when}>
                                        <Input.TextArea
                                            name="when"
                                            readOnly={
                                                getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6
                                            }
                                            rows="4"
                                            cols="50"
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12}>
                                {getCounterFromCaseStatus(
                                    caseData.CaseStatus
                                ) >= 6 ? (
                                    <CaseReportBlock
                                        label="What happened"
                                        displayMessage={ClosingReport.what}
                                    />
                                ) : (
                                    <Form.Item
                                        label="What Happened"
                                        name="what"
                                        rules={[
                                            {
                                                required:
                                                    caseClosingForm.getFieldValue(
                                                        "template"
                                                    ) !== "Lack of response",
                                                message:
                                                    "Please enter What Happened",
                                            },
                                        ]}
                                        initialValue={ClosingReport.what}>
                                        <Input.TextArea
                                            readOnly={
                                                getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6
                                            }
                                            name="what"
                                            rows="4"
                                            cols="50"
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>

                        <Row gutter={[16, 48]}>
                            <Col span={12} style={{ paddingRight: "2rem" }}>
                                {getCounterFromCaseStatus(
                                    caseData.CaseStatus
                                ) >= 6 ? (
                                    <CaseReportBlock
                                        label="Where"
                                        displayMessage={ClosingReport.where}
                                    />
                                ) : (
                                    <Form.Item
                                        label="Where"
                                        name="where"
                                        rules={[
                                            {
                                                required:
                                                    caseClosingForm.getFieldValue(
                                                        "template"
                                                    ) !== "Lack of response",
                                                message:
                                                    "Please enter Where it happened",
                                            },
                                        ]}
                                        initialValue={ClosingReport.where}>
                                        <Input.TextArea
                                            readOnly={
                                                getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6
                                            }
                                            name="where"
                                            rows="4"
                                            cols="50"
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={12}>
                                {getCounterFromCaseStatus(
                                    caseData.CaseStatus
                                ) >= 6 ? (
                                    <CaseReportBlock
                                        label="Who was involved"
                                        displayMessage={ClosingReport.who}
                                    />
                                ) : (
                                    <Form.Item
                                        label="Who was involved"
                                        name="who"
                                        rules={[
                                            {
                                                required:
                                                    caseClosingForm.getFieldValue(
                                                        "template"
                                                    ) !== "Lack of response",
                                                message:
                                                    "Please enter Who it happened to",
                                            },
                                        ]}
                                        initialValue={ClosingReport.who}>
                                        <Input.TextArea
                                            name="who"
                                            rows="4"
                                            readOnly={
                                                getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6
                                            }
                                            cols="50"
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>

                        <Row gutter={[16, 48]}>
                            <Col span={12} style={{ paddingRight: "2rem" }}>
                                {getCounterFromCaseStatus(
                                    caseData.CaseStatus
                                ) >= 6 ? (
                                    <CaseReportBlock
                                        label="Remark"
                                        displayMessage={ClosingReport.remarks}
                                    />
                                ) : (
                                    <Form.Item
                                        label="Remarks"
                                        name="remarks"
                                        rules={[
                                            {
                                                required:
                                                    caseClosingForm.getFieldValue(
                                                        "template"
                                                    ) !== "Lack of response",
                                                message:
                                                    "Please enter the Remarks",
                                            },
                                        ]}
                                        initialValue={ClosingReport.remarks}>
                                        <Input.TextArea
                                            name="remarks"
                                            rows="4"
                                            readOnly={
                                                getCounterFromCaseStatus(
                                                    caseData.CaseStatus
                                                ) >= 6
                                            }
                                            cols="50"
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                <Divider style={{ background: "#a3aed0" }} />
                {/* CT message template box is conditional on availability of phone number(caseData.Complainer)  */}
                {caseData.Complainer ? (
                    <>
                        <div className={styles.remarksBottom}>
                            <div className={styles.caseReportHeading}>
                                {user.role === "REGIONAL_ADMIN" &&
                                caseData.CaseCategory === "POSH"
                                    ? "Acknowledgement Message"
                                    : "Resolving Remarks Message"}
                            </div>
                            <div className={styles.closingMainBox}>
                                {getCounterFromCaseStatus(
                                    caseData.CaseStatus
                                ) >= 6 ? null : (
                                    <>
                                        <div className={styles.headingWrapper}>
                                            01- Basics <hr />
                                        </div>
                                        <hr />
                                    </>
                                )}
                                <Row>
                                    <Col span={12}>
                                        <div
                                            className=""
                                            style={{
                                                paddingRight: "2rem",
                                            }}>
                                            {getCounterFromCaseStatus(
                                                caseData.CaseStatus
                                            ) >= 6 ? (
                                                <CaseReportBlock
                                                    label="Template"
                                                    displayMessage={
                                                        ClosingReport.template
                                                    }
                                                />
                                            ) : (
                                                <Form.Item
                                                    label={
                                                        <label
                                                            className={
                                                                styles.label
                                                            }>
                                                            Template
                                                        </label>
                                                    }
                                                    initialValue={
                                                        ClosingReport.template
                                                    }
                                                    name="template"
                                                    className={styles.lable}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please select Template!",
                                                        },
                                                    ]}>
                                                    <Select>
                                                        {Object.keys(
                                                            allTemplates
                                                        )?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            item
                                                                        }>
                                                                        {item}
                                                                    </Select.Option>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="">
                                            {getCounterFromCaseStatus(
                                                caseData.CaseStatus
                                            ) >= 6 ? (
                                                <>
                                                    <CaseReportBlock
                                                        label="Language"
                                                        displayMessage={
                                                            ClosingReport.language
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <Form.Item
                                                    label={
                                                        <label
                                                            className={
                                                                styles.label
                                                            }>
                                                            Language
                                                        </label>
                                                    }
                                                    initialValue={
                                                        ClosingReport.language
                                                    }
                                                    className={styles.lable}
                                                    name="language"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please select Language!",
                                                        },
                                                    ]}>
                                                    <Select>
                                                        {Object.keys(
                                                            allTemplates
                                                        ).length > 0 &&
                                                            template &&
                                                            allTemplates.hasOwnProperty(
                                                                template
                                                            ) &&
                                                            Object.keys(
                                                                allTemplates[
                                                                    template
                                                                ]
                                                            ).map((val) => (
                                                                <Select.Option
                                                                    key={val}
                                                                    value={val}>
                                                                    {val}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {getCounterFromCaseStatus(
                                        caseData.CaseStatus
                                    ) >= 6 ? null : (
                                        <>
                                            <div
                                                className={
                                                    styles.headingWrapper
                                                }>
                                                02- Variables
                                                <hr />
                                            </div>
                                            <div className={styles.variableBox}>
                                                {variableData === ""
                                                    ? null
                                                    : Object.keys(variableData)
                                                          .length === 0
                                                    ? "There are no variable entries for this template"
                                                    : variableData?.map(
                                                          (item, i) => {
                                                              return (
                                                                  <>
                                                                      <MultiTranslationDynamicVariable
                                                                          key={
                                                                              item.var
                                                                          }
                                                                          item={
                                                                              item.var
                                                                          }
                                                                          language={
                                                                              item.language
                                                                          }
                                                                          userInputVar={
                                                                              variableData
                                                                          }
                                                                          setTranslatedDataObj={
                                                                              setTranslatedDataObj
                                                                          }
                                                                          translatedDataObj={
                                                                              translatedDataObj
                                                                          }
                                                                          prefilledInputValue={
                                                                              draftFlag &&
                                                                              prefilledInputValue &&
                                                                              prefilledInputValue[0]
                                                                                  ?.InputValue
                                                                          }
                                                                          isTemplateValue={
                                                                              isTemplateValue
                                                                          }
                                                                          setCountTranslatedMsg={
                                                                              setCountTranslatedMsg
                                                                          }
                                                                          setCount={
                                                                              setCount
                                                                          }
                                                                          count={
                                                                              count
                                                                          }
                                                                          setInputChangeFlag={
                                                                              setInputChangeFlag
                                                                          }
                                                                          inputChangeFlag={
                                                                              inputChangeFlag
                                                                          }
                                                                          draftFlag={
                                                                              draftFlag
                                                                          }
                                                                      />
                                                                  </>
                                                              );
                                                          }
                                                      )}
                                            </div>
                                        </>
                                    )}
                                </Row>
                                <Row>
                                    {getCounterFromCaseStatus(
                                        caseData.CaseStatus
                                    ) >= 6 ? (
                                        <Row gutter={[16, 48]}>
                                            <Col span={12}>
                                                <CaseReportBlock
                                                    label="Message"
                                                    displayMessage={
                                                        ClosingReport.message
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <div className={styles.headingWrapper}>
                                            <p>03 - Message</p>
                                            <hr />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}>
                                                {isLoading && <Spin />}
                                            </div>

                                            {/* to show message only when all the input field are filled and all the input message are translated */}
                                            {count ===
                                            Object.keys(variableData).length ? (
                                                <div
                                                    style={
                                                        isLoading
                                                            ? {
                                                                  filter: "blur(5px)",
                                                                  pointerEvents:
                                                                      "none",
                                                              }
                                                            : {}
                                                    }
                                                    className={
                                                        styles.messageBox
                                                    }>
                                                    {previewScreenData?.body}
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </div>
                        <Divider style={{ background: "#a3aed0" }} />
                    </>
                ) : null}
                <Row align="middle" justify="end">
                    <Col>
                        {getCounterFromCaseStatus(caseData.CaseStatus) < 6 && (
                            <Popover
                                content={
                                    <div>
                                        <p>
                                            All the filled information on the
                                            Case Report will get erased.
                                        </p>
                                    </div>
                                }
                                title="Clear All">
                                <button
                                    className="secondaryButton"
                                    onClick={() => {
                                        caseClosingForm.resetFields();
                                    }}>
                                    Clear All
                                </button>
                            </Popover>
                        )}
                    </Col>
                    <Col>
                        {getCounterFromCaseStatus(caseData.CaseStatus) < 6 &&
                            caseData.CaseCategory !== "POSH" && (
                                <Popover
                                    content={
                                        <div>
                                            <p>Draft will be saved</p>
                                        </div>
                                    }
                                    title="Save Draft">
                                    <button
                                        className="secondaryButton"
                                        onClick={() => {
                                            showModalDraft();
                                            handleCaseClosingDraft();
                                        }}>
                                        Save Draft
                                    </button>
                                </Popover>
                            )}
                    </Col>
                    <Col>
                        {getCounterFromCaseStatus(caseData.CaseStatus) < 6 && (
                            <Popover
                                content={
                                    <div>
                                        <p>
                                            {caseClosingForm.getFieldValue(
                                                "template"
                                            ) === "Lack of response" ||
                                            caseClosingForm.getFieldValue(
                                                "template"
                                            ) ===
                                                "POSH case closure (No response)" ||
                                            caseClosingForm.getFieldValue(
                                                "template"
                                            ) === "POSH case closure"
                                                ? "Case will be Closed"
                                                : "Case will be Resolve"}
                                        </p>
                                    </div>
                                }
                                title={
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "Lack of response" ||
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "POSH case closure" ||
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "POSH case closure (No response)" ||
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "POSH Lack of response"
                                        ? "Close"
                                        : "Resolve"
                                }>
                                <button
                                    className="primaryButton"
                                    htmltype="submit"
                                    onClick={() => {
                                        showModalClose();
                                        handleCaseClosingSubmission();
                                    }}>
                                    {caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "Lack of response" ||
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "POSH case closure (No response)" ||
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "POSH case closure" ||
                                    caseClosingForm.getFieldValue(
                                        "template"
                                    ) === "POSH Lack of response"
                                        ? "Close the case"
                                        : "Submit Report"}
                                </button>
                            </Popover>
                        )}
                    </Col>

                    <Col>
                        {getCounterFromCaseStatus(caseData.CaseStatus) >= 6 &&
                            (user.role === "CT" ||
                                (user.role === "REGIONAL_ADMIN" &&
                                    // previousRoute.defaultRoute.includes(
                                    //     "critical=true"
                                    // )
                                    (caseData.CaseCategory == "POSH" ||
                                        caseData.CaseCategory ==
                                            "Special Cases"))) && (
                                <>
                                    {getCounterFromCaseStatus(
                                        caseData.CaseStatus
                                    ) === 6 ? (
                                        <Popover
                                            content={
                                                <div>
                                                    <p>
                                                        Case will be closed.
                                                        Ensure there is positive
                                                        feedback to Close the
                                                        Case.
                                                    </p>
                                                </div>
                                            }
                                            title="Closed">
                                            <button
                                                className="primaryButton"
                                                onClick={showModalResolve}>
                                                Close the Case
                                            </button>
                                        </Popover>
                                    ) : null}
                                </>
                            )}
                    </Col>
                    <Col>
                        {getCounterFromCaseStatus(caseData.CaseStatus) === 6 &&
                            (user.role === "CT" ||
                                (user.role === "REGIONAL_ADMIN" &&
                                    // previousRoute.defaultRoute.includes(
                                    //     "critical=true"
                                    // )
                                    (caseData.CaseCategory == "POSH" ||
                                        caseData.CaseCategory ==
                                            "Special Cases"))) && (
                                <>
                                    <Popover
                                        content={
                                            <div>
                                                <p>
                                                    Case will be reopened. If
                                                    you reopen the case after 72
                                                    hours of closing it then a
                                                    new case will be created.
                                                </p>
                                            </div>
                                        }
                                        title="Reopen">
                                        <button
                                            className="secondaryButton"
                                            onClick={() => {
                                                setIsReopenModalVisible(true);
                                            }}>
                                            Reopen
                                        </button>
                                    </Popover>
                                </>
                            )}
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
