import React from "react";
import { useState } from "react";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Column } = Table;
const DynamicTable = ({ data, columns }) => {
    const [filterTable, setFilterTable] = useState([]);

    const onSearch = (value) => {
        const filterTable = data.filter((record) => {
            return Object.keys(record).some((key) =>
                String(record[key]).toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilterTable(filterTable);
    };

    const onChange = (pagination, filters, sorter) => {
        const filterTable = [...data];
        if (sorter.order === "ascend") {
            filterTable.sort((a, b) => {
                return a[sorter.field] > b[sorter.field] ? 1 : -1;
            });
        } else if (sorter.order === "descend") {
            filterTable.sort((a, b) => {
                return a[sorter.field] < b[sorter.field] ? 1 : -1;
            });
        }
        setFilterTable(filterTable);
    };

    return (
        <>
            <div style={{ alignItems: "flex-end" }}>
                <div style={{ marginBottom: 16, float: "right" }}>
                    <Input
                        placeholder="Search Table"
                        prefix={<SearchOutlined />}
                        allowClear
                        onChange={(e) => onSearch(e.target.value)}
                        style={{ width: 200, marginRight: 8 }}
                    />
                </div>
                <Table
                    columns={columns}
                    dataSource={filterTable.length > 0 ? filterTable : data}
                    pagination={{ pageSize: 10 }}
                    bordered
                    size="middle"
                    scroll={{ x: "max-content" }}
                    style={{ marginBottom: "2rem", borderRadius: "2%" }}
                    onChange={onChange}>
                    {columns.map((column) => (
                        <Column key={column.dataIndex} {...column} />
                    ))}
                </Table>
            </div>
        </>
    );
};

export default DynamicTable;
