import { Spin, Table } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import NoCasesTable from "../NoCasesTable";
import styles from "../TableData.module.css";

const NewCaseTable = ({
    loading,
    cases,
    columns,
    handleRowClick,
    filterTableData,
}) => {
    const [colorValue, setColorValue] = useState("#E7E8FF");

    useEffect(() => {
        //changing the color from new-purlpe to white-unread after 7 sec
        const timer = setTimeout(() => {
            cases.map((c) =>
                c.CurrentStatus === "new_cases" ? setColorValue("white") : ""
            );
        }, 7000);

        return () => clearTimeout(timer);
    }, [cases]);

    return (
        <div>
            {loading ? (
                <Spin />
            ) : cases.length == 0 ? (
                <NoCasesTable />
            ) : (
                <Table
                    pagination={{
                        showSizeChanger: false,
                        pageSize: 10,
                    }}
                    scroll={{ y: 400 }}
                    columns={columns}
                    dataSource={
                        filterTableData.length > 0 ? filterTableData : cases
                    }
                    size="middle"
                    rowKey="CaseNumber"
                    className={styles.tableContainer}
                    bordered
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record.id),
                        style: {
                            background:
                                record.CurrentStatus === "new_cases"
                                    ? colorValue
                                    : record.CurrentStatus === "Read" ||
                                      record.CurrentStatus === "Draft"
                                    ? "#F2F2F2"
                                    : record.CurrentStatus === "Unread"
                                    ? "white"
                                    : "white",
                        },
                        transition: "background-color 0.5s ease",
                    })}
                />
            )}
        </div>
    );
};

export default NewCaseTable;
