import React from "react";
import styles from "../../CaseReport.module.css";
import CaseReportTabItem from "../CaseReportTabItem/";

const keysToIterate = [
    "CaseNumber",
    "Date",
    "Time",
    "CaseStatus",
    "ReportingMedium",
];
export default function CaseDetailsGeneral({ caseData }) {
    let caseDetailsRightDisplay = "";
    if (caseData?.ReportingMedium == "Call") {
        caseDetailsRightDisplay = (
            <>
                <h4>Phone Call</h4>
                <audio
                    onContextMenu={(e) => e.preventDefault()}
                    controls
                    src={caseData.CallRecording_url}
                    controlsList="nodownload">
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            </>
        );
    } else if (caseData?.ReportingMedium == "SMS") {
        caseDetailsRightDisplay = (
            <>
                <h4>SMS</h4>
                <p>{caseData.SMS_Data}</p>
            </>
        );
    } else if (
        caseData?.ReportingMedium == "In Person" ||
        caseData?.ReportingMedium == "Suggestion Box" ||
        caseData?.ReportingMedium == "Worker Committee"
    ) {
        caseDetailsRightDisplay = (
            <div className={styles.singleItem}>
                <h4 style={{ paddingRight: "5%", width: "60%" }}>
                    Message from Worker:
                </h4>
                <p>{caseData.MessagebyWorker}</p>
            </div>
        );
    }

    return (
        <div className={styles.caseDetailsSection}>
            <div className={styles.caseDetailsLeft}>
                {keysToIterate.map((item, index) => {
                    return (
                        <CaseReportTabItem
                            tabValue={caseData[item]}
                            tabName={item}
                            key={index}
                        />
                    );
                })}
            </div>
            <div className={styles.caseDetailsRight}>
                {caseDetailsRightDisplay}
            </div>
        </div>
    );
}
