import { common_axios } from "../utils/AxiosSettings";
import axios from "axios";

export async function getNewAccessFromRefresh(accessToken) {
    const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/api/token/refresh/ `,
        {
            refresh: accessToken,
        }
    );
    return res;
}
