/* Container managing global state and pasing them to tableContainer*/
import React, { useState } from "react";
import TableContainer from "../TableContainer";
import DynamicTable from "../../Utils/DynamicTable/DynamicTable";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import { tokenState } from "../../../RecoilState/tokenState";
import { factoryState } from "../../../RecoilState/FactoryState";
import AdminDashboard from "../../AdminDashboards/AdminDashboard";
import { Layout } from "antd";
import CollapsableMenu from "../../CollapsableMenu";
import ResetPasswordForm from "../../../pages/Login/ResetPasswordAfterLogin";
import { useLocation } from "react-router";
export default function MainHome() {
    const location = useLocation();
    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    const [factory, setFactory] = useRecoilState(factoryState);
    const [resetview, setResetView] = useState(
        location.search.split("=")[1] || "false"
    );
    if (resetview === "true") {
        return (
            <Layout>
                <ResetPasswordForm></ResetPasswordForm>
            </Layout>
        );
    } else {
        if (
            user.role === "SUPER_ADMIN" ||
            user.role === "FACTORY_ADMIN" ||
            user.role === "REGIONAL_ADMIN"
        ) {
            return (
                <Layout>
                    <AdminDashboard />
                </Layout>
            );
        } else {
            return (
                <Layout>
                    <CollapsableMenu></CollapsableMenu>
                    <TableContainer user={user} selectedFactory={factory} />
                </Layout>
            );
        }
    }
}
