import React, { useEffect, useState } from "react";
import styles from "./Charts.module.css";
import { Column } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";

export default function TThreeChart({ caseData }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        caseData.subT && setData(caseData.subT);
    }, [caseData.subT]);
    const config = {
        data,
        isStack: true,
        xField: "type",
        yField: "value",
        seriesField: "caseType",
        legend: false,
        height: 340,
        width: 600,

        color: ({ caseType }) => {
            if (caseType === "non-breached") {
                return "#CDCFF5";
            } else if (caseType === "breached") {
                return "#FF0000";
            }
        },

        label: {
            style: {
                fill: "#1B2559",
                opacity: 1,
                fontWeight: 700,
                fontSize: 23,
            },
            formatter: function (details) {
                if (details.value == 0) {
                    return " ";
                } else {
                    return details.value;
                }
            },
        },
        theme: {
            styleSheet: {
                fontFamily: "DM Sans",
            },
            maxColumnWidth: 400,
        },
        xAxis: {
            label: {
                position: "middle",
                offsetY: 10,
                style: {
                    fill: "#1B2559",
                    textAlign: "center",
                    lineHeight: 20,
                    fontWeight: 300,
                    fontSize: 25,
                    opacity: 1,
                },
            },
            line: {
                style: {
                    lineWidth: 5,
                    stroke: "#BEBEBE",
                },
            },
        },
        yAxis: {
            title: {
                text: "Number Of Cases Qualified",
                style: { fontSize: 16, fill: "#1B2559" },
            },
            label: {
                //autoHide: false,
            },
            line: {
                style: {
                    lineWidth: 10,
                    stroke: "#BEBEBE",
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: "#BEBEBE",
                        lineWidth: 2,
                        strokeOpacity: 0.6,
                    },
                },
            },
            verticalFactor: 2,
        },
        columnStyle: {
            fillOpacity: 0.6,
            lineWidth: 2,
            stroke: "#1B2559",
        },
        meta: {
            type: {
                alias: "",
            },
            sales: {
                alias: "",
            },
        },
        // annotations,
    };
    return (
        <>
            <div
                className={styles.container + " " + styles.compliance}
                style={{ pageBreakinside: "avoid", pageBreakAfter: "always" }}
                id="compliance-chart">
                <h1>Cases at stage T3</h1>
                <Column {...config} />
            </div>
            <div className={styles.eligibleRightContainer}>
                <div className="">
                    <ul>
                        <li>
                            <b>T3a1-</b> Major (Level 1){" "}
                        </li>
                        <li>
                            <b>T3a2-</b> Major (Level 2)
                        </li>
                        <li>
                            <b>T3b1-</b> Medium (Internal)
                        </li>
                        <li>
                            <b>T3b2-</b> Medium (External)
                        </li>
                        <li>
                            <b>T3c1-</b> Minor (Internal)
                        </li>
                        <li>
                            <b>T3c2-</b> Minor (External)
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
