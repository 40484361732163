/* Component with Casereport deisplay based on CR, CM and CT based reports */
/* Api request for preopulating the data */
/* Two basic forms - Case Details one with dropdown */
/* 2nd form is case closing report only for CT*/

import React, { useState, useEffect } from "react";
import styles from "./CaseReport.module.css";
import INFOcomponent from "./INFOcomponent";
import ATTACHMENTcomponent from "./ATTACHMENTcomponent";
import GENERALcomponent from "./GENERALcomponent";
import { Link, useLocation } from "react-router-dom";
import CaseSendMessage from "../../components/CaseSendMessage";

import LoggedInComponent from "../../containers/LoggedInComponent/LoggedInComponent";
import { getCounterFromCaseStatus } from "../../hooks/getCounterFromCaseStatus";
import { userState } from "../../RecoilState/userState";
import { useRecoilState } from "recoil";
import { previousRouteData } from "../../RecoilState/previousRouteData";
import { caseReportDataState } from "../../RecoilState/caseReportDataState";

export default function CaseReport() {
    const { state } = useLocation();
    const from = state?.myData;
    const [user, setUser] = useRecoilState(userState);
    /*
    Tab based State , Will render differect components 
    based on selectedTab State
    Values : GENERAL,INFO and ATTACHMENT
    */
    const [selectedTab, setSelectedTab] = useState();
    /*
    visible state decides to hide/show Send Message Modal
    */
    const [caseData] = useRecoilState(caseReportDataState);
    useEffect(() => {
        setSelectedTab("GENERAL");
    }, []);
    const [visible, setVisible] = React.useState(false);
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    return (
        <LoggedInComponent>
            <CaseSendMessage
                caseData={from}
                visible={visible}
                makeItVisible={() => {
                    setVisible(true);
                }}
                hideModal={() => {
                    setVisible(false);
                }}
            />
            <div className={styles.casereportContainer}>
                <div className={styles.mainContainer}>
                    <div className={styles.header}>
                        <h4>
                            <Link
                                to={
                                    user.role === "SUPER_ADMIN" ||
                                    user.role === "FACTORY_ADMIN" ||
                                    user.role === "REGIONAL_ADMIN"
                                        ? previousRoute.defaultRoute
                                        : previousRoute.defaultRoute
                                }>
                                <span>
                                    <img
                                        src="/assets/images/back/Group 4495.svg"
                                        alt="back-logo"
                                    />
                                </span>
                            </Link>
                            <span>Case Report</span>
                        </h4>

                        <div className={styles.headerRight}>
                            {/* send message button is conditional on availability of phone number(from.Complainer)  */}
                            {from?.Complainer &&
                            ((user.role == "CM" &&
                                getCounterFromCaseStatus(from.CaseStatus) <
                                    3) ||
                                (user.role == "CR" &&
                                    getCounterFromCaseStatus(from.CaseStatus) <
                                        2) ||
                                ((user.role == "CT" ||
                                    user.role === "REGIONAL_ADMIN") &&
                                    getCounterFromCaseStatus(from.CaseStatus) <
                                        6)) ? (
                                <button
                                    className={styles.topButton}
                                    onClick={() => {
                                        setVisible(true);
                                    }}>
                                    Send Message
                                </button>
                            ) : null}

                            <Link
                                to={`/ViewLogs/${from?.id}`}
                                state={{ from: from }}>
                                <button className={styles.topButton}>
                                    View Logs
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.bottomContainer}>
                        <ul className={styles.tabs}>
                            <li
                                className={
                                    selectedTab === "GENERAL"
                                        ? styles.active
                                        : null
                                }
                                onClick={() => setSelectedTab("GENERAL")}>
                                GENERAL
                            </li>
                            <li
                                className={
                                    selectedTab === "INFO"
                                        ? styles.active
                                        : null
                                }
                                onClick={() => setSelectedTab("INFO")}>
                                INFORMATION
                            </li>
                            <li
                                className={
                                    selectedTab === "ATTACHMENT"
                                        ? styles.active
                                        : null
                                }
                                onClick={() => setSelectedTab("ATTACHMENT")}>
                                ATTACHMENT
                            </li>
                        </ul>
                        <div className={styles.caseRepDivider}></div>
                        <div className={styles.tabContent}>
                            {selectedTab === "GENERAL" ? (
                                <>
                                    <GENERALcomponent caseData={from} />
                                    <div className={styles.mandatoryBottom}>
                                        <span style={{ color: "red" }}>*</span>{" "}
                                        means mandatory
                                    </div>
                                </>
                            ) : selectedTab === "INFO" ? (
                                <INFOcomponent caseData={from} />
                            ) : (
                                caseData && (
                                    <ATTACHMENTcomponent caseData={caseData} />
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LoggedInComponent>
    );
}
