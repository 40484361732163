import React, { useState } from "react";
import { Layout, Modal } from "antd";
import { useEffect } from "react";

import {
    Row,
    Col,
    Form,
    Input,
    InputNumber,
    Select,
    message,
} from "antd";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router";
import { tokenState } from "../../../../../RecoilState/tokenState";
import styles from "../../../AdminDashboard.module.css";
import { userState } from "../../../../../RecoilState/userState";
import { useFetchUserData } from "../UserHooks/useFechUserDataAdmin";
import {
    getUserDetails,
    patchUserDetails,
} from "../../../../../Adapters/userCalls";
import CollapsableMenu from "../../../../CollapsableMenu";
import LoggedInComponent from "../../../../../containers/LoggedInComponent/LoggedInComponent";
import UserRoleTable from "./Components/UserRoleTable";
import EditRoleModal from "./Components/EditRoleModal";
import ReplaceAccessModal from "./Components/ReplaceAccess/ReplaceAccessModal";
import DeleteRoleModal from "./Components/DeleteRoleModal";
import { Link } from "react-router-dom";
import AddRoleModal from "./Components/AddRoleModal";
import useEventMetricsUpdater from "../../../../../hooks/useEventMetricsUpdater";
const { Option } = Select;

const EditUserForm = () => {
    const { fetchUserData } = useFetchUserData();
    const [token, setToken] = useRecoilState(tokenState);
    const [user, setUser] = useRecoilState(userState);
    const [userDataForm] = Form.useForm();
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [phoneNoValidateStatus, setphoneNoValidateStatus] =
        React.useState(null);
    const [phoneNoErrorMsg, setPhoneNoErrorMsg] = React.useState(null);
    const [PhoneNo, setPhoneNo] = React.useState("");
    const pathname = useLocation();
    const [userID, setUserID] = useState(
        pathname.pathname.split("EditUser/")[1]
    );
    const [userData, setUserData] = useState("");
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isTransferModalVisible, setIsTransferModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const navigate = useNavigate();
    const genderList = ["Male", "Female", "Others"];

    function fetchData() {
        setIsLoading(true); // Begin loading state
        if (userID) {
            getUserDetails(userID, token)
                .then((response) => {
                    setUserData(response.data.message_body);
                    setIsLoading(false); // End loading state
                })
                .catch((err) => {
                    // Handle errors
                    setIsLoading(false);
                    message.error(
                        err.message || "Something went wrong! Please try again."
                    );
                });
        }
    }
    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [user.role]);

    function isEmpty(obj) {
        return (
            obj && Object.keys(obj).length === 0 && obj.constructor === Object
        );
    }

    useEffect(() => {
        if (isEmpty(userData.roles)) {
            navigate("/AdminTableView?key=3");
        }
    }, [userData]);
    useEffect(() => {
        if (userData) {
            userDataForm.setFieldsValue({
                Username: userData?.User?.user_name,
                Email: userData?.User?.email,
                Name: userData?.User?.name,
                Gender: userData?.User?.gender,
                PhoneNo: userData?.User?.mobile_number,
            });
        }
    }, [userData]);

    const handleNumberChange = (value) => {
        setPhoneNo(value);
        if (value !== null) {
            const phoneNoValidateStatus = value?.toString().length === 10;
            if (phoneNoValidateStatus) {
                setphoneNoValidateStatus("success");
                setPhoneNoErrorMsg(null);
            } else {
                setphoneNoValidateStatus("error");
                setPhoneNoErrorMsg("Enter Mobile No of 10 Digits");
            }
        } else {
            setphoneNoValidateStatus("success");
            setPhoneNoErrorMsg(null);
        }
    };

    function resetFields() {
        userDataForm.setFieldsValue({
            Username: userData?.User?.user_name,
            Email: userData?.User?.email,
            Name: userData?.User?.name,
            Gender: userData?.User?.gender,
            PhoneNo: userData?.User?.mobile_number,
        });
    }

    function handleUserDataFormSubmit() {
        const errorInfo = userDataForm.getFieldsError();
        if (errorInfo.some((field) => field.errors.length > 0)) {
            return;
        }

        if (isFormChanged) {
            setIsLoading(true);

            const submitData = {
                user_name: userDataForm.getFieldValue("Username"),
                email: userDataForm.getFieldValue("Email"),
                gender: userDataForm.getFieldValue("Gender"),
                mobile_number: userDataForm.getFieldValue("PhoneNo"),
                name: userDataForm.getFieldValue("Name"),
                company_fk: userData.User.company_fk,
            };
            //edit user function call
            patchUserDetails(submitData, userData.User.id)
                .then((response) => {
                    setIsLoading(false);
                    message.success(response.data.message);
                })
                .catch((error) => {
                    setIsLoading(false);
                    message.error(error?.response?.data?.errorMessage);
                    setIsModalVisible(false);
                    fetchData();
                    resetFields();
                });
            fetchUserData();
        } else {
            message.info("No changes were made, Thank you");
        }
        navigate("/AdminTableView?key=3");
    }

    function handleFormChange() {
        setIsFormChanged(true);
    }

    const handleOk = (eventName, actionType) => {
        handleUserDataFormSubmit();
        updateEventMetrics(eventName, actionType);
    };

    const handleCancel = () => {
        if (isFormChanged) {
            setShowConfirmationModal(true);
        } else {
            setIsModalVisible(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        message.error("Failed:", errorInfo);
    };

    const onConfirmationOk = () => {
        fetchUserData();
        setShowConfirmationModal(false);
        setIsModalVisible(false);
        fetchData();
        resetFields();
        setIsFormChanged(false);
        // Handle cancel action here
    };

    const onConfirmationCancel = () => {
        setShowConfirmationModal(false);
    };

    const handleEditRole = (role) => {
        setSelectedRole(role);
        setIsEditModalVisible(true);
    };

    const handleTransferRole = (role) => {
        setSelectedRole(role);
        setIsTransferModalVisible(true);
    };

    const handleDeleteRole = (role) => {
        setSelectedRole(role);
        setIsDeleteModalVisible(true);
    };

    const handleEditModalCancel = () => {
        setSelectedRole(null);
        setIsEditModalVisible(false);
    };

    const handleTransferModalCancel = () => {
        setIsTransferModalVisible(false);
        setSelectedRole(null);
    };

    const handleDeleteModalCancel = () => {
        setSelectedRole(null);
        setIsDeleteModalVisible(false);
    };

    const handleSaveEditedRole = (editedRole) => {
        setIsEditModalVisible(false);
    };

    const handleRoleTransfer = (transferredRole) => {
        setIsTransferModalVisible(false);
    };

    const handleRoleDelete = (roleToDelete) => {
        setIsDeleteModalVisible(false);
    };

    const handleAddRoleClick = () => {
        setIsAddModalVisible(true);
    };

    const handleAddModalCancel = () => {
        setIsAddModalVisible(false);
    };

    const handleSaveNewRole = (newRole) => {
        // Logic to save the new role here (perhaps via an API call)
        setIsAddModalVisible(false);
    };

    return (
        <Layout>
            <LoggedInComponent>
                <CollapsableMenu> </CollapsableMenu>
                <div className={styles.TableContainer}>
                    <div className={styles.header}>
                        <h4>
                            {/* back button to go back to the case report */}
                            <Link to={"/AdminTableView?key=3"}>
                                <span>
                                    <img
                                        src="/assets/images/back/Group 4495.svg"
                                        alt="back-logo"
                                    />
                                </span>
                            </Link>
                            <span>Edit User</span>
                        </h4>
                    </div>
                    <Form
                        id="userDataForm"
                        name="User Detail Form"
                        form={userDataForm}
                        className={isLoading ? ` ${styles.blur}` : null}
                        onValuesChange={handleFormChange}
                        onFinish={handleUserDataFormSubmit}
                        onFinishFailed={onFinishFailed}
                        initialValues={userData}>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Email address"
                                    name="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter Email Address",
                                        },
                                    ]}>
                                    <Input value={userData?.Email} disabled />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter Name",
                                        },
                                    ]}>
                                    <Input value={userData?.Name} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Username"
                                    name="Username"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Role",
                                        },
                                    ]}
                                    initialValue={userData?.Username}>
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    name="PhoneNo"
                                    label="Phone Number"
                                    className="phoneNo"
                                    validateStatus={phoneNoValidateStatus}
                                    help={phoneNoErrorMsg}
                                    rules={[
                                        {
                                            pattern: /^[0-9]+$/,
                                            message:
                                                "Please input numbers only!",
                                        },
                                    ]}>
                                    <InputNumber
                                        maxLength={10}
                                        value={PhoneNo}
                                        prefix="+91"
                                        controls={false}
                                        style={{
                                            width: "100%",
                                        }}
                                        onChange={(e) => handleNumberChange(e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Gender"
                                    name="Gender"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select the gender!",
                                        },
                                    ]}
                                    initialValue={userData?.User?.gender}>
                                    <Select>
                                        {genderList.map((value) => (
                                            <Option value={value}>
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {isAddModalVisible && (
                        <AddRoleModal
                            userSelected={userData.User}
                            visible={isAddModalVisible}
                            onCancel={handleAddModalCancel}
                            onSave={handleSaveNewRole}
                            fetcher={fetchData} // if you need to fetch some data for the AddRoleModal
                        />
                    )}
                    <div
                        className={`primaryBorderStyle ${styles.rowContainer}`}>
                        <Row>
                            <Col span={18} className={styles.colLeft}>
                                <h3>Roles :</h3>
                            </Col>
                            <Col span={6} className={styles.colRight}>
                                <button
                                    className="secondaryButton"
                                    onClick={handleAddRoleClick}>
                                    Add Role
                                </button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <UserRoleTable
                                    roles={userData.roles}
                                    onEdit={handleEditRole}
                                    onTransfer={handleTransferRole}
                                    onDelete={handleDeleteRole}
                                    fetcher={fetchData}
                                />
                            </Col>
                        </Row>
                    </div>

                    {isEditModalVisible && (
                        <EditRoleModal
                            user={userData.User}
                            role={selectedRole}
                            visible={isEditModalVisible}
                            onCancel={handleEditModalCancel}
                            onSave={handleSaveEditedRole}
                            fetcher={fetchData}
                        />
                    )}
                    {isTransferModalVisible && (
                        <ReplaceAccessModal
                            user={userData.User}
                            role={selectedRole}
                            visible={isTransferModalVisible}
                            onCancel={handleTransferModalCancel}
                            onTransfer={handleRoleTransfer}
                            fetcher={fetchData}
                        />
                    )}
                    {isDeleteModalVisible && (
                        <DeleteRoleModal
                            user={userData.User}
                            role={selectedRole}
                            visible={isDeleteModalVisible}
                            onCancel={handleDeleteModalCancel}
                            onDelete={handleRoleDelete}
                            fetcher={() => {
                                fetchData();
                                fetchUserData();
                            }}
                        />
                    )}
                    <Modal
                        title="Confirmation"
                        visible={showConfirmationModal}
                        onOk={onConfirmationOk}
                        onCancel={onConfirmationCancel}
                        footer={[
                            <button
                                key="cancel"
                                onClick={onConfirmationCancel}
                                className="secondaryButton">
                                Cancel
                            </button>,
                            <button
                                key="ok"
                                onClick={onConfirmationOk}
                                className="primaryButton">
                                Confirm
                            </button>,
                        ]}>
                        <p>Are you sure you want to close without saving?</p>
                    </Modal>

                    <Row justify="end" style={{ padding: "0 1rem" }}>
                        {/* <DeleteUserButton
                            isIcon={false}
                            props={userData.User}
                            fetchData={fetchUserData}>
                            Delete
                        </DeleteUserButton> //hidden for now*/}
                        <Col>
                            <button
                                key="ok"
                                onClick={() =>
                                    handleOk(
                                        "editUser",
                                        "Edit User- Save Changes"
                                    )
                                }
                                className="primaryButton">
                                Save Changes
                            </button>
                        </Col>
                    </Row>
                </div>
            </LoggedInComponent>
        </Layout>
    );
};

export default EditUserForm;
