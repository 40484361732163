import React from "react";
import ReactDOM from "react-dom";
import "./main.scss";
import App from "./App";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
const queryClient = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <CookiesProvider>
            <RecoilRoot>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </RecoilRoot>
        </CookiesProvider>
    </QueryClientProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
