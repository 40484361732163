import { common_axios } from "../utils/AxiosSettings";
export async function caseReportFileUpload(formData, caseData) {
    const res = await common_axios.put(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/${caseData.id}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    return res;
}

export async function getCaseSettingsOfCompany(company) {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/casesetting/${company}`
    );

    return res;
}
export async function patchFileUpload(formData, userData) {
    const res = await common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/baseuser/${userData.id}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    return res;
}
export async function UploadImage() {}

export async function createNewCaseWithFile(formData) {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/CaseswithFileUpload/`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    return res;
}

export async function getNumberOfCases() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/caseCount/`
    );
    return res;
}

export async function inPersonCaseUploaderWithoutFile(caseObject) {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/case/upload/`,
        caseObject
    );

    return res;
}

export async function caseMessageSaver(messageCase, caseData) {
    const res = await common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/caseMessager/`,
        {
            Message: messageCase,
            Case: caseData.id,
        }
    );

    return res;
}
export async function getCaseDetailsByID(caseID) {
    const res = common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Cases/${caseID}`
    );
    return res;
}

export async function getViewLog(caseID, token) {
    //reimplement this using useQuery and useParam hook
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/logs/?case=${caseID}`
        // {
        //     headers: {
        //         Authorization: `Bearer ${token}`,
        //     },
        // }
    );
    return res;
}
