import React, { useEffect, useState } from "react";
import { Form, Select, Col, Alert } from "antd";
import { usersList } from "../../../../../../../RecoilState/usersList";
import { useFetchUserData } from "../../../UserHooks/useFechUserDataAdmin";
import { useRecoilState } from "recoil";
const { Option } = Select;

function groupUsersByRoles(data) {
    let groupedData = {};

    data.forEach((item) => {
        if (!groupedData[item.userId]) {
            groupedData[item.userId] = {
                username: item.username,
                Email: item.Email,
                roles: [],
            };
        }
        groupedData[item.userId].roles.push({
            id: item.roleId,
            factory_fk: item.FactoryId,
            role: item.Role,
            Code: item.Factory,
            Region: item.Region,
        });
    });

    return Object.values(groupedData);
}

const ExistingDashboardSelector = ({
    onEmailChange,
    role,
    user,
    currentUser,
    searchbyRole,
    setUser,
    factoryData,
}) => {
    const { fetchUserData, isLoading } = useFetchUserData();
    const [users, setUsers] = useRecoilState(usersList);
    const [RoleClubbedUsers, setRoleClubbedUsers] = useState(null);
    const [dashboardEmail, setDashboardEmail] = useState("");
    const [form] = Form.useForm();

    useEffect(() => {
        setDashboardEmail("");
        form.setFieldsValue({
            dashboardEmail: "",
        });
    }, [role]);
    const UserDetails = ({ email, userListData, setUser }) => {
        const user = userListData.find((user) => user.Email === email);

        if (user) {
            setUser(user);
            const roles = user.roles
                .map((role) => `${role.role} at ${role.Code} Factory`)
                .join(" & ");
            const message = `This user is previously assigned as ${roles}.`;

            return (
                <div
                    style={{
                        display: "flex",
                        position: "relative",
                        // left: "40%", // this style is breaking in add user, "left 40%" will not work for add user
                    }}>
                    <Alert
                        style={{ borderRadius: "10px" }}
                        showIcon
                        message={message}
                        type="info"></Alert>
                </div>
            );
        } else {
            const message = `Can't find user with email ID ${email}. Please make sure the user exists.`;

            return (
                <div
                    style={{
                        display: "flex",
                        position: "relative",
                        left: "40%",
                    }}>
                    <Alert
                        style={{ borderRadius: "10px" }}
                        showIcon
                        message={message}
                        type="error"></Alert>
                </div>
            );
        }
    };

    useEffect(() => {
        const fetchDataAndUpdate = async () => {
            let mergedData;
            if (
                !users ||
                users.length === 0 ||
                users === "" ||
                users === undefined
            ) {
                const fetchedData = await fetchUserData();
                mergedData = groupUsersByRoles(fetchedData); //move this to fetchUserData hook rather than here
            } else {
                mergedData = groupUsersByRoles(users);
            }

            if (currentUser && currentUser.email) {
                mergedData = mergedData.filter(
                    (user) => user.Email !== currentUser.email
                );
            }

            if (searchbyRole && role.role !== "REGIONAL_ADMIN") {
                const matchingUsers = mergedData.filter((user) => {
                    return user.roles.some(
                        (userRole) =>
                            userRole.role === role?.role &&
                            userRole.factory_fk === role?.factory_fk
                    );
                });
                setRoleClubbedUsers(matchingUsers);
            } else if (searchbyRole && role.role === "REGIONAL_ADMIN") {
                const factory = factoryData.find(
                    (factory) => factory.id === role.factory_fk
                );
                const FactoryRegion = factory ? factory.Region : null;
                const matchingUsers = mergedData.filter((user) => {
                    return user.roles.some(
                        (userRole) =>
                            userRole.role === role?.role &&
                            FactoryRegion === userRole.Region
                    );
                });
                setRoleClubbedUsers(matchingUsers);
            } else {
                mergedData = mergedData.filter(
                    (mergeUser) => user.email != mergeUser.Email
                );
                setRoleClubbedUsers(mergedData);
            }
        };
        fetchDataAndUpdate();
    }, [role, currentUser, users, searchbyRole, factoryData]);
    return (
        <>
            <Form.Item
                label="Dashboard Email"
                name="dashboardEmail"
                rules={[
                    {
                        required: true,
                        message: "Please input a dashboard email",
                    },
                ]}>
                <Select
                    showSearch
                    showArrow
                    placeholder="Select a user's email"
                    optionFilterProp="children"
                    value={dashboardEmail}
                    onChange={(value) => {
                        setUser(value);
                        onEmailChange(value);
                        setDashboardEmail(value);
                    }}
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }>
                    {RoleClubbedUsers &&
                        RoleClubbedUsers.map((user) => (
                            <Option key={user.id} value={user.Email}>
                                {user.Email}
                            </Option>
                        ))}
                </Select>
            </Form.Item>
            {dashboardEmail
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) && (
                <UserDetails
                    email={dashboardEmail}
                    userListData={RoleClubbedUsers}
                    setUser={setUser}
                />
            )}
        </>
    );
};

export default ExistingDashboardSelector;
