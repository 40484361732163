import React from "react";
import UserDisplayComp from "../../../../containers/UserDisplayComp";
import styles from "../../CaseReport.module.css";
export default function InfoContainerCard(props) {
    let contentmidDisplay, time, date;

    if (props.left == "Time") {
        time = props.right.split(".")[0];
        time = props.right.split(":")[0] + ":" + props.right.split(":")[1];
        contentmidDisplay = (
            <>
                <div className={styles.left}>{props.left}</div>
                <div className={styles.right}>{time}</div>
            </>
        );
    } else if (props.left == "Date") {
        date = props.right.split("T")[0];
        contentmidDisplay = (
            <>
                <div className={styles.left}>{props.left}</div>
                <div className={styles.right}>{date}</div>
            </>
        );
    } else if (
        props.left == "CaseReporter" ||
        props.left == "CaseTroubleShooter"
    ) {
        contentmidDisplay = (
            <>
                <div className={styles.left}>{props.left}</div>
                <div className={styles.right}>
                    <UserDisplayComp userid={props.right} />
                </div>
            </>
        );
    } else {
        contentmidDisplay = (
            <>
                <div className={styles.left}>{props.left}</div>
                <div className={styles.right}>{props.right}</div>
            </>
        );
    }

    return <div className={styles.item}>{contentmidDisplay}</div>;
}
