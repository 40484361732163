import { Modal, Button } from "antd";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { currentSelectedRoleState } from "../../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import { useNavigate } from "react-router";
import { tokenState } from "../../../RecoilState/tokenState";
import RoleToken from "./RoleTokenGetter";

export default function RoleSelectModal({ isOpen, roles, dataAtLogin }) {
    let navigate = useNavigate();
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const [token, setToken] = useRecoilState(tokenState);
    const [isLoading, setIsLoading] = useState("null");
    const handleRoleSelect = (role) => {
        setCurrentSelectedRole(role);
        setIsLoading("happening");
    };

    return (
        <Modal visible={isOpen} footer={null} closeIcon={true}>
            <h2>Select a Role</h2>
            <ul>
                {roles?.map((role) => (
                    <li key={role.id}>
                        <Button
                            style={{ margin: "10px", border: "1px solid" }}
                            onClick={() => handleRoleSelect(role)}>
                            {`${role.role} `}
                            {role.Code && ` - ${role.Code}`}
                            {role.Location && ` ${role.Location}`}
                            {!role.Location &&
                                !role.Code &&
                                role.region &&
                                `- ${role.region}`}
                        </Button>
                    </li>
                ))}
            </ul>
            {currentSelectedRole && (
                <RoleToken
                    role_id={currentSelectedRole.id}
                    tokens={token}
                    setToken={setToken}
                    loading={isLoading}
                    setter={setIsLoading}
                    token={token}
                />
            )}
            {/* //this code can be modified in better way */}
            {dataAtLogin?.render_reset_password === "false" &&
                isLoading == "done" &&
                navigate("home")}
            {dataAtLogin?.render_reset_password === "true" &&
                isLoading == "done" &&
                navigate("/home?reset=true")}
            {dataAtLogin?.render_reset_password === "false" &&
                dataAtLogin?.language_preference_needed === "true" &&
                isLoading == "done" &&
                navigate("/languagePreference")}
        </Modal>
    );
}
