import axios from "axios";
import { common_axios } from "../utils/AxiosSettings";
export async function caseReviewDoneCall(caseID) {
  let res = await common_axios.put(
    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Case/${caseID}/qc/reviewed/`,
    { Case: caseID }
  );

  return res.data;
}

export async function QCcaseReopener(formData, caseID) {
  const res = await common_axios.post(
    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/Case/qc/reopen/`,
    {
      Case: caseID,
      TOC: formData.ToneOfConversation,
      IC: formData.InternalCommunication,
      QOE: formData.QualityOfEvidence,
      Remarks: formData.Remarks,
    }
  );

  return res.data;
}
export async function getQCreopenedcases(factoryID, company = 1) {
  const res = await common_axios.get(
    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}/factory/${factoryID}/qc/reopened/`
  );
  return res;
}

export async function getQCreviewedCases(factoryID, company = 1) {
  const res = await common_axios.get(
    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}/factory/${factoryID}/qc/reviewed/`
  );
  return res;
}

export async function getQCnewcases(factoryID, company = 1) {
  const res = await common_axios.get(
    `${process.env.REACT_APP_BASE_URL_API}/api/accounts/company/${company}/factory/${factoryID}/qc/newcases/`
  );
  return res;
}
