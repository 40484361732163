import { useState, useEffect } from "react";
import React from "react";
import {
    Row,
    Col,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    Button,
    message,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import styles from "../../AdminDashboard.module.css";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import { useRecoilState } from "recoil";
import { Upload } from "antd";
import { common_axios } from "../../../../utils/AxiosSettings";
import { userState } from "../../../../RecoilState/userState";
import { useNavigate } from "react-router";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { InboxOutlined } from "@ant-design/icons";
import { useFetchFactories } from "../../UserDashboard/UserAction/UserHooks/useFetchFactories";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import useFetchRegion from "../../RegionDashboard/RegionHooks/FetchRegionHook";
import { debounce } from "lodash";

const { Dragger } = Upload;

const { Option } = Select;

const CreateFactory = ({ regionData }) => {
    const [file, setFile] = useState(null);
    const [FactoryDataForm] = Form.useForm();
    const [user, setUser] = useRecoilState(userState);
    const [isFormChanged, setIsFormChanged] = useState(false);
    let navigate = useNavigate();
    const [factorySelected, setFactorySelected] = useRecoilState(factoryState);

    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);

    const { fetchFactoryData, isLoading, factories } = useFetchFactories();
    const { fetchRegionData } = useFetchRegion();
    const [fileError, setFileError] = useState(null);
    const { updateEventMetrics } = useEventMetricsUpdater();
    useEffect(() => {
        FactoryDataForm.setFieldsValue({
            requiredAwarenessProgram: 4,
        });
    }, []);

    function handleUserDataFormSubmit(eventName, actionType) {
        if (isFormChanged) {
            // Check if a file is selected and its extension is csv
            if (!file || (file && file.type === "text/csv")) {
                const submitData = {
                    Company:
                        user.role === "REGIONAL_ADMIN"
                            ? FactoryList[0]?.Company_id
                            : factorySelected?.Company_id,
                    Code: FactoryDataForm.getFieldValue("Code"),
                    Location: FactoryDataForm.getFieldValue("Location"),
                    Number: `91${FactoryDataForm.getFieldValue("Number")}`,
                    region:
                        user.role === "REGIONAL_ADMIN"
                            ? user.region_fk
                            : FactoryDataForm.getFieldValue("Region"),
                    requiredAwarenessProgram: FactoryDataForm.getFieldValue(
                        "requiredAwarenessProgram"
                    ),
                };
                common_axios
                    .post(
                        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/factories/`,
                        submitData
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            message.success(response.data.message);

                            if (file) {
                                const formData = new FormData();
                                formData.append("csv_file", file);
                                formData.append(
                                    "Company",
                                    factorySelected.Company_id
                                );
                                formData.append("Factory", response.data.id);

                                common_axios
                                    .post(
                                        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/complainers/`,
                                        formData
                                    )
                                    .then((response2) => {
                                        message.success(
                                            response2?.data?.message
                                        );
                                    })
                                    .catch((error) => {
                                        message.error(
                                            error?.response?.data?.errorMessage
                                        );
                                    });
                            }

                            navigate("/AdminTableView?key=2");
                            fetchFactoryData();
                            fetchRegionData();
                            updateEventMetrics(eventName, actionType);
                        } else {
                            message.error(
                                "An error occurred while processing your request."
                            );
                        }
                    })
                    .catch((error) => {
                        if (
                            error?.response?.status >= 400 &&
                            error?.response?.status < 500
                        ) {
                            const errorMessage = error?.response?.data?.Code;
                            message.error(`Error: ${errorMessage}`);
                            if (
                                errorMessage ===
                                "factory with this Code already exists."
                            ) {
                                const errormsg = [
                                    "Factory with this number already exist, please enter a different Factory Number",
                                ];
                                FactoryDataForm.setFields([
                                    {
                                        name: "Code",
                                        errors: errormsg,
                                    },
                                ]);
                            }
                        } else {
                            message.error(
                                "An error occurred while processing your request."
                            );
                            message.error(error?.response?.data);
                        }
                    });
            } else {
                message.error("Please upload Master Data File in CSV format");
            }
        } else {
            message.info("No changes were made, Thank you");
        }
    }

    function handleFormChange() {
        setIsFormChanged(true);
    }

    const onFileChange = (info) => {
        const fileList = info.fileList;
        if (fileList.length > 0) {
            const file = fileList[0].originFileObj;
            setFile(file);
        } else {
            setFile(null);
            setFileError(null); // Reset the error when no file is selected
        }
    };
    const initialValues = {
        Region: user.role === "REGIONAL_ADMIN" ? user.region : null,
    };
    const debouncehandler = debounce((eventName, actionType) => {
        handleUserDataFormSubmit(eventName, actionType);
    }, 500);
    return (
        <>
            {initialValues.Region !== "" && (
                <div>
                    <Form
                        name="Factory Detail Form"
                        form={FactoryDataForm}
                        initialValues={initialValues}
                        onValuesChange={handleFormChange}
                        onFinish={() =>
                            debouncehandler(
                                "createFatory",
                                "Add Factory - Create Factory "
                            )
                        }>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Company"
                                    name="Company"
                                    value={
                                        user.role === "REGIONAL_ADMIN"
                                            ? FactoryList[0]?.Company
                                            : factorySelected?.Company
                                    }>
                                    <Input
                                        defaultValue={
                                            user.role === "REGIONAL_ADMIN"
                                                ? FactoryList[0]?.Company
                                                : factorySelected?.Company
                                        }
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Factory Number"
                                    name="Code"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Factory Number",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9]+$/,
                                            message:
                                                "Factory Number must be alphanumeric",
                                        },
                                    ]}>
                                    <Input maxLength={10} />
                                </Form.Item>
                                <Form.Item
                                    label="Region"
                                    name="Region"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select a Region",
                                        },
                                    ]}>
                                    <Select
                                        defaultValue={
                                            user.role === "REGIONAL_ADMIN"
                                                ? user.region
                                                : ""
                                        }
                                        disabled={
                                            user.role === "REGIONAL_ADMIN"
                                        }>
                                        {regionData.map((region) => (
                                            <Option
                                                key={region.id}
                                                value={region.id}>
                                                {region.Name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Upload Master Data"
                                    name="csv_file"
                                    help={fileError} // Display the error message
                                    validateStatus={fileError ? "error" : ""}>
                                    <Dragger
                                        accept=".csv"
                                        beforeUpload={(file) => {
                                            if (file.type !== "text/csv") {
                                                setFileError(
                                                    "Please upload a correct format of Master Data File, required format is CSV"
                                                );
                                                return false;
                                            } else {
                                                setFileError(null); // Reset the error
                                                setFile([file]);
                                                return false; // prevent automatic upload
                                            }
                                        }}
                                        onChange={onFileChange}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to
                                            upload
                                        </p>
                                        <p className="ant-upload-hint">
                                            Support for a single CSV file
                                            upload.
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Location"
                                    name="Location"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter the Location",
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col
                                style={{
                                    width: "50%",
                                }}>
                                <Button
                                    className={styles.outlineButton}
                                    onClick={() => {
                                        const url = `/assets/Doc/MasterDataTemplate.csv`;
                                        const link =
                                            document.createElement("a");
                                        link.href = url;
                                        link.download = "template.csv";
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}>
                                    <DownloadOutlined />
                                    Download Sample Template
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ width: "100%" }}>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Inache Number"
                                    name="Number"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select an Inache Number",
                                        },
                                        {
                                            pattern: /^[0-9]+$/,
                                            message:
                                                "Please input numbers only!",
                                        },
                                    ]}>
                                    <Input
                                        maxLength={10}
                                        addonBefore="+91"></Input>
                                </Form.Item>
                            </Col>
                            <Col style={{ width: "50%" }}>
                                <Form.Item
                                    label="Mandatory Awareness Programme"
                                    name="requiredAwarenessProgram"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please enter no of Mandatory Awareness Programme",
                                        },
                                        {
                                            pattern: /^[0-9]+$/,
                                            message:
                                                "Please enter a valid number",
                                        },
                                        {
                                            type: "number",
                                            max: 99,
                                            message:
                                                "Please enter a number below 100",
                                        },
                                        {
                                            type: "number",
                                            min: 0,
                                            message:
                                                "Please enter a positive number",
                                        },
                                    ]}>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        maxLength={3}
                                        min={0}
                                        max={99}
                                        placeholder="00"
                                        pattern="[0-9]*"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Row justify="end" style={{ padding: "0 1rem" }}>
                                <Col>
                                    <button
                                        className="primaryButton"
                                        htmlType="submit">
                                        Create Factory
                                    </button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </>
    );
};

export default CreateFactory;
