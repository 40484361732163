/* Will present Case Details forwarded in case object from backend */
import React from "react";
import styles from "../CaseReport.module.css";
import InfoContainerCard from "./InfoContainerCard";
export default function INFOcomponent(props) {
    return (
        <div className={styles.InfoContainer}>
            {[
                "CaseNumber",
                "Date",
                // "Time",
                "CaseDetails",
                "CaseCategory",
                "SubCategory",
                "CaseReporter",
                "CaseTroubleShooter",
            ].map((item, index) => {
                return (
                    <InfoContainerCard
                        key={index}
                        left={item}
                        right={props.caseData[item]}
                    />
                );
            })}
        </div>
    );
}
