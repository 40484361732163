import { common_axios } from "../utils/AxiosSettings";

export async function putNewUser(submitData) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?operation=user`,
        submitData
    );
    return res;
}

export async function addPlusAssignUser(submitData) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/add-assign/?operation=user`,
        submitData
    );
    return res;
}

export async function AssignCasesToFrom(dashboardEmail, newemailid) {
    const res = common_axios.put(
        `${
            process.env.REACT_APP_BASE_URL_API
        }/api/accounts/assign-cases/?method=assign-case&fromEmailaddress=${encodeURIComponent(
            dashboardEmail
        )}&to=${newemailid}`
    );
    return res;
}

export const assignCases = (fromRole, toRole) => {
    const url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/assign-cases/?method=assign-case`;
    const params = {
        operation: "assign-case",
        fromRole: fromRole,
        toRole: toRole,
    };

    return common_axios.put(url, {}, { params: params });
};

export async function getUserDataAdmin(
    role,
    company_fk,
    factory_fk,
    region_fk
) {
    let url = "";
    if (role === "FACTORY_ADMIN") {
        url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?operation=admin-list&company=${company_fk}&factory=${factory_fk}`;
    }
    if (role === "SUPER_ADMIN") {
        url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?operation=admin-list&company=${company_fk}`;
    }
    if (role === "REGIONAL_ADMIN") {
        url = `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?operation=admin-list&company=${company_fk}&region=${region_fk}`;
    }
    const res = common_axios.get(url);

    return res;
}

export async function patchRoleDataOfUser(roleId, formData) {
    const res = common_axios.patch(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?id=${roleId}&operation=role`,
        formData
    );

    return res;
}

export async function postNewRole(formData) {
    const res = common_axios.post(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?operation=role`,
        formData
    );

    return res;
}
export async function deleteRole(roleId) {
    const response = await common_axios.delete(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/users/?id=${roleId}&operation=role`
    );

    return response;
}

export async function getAllRoles(token) {
    if (token.access) {
        const res = await common_axios.get(
            `${process.env.REACT_APP_BASE_URL_API}/api/accounts/roles/`
        );
        return res;
    }
}

export async function assignCase(operation, fromRole, toRole) {
    let res;
    const isEmail = (str) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(str);
    };

    const isInteger = (value) => {
        if (typeof value === "string") {
            return Number.isInteger(parseInt(value, 10));
        }
        return Number.isInteger(value);
    };

    switch (operation) {
        case "split":
            if (!isInteger(fromRole))
                throw new Error(
                    "fromRole should be an integer for split operation!"
                );
            res = await common_axios.put(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/assign-cases/?operation=split-case&fromRole=${fromRole}`
            );
            break;

        case "merge":
            if (!isInteger(fromRole) || !isInteger(toRole))
                throw new Error(
                    "fromRole and toRole should be integers for merge operation!"
                );
            res = await common_axios.put(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/assign-cases/?operation=merge-case&fromRole=${fromRole}&toRole=${toRole}`
            );
            break;

        case "assign":
            if (!isEmail(toRole))
                throw new Error(
                    "toRole should be an email for assign operation!"
                );
            res = await common_axios.put(
                `${process.env.REACT_APP_BASE_URL_API}/api/accounts/assign-cases/?operation=assign-case&fromRole=${fromRole}&toRole=${toRole}`
            );
            break;

        default:
            throw new Error("Invalid operation provided!");
    }

    return res;
}
//to get Region data
export async function getRegionData() {
    const res = await common_axios.get(
        `${process.env.REACT_APP_BASE_URL_API}/api/accounts/region/`
    );

    return res;
}
