/* header component with dropdown for factory selection*/
import React, { Suspense } from "react";
import Navbar from "../../@setproduct-ui/core/Navbar";
import NavbarGroup from "../../@setproduct-ui/core/Navbar/Group.jsx";
import NavbarHeading from "../../@setproduct-ui/core/Navbar/Heading.jsx";
import NavbarDivider from "../../@setproduct-ui/core/Navbar/Divider.jsx";
import styles from "./Header.module.css";
import { useRecoilState } from "recoil";
import { userState } from "../../RecoilState/userState";
import { Link } from "react-router-dom";
import { factoryState } from "../../RecoilState/FactoryState";
import UserProfileMenu from "./UserProfileMenu";
import { useEffect } from "react";
import { UserImageState } from "../../RecoilState/UserImageState";
import { ProfileModalState } from "../../RecoilState/ProfileModalState";
import { convertroleToText } from "../../utils/convertroleToText";
import { useLocation } from "react-router-dom";
import { getAllFactoriesOfCompany } from "../../Adapters/FactoryAndCompanyCalls";
import { tokenState } from "../../RecoilState/tokenState";
import { FactoryData } from "../../RecoilState/FactoryData";
import { currentSelectedRoleState } from "../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import RolesDropdown from "./RoleFactoryDropdown";
import DocumentViewer from "../UploadComponent/ShowDocHeaderFile";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs.js";
import useLogout from "../../hooks/useLogout";
import { Cookies} from "react-cookie";

export default function Header() {
    const [user, setUser] = useRecoilState(userState);
    const [showDropper, setshowDropper] = React.useState(false);
    const [FactoryDataList, setFactoryDataList] = useRecoilState(FactoryData);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [UserImage, setUserImage] = useRecoilState(UserImageState);
    const [showProfile, setshowProfile] = useRecoilState(ProfileModalState);
    const [token, setToken] = useRecoilState(tokenState);
    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );
    const location = useLocation();
    const cookies = new Cookies();
    const { handleLogout } = useLogout();

    const handleOnIdle = async (event) => {
        if (localStorage.getItem("flag") == "true") {
            localStorage.setItem("flag", false);
        }
       await handleLogout("Idle Logout");
    };
    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        onActive: {},
        onAction: {},
        debounce: 500,
        disabled: cookies.get("token") ? false : true,
    });

    function QCFunctionChecker() {
        let factoryCondition;
        if (!FactorySelected) {
            factoryCondition = user?.QCFactory == user.factory_fk;
        }
        if (FactorySelected) {
            factoryCondition = user?.QCFactory == FactorySelected.id;
        }
        if (
            factoryCondition && // if user factory is same as selected factory
            user?.QCMonth == new Date().getMonth() + 1 && // if user month is same as current month
            user?.QCYear == new Date().getFullYear() // if user year is same as current year
        ) {
            return true;
        } else {
            return false;
        }
    }
    const handleHover = () => {
        setshowDropper(true);
    };

    const handleLeave = () => {
        setshowDropper(false);
    };
    let userRole = convertroleToText(user.role);
    useEffect(() => {
        if (localStorage.getItem("current-selected-role")) {
            setCurrentSelectedRole(
                JSON.parse(localStorage.getItem("current-selected-role"))
            );
        }
    }, []);

    useEffect(() => {
        if (currentSelectedRole) {
            localStorage.setItem(
                "current-selected-role",
                JSON.stringify(currentSelectedRole)
            );
        }
    }, [currentSelectedRole]);
    useEffect(() => {
        if (location.pathname == "/login" || location.pathname == "/") {
        } else {
            if (token && user.company_fk != undefined) {
                if (!FactoryDataList && user.company_fk != undefined) {
                    getAllFactoriesOfCompany(
                        user.company_fk,
                        token.access,
                        user.role === "REGIONAL_ADMIN" && "region"
                    ).then((res) => {
                        const updatedFactoryList =
                            res?.data?.message_body?.Factories.map((item) => {
                                return { ...item, key: item.id };
                            });
                        setFactoryDataList(updatedFactoryList);
                        if (
                            user.role === "SUPER_ADMIN" ||
                            user.role === "REGIONAL_ADMIN"
                        ) {
                            const filteredFactories =
                                res?.data?.message_body?.Factories.filter(
                                    (f) => f.id == user.factory_fk
                                );
                            setFactorySelected(
                                filteredFactories.length > 0
                                    ? filteredFactories[0]
                                    : res?.data?.message_body?.Factories[0]
                            );
                        } else {
                            setFactorySelected(
                                res.data.message_body.Factories.filter(
                                    (f) => f.id == user.factory_fk
                                )[0]
                            );
                        }
                    });
                }
            }
        }
        return () => {};
    }, [user]);

    let roleFinal;

    if (FactoryDataList) {
        if (QCFunctionChecker()) {
            roleFinal = "Quality Checker";
        }
        if (user.role === "SUPER_ADMIN") {
            roleFinal = "Super Admin";
        } else if (
            user.factory_fk !==
            (FactorySelected ? FactorySelected?.id : FactoryDataList[0]?.id)
        ) {
            roleFinal = "You have no role in this Factory!";
        } else {
            roleFinal = userRole;
        }
    }
    if (user !== "" && location.pathname !== "/") {
        return (
            <Navbar type="tablet" view="smooth" id="naver" color="primary">
                <NavbarGroup className={styles.leftNavbar} align="left">
                    <RolesDropdown />
                </NavbarGroup>

                <NavbarGroup className={styles.centerNavbar} align="center">
                    <div className={styles.login}>
                        <Link to="/home">
                            <NavbarHeading>
                                {" "}
                                <img
                                    src="/assets/images/Logos/InacheLogoIcon.png"
                                    height={55}
                                />{" "}
                            </NavbarHeading>
                        </Link>
                    </div>
                </NavbarGroup>

                <NavbarGroup
                    align="right"
                    className={styles.rightNavbar}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        onClick={() => {
                            setshowProfile(!showProfile);
                        }}>
                        <DocumentViewer
                            document_delete_on={false}
                            document_download_on={false}
                            documentIds={user.profile_picture}
                            model={"profile_picture"}
                            model_id={user.id}
                            previewSize="40px"
                        />
                        <p style={{ whiteSpace: "nowrap" }}>
                            {user.user_name ? user.user_name : user.email}
                        </p>
                    </div>
                    <div className={styles.expandMore}>
                        <img
                            src="/assets/images/icon/navigation/expand_more_24px.svg"
                            height={10}
                            width={20}
                            style={{ marginLeft: "2em" }}
                            onClick={() => {
                                setshowDropper(!showDropper);
                            }}
                        />
                        {true && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <UserProfileMenu
                                    openProfile={showProfile}
                                    setOpenProfile={setshowProfile}
                                    userData={user}
                                    showDropper={showDropper}
                                    setshowDropper={setshowDropper}
                                />
                            </Suspense>
                        )}
                    </div>
                </NavbarGroup>
            </Navbar>
        );
    } else {
        return (
            <Navbar type="tablet" view="smooth" color="primary">
                <NavbarGroup className={styles.leftNavbar} align="left">
                    <div className={styles.loginLogo}>
                        <NavbarHeading>
                            {" "}
                            <img
                                src="/assets/images/Logos/InacheLogoIcon.png"
                                height={55}
                            />{" "}
                        </NavbarHeading>
                    </div>
                </NavbarGroup>
                <NavbarDivider />
            </Navbar>
        );
    }
}
