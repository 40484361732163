// Function to calculate session duration
export const calculateSessionDuration = (loginTimestamp, logoutTimestamp) => {
    const loginTime = new Date(loginTimestamp);
    const logoutTime = new Date(logoutTimestamp);
    // Calculate session duration in milliseconds
    const sessionDurationMilliseconds =
        logoutTime.getTime() - loginTime.getTime();
    // Convert session duration to seconds, minutes, and hours
    const sessionDurationInSeconds = Math.floor(
        sessionDurationMilliseconds / 1000
    );
    const sessionDurationInMinutes = Math.floor(sessionDurationInSeconds / 60);
    const sessionDurationInHours = Math.floor(sessionDurationInMinutes / 60);
    return {
        milliseconds: sessionDurationMilliseconds,
        seconds: sessionDurationInSeconds,
        minutes: sessionDurationInMinutes,
        hours: sessionDurationInHours,
    };
};