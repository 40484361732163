/* Component with all Case reporter, Case Manager and Case Troubleshooter forms based on conditional rendering */
import React, { useState } from "react";
import CaseDetailsGeneral from "./CaseDetailsGeneral/";
import styles from "../CaseReport.module.css";

import CaseClosing from "./CaseClosing";
import { Link } from "react-router-dom";
import { Modal, message, Divider } from "antd";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { userState } from "../../../RecoilState/userState";
import { ClosingState } from "../../../RecoilState/ClosingState";

import {
    fetchManagers,
    putCaseData,
    ResolveCaseByCT,
    saveClosingCaseReportCall,
    draftClosingCaseReportCall,
    fetchTRB,
    fetchRA,
} from "../../../Adapters/CaseReportCalls";
import { ReopenCaseCTBySLA } from "../../../Adapters/CaseReportCalls";
import { getAllFactoriesOfCompany } from "../../../Adapters/FactoryAndCompanyCalls";
import { useEffect } from "react";
import { tokenState } from "../../../RecoilState/tokenState";
import CaseReportDetails from "./CaseReportDetails";
import { caseReportDataState } from "../../../RecoilState/caseReportDataState";
import { FactoryData } from "../../../RecoilState/FactoryData";
import { getCounterFromCaseStatus } from "../../../hooks/getCounterFromCaseStatus";
import { previousRouteData } from "../../../RecoilState/previousRouteData";
import { factoryState } from "../../../RecoilState/FactoryState";
import useEventMetricsUpdater from "../../../hooks/useEventMetricsUpdater";
import { getCaseDetailsByID } from "../../../Adapters/CaseCRUDCalls";

const key = "generalcaserep";

export default function GENERALcomponent(caseData) {
    let navigate = useNavigate();

    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);

    const [RAFieldflagValues, setRAFieldflagValues] = useState({
        closeModalFlag: false, //to show close modal for particular template
        closingRemarkMandatory: false, //to set all the fields of "closing remark" for special cases as mandatory and non mandator for RA
        CBRRMandatory: false, //to set "remark"- Comment by RA filed for Posh cases as mandatory and non mandator
    });
    const [conditionalNaviagte, setConditionalNaviagte] = useState("/home");

    const [user, setUser] = useRecoilState(userState);
    const [token, setToken] = useRecoilState(tokenState);
    const [closingReportExist, setClosingReportExist] = useState(false);
    const [caseDatastate, setcaseDatastate] =
        useRecoilState(caseReportDataState);
    const [factoryList, setFactoryList] = useRecoilState(FactoryData);
    const [ManagerData, setManagerData] = useState();
    const [TRBData, setTRBData] = useState();
    const [RAData, setRAData] = useState();
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [translatedDataObj, setTranslatedDataObj] = useState({});
    //using the factory list to get the inache no of the particular factory, which the case belongs
    const fact =
        Array.isArray(factoryList) && factoryList.length
            ? factoryList
                  .map((val) => {
                      if (val.id === caseDatastate.Factory) {
                          return val.Number;
                      }
                  })
                  .filter(Boolean)
            : [];

    //getting the factory details from the getAllFactoriesOfCompany
    // Factory API call

    useEffect(() => {
        caseDatastate.CaseCategory === "POSH" ||
        caseDatastate.CaseCategory === "Special Cases"
            ? setConditionalNaviagte(previousRoute.defaultRoute)
            : setConditionalNaviagte(previousRoute.defaultRoute);
    }, [caseDatastate.CaseCategory]);

    useEffect(() => {
        if (
            !ManagerData &&
            user.company_fk != undefined &&
            (user.factory_fk ? user.factory_fk : FactorySelected?.id)
        ) {
            fetchManagers(
                user.company_fk,
                user.factory_fk ? user.factory_fk : FactorySelected?.id
            ).then((res) => {
                const formattedData = Object.values(res.data.message_body);
                setManagerData(formattedData);
            });
        }
    }, [
        user.company_fk,
        user.factory_fk ? user.factory_fk : FactorySelected?.id,
        setManagerData,
    ]);
    useEffect(() => {
        if (
            !TRBData && user.company_fk != undefined && user.factory_fk
                ? user.factory_fk
                : FactorySelected?.id
        ) {
            fetchTRB(
                user.company_fk,
                user.factory_fk ? user.factory_fk : FactorySelected?.id
            ).then((res) => {
                const formattedData = Object.values(res.data.message_body);
                setTRBData(formattedData);
            });
        }
    }, [
        user.company_fk,
        user.factory_fk ? user.factory_fk : FactorySelected?.id,
        setTRBData,
    ]);
    useEffect(() => {
        if (
            !RAData && user.company_fk != undefined && user.factory_fk
                ? user.factory_fk
                : FactorySelected?.id
        ) {
            fetchRA(
                user.company_fk,
                user.factory_fk ? user.factory_fk : FactorySelected?.id
            ).then((res) => {
                const formattedData = Object.values(res.data.message_body);
                setRAData(formattedData);
            });
        }
    }, [
        user.company_fk,
        user.factory_fk ? user.factory_fk : FactorySelected?.id,
    ]);

    const [Manager, setManager] = React.useState(
        caseDatastate["CaseManager"] ? caseDatastate["CaseManager"] : ""
    );
    const [TroubleShooter, setTroubleShooter] = React.useState(
        caseDatastate["CaseTroubleShooter"]
            ? caseDatastate["TroubleShooter"]
            : ""
    );
    const [ClosingReport, setClosing] = useRecoilState(ClosingState);

    // MODAL LOGIC

    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
    const [isDraftModalVisible, setIsDraftModalVisible] = useState(false);
    const [isResetModalVisible, setIsResetModalVisible] = useState(false);

    const [isResolveModalVisible, setIsResolveModalVisible] = useState(false);
    const [isReopenModalVisible, setIsReopenModalVisible] = useState(false);

    function ReopenCTCase() {
        ReopenCaseCTBySLA(caseDatastate.id).then((res) => {
            message.success(res.data.response);
            navigate(conditionalNaviagte);
        });
    }
    const handleOkReopenModal = () => {
        ReopenCTCase();
        setIsReopenModalVisible(false);
    };
    const handleCancelReopen = () => {
        setIsReopenModalVisible(false);
    };

    function handleDraftButtonCT() {
        {
            draftClosingCaseReportCall(
                ClosingReport,
                caseDatastate?.id,
                caseDatastate.Company,
                closingReportExist
            )
                .then((res) => {
                    if (res.status === 200) {
                        message.success(res.data);
                        navigate(conditionalNaviagte);
                    } else if (res.status === 201) {
                        message.success(res.data.message);
                        navigate(conditionalNaviagte);
                    } else {
                        message.warning(res.data);
                    }
                    updateEventMetrics(
                        "caseDashboardSaveDraft",
                        "Case Dashboard- Save draft"
                    );
                })
                .catch((error) => {
                    //handeling the error if the errorMessag is not coming from backend
                    error?.response?.data?.errorMessage
                        ? message.warning(error?.response?.data?.errorMessage)
                        : message.warning(
                              "Unable to save draft closing report ! Try again later"
                          );
                });
        }
    }
    function handleCloseButtonCT() {
        ResolveCaseByCT(caseDatastate.id).then((res) => {
            res.data
                ? message.success(res.data)
                : message.success("The case is closed now !");
            navigate(conditionalNaviagte);
        });
    }
    const showModalClose = () => {
        setIsCloseModalVisible(true);
    };
    const hideModalClose = () => {
        setIsCloseModalVisible(false);
    };
    const showModalDraft = () => {
        setIsDraftModalVisible(true);
    };
    const hideModalDraft = () => {
        setIsDraftModalVisible(false);
    };
    const handleOkDraftModal = () => {
        handleDraftButtonCT();
        hideModalDraft();
    };

    const handleOkCloseModal = () => {
        handleCloseButtonCT();
        hideModalClose();
    };
    const handleCancelOfClose = () => {
        hideModalClose();
    };
    const hideModalReset = () => {
        setIsResetModalVisible(false);
    };
    const handleOKresetModal = () => {
        setIsResetModalVisible(false);
    };

    const showModalResolve = () => {
        setIsResolveModalVisible(true);
    };

    const handleOkResolveModal = () => {
        // submit reslove
        ResolveCase();
        setIsResolveModalVisible(false);
    };
    function ResolveCase() {
        if (
            (ClosingReport.who === "" ||
                ClosingReport.what === "" ||
                ClosingReport.remarks === "" ||
                ClosingReport.when === "" ||
                ClosingReport.where === "") &&
            RAFieldflagValues.closingRemarkMandatory
        ) {
            message.warning(
                "Please fill the Closing Case Report Mandatory fields(*)"
            );
        } else if (
            (ClosingReport.CCRComments_RA === undefined ||
                ClosingReport.CCRComments_RA === "") &&
            RAFieldflagValues.CBRRMandatory
        ) {
            message.warning("Please fill the Remark Mandatory fields(*)");
        } else {
            saveClosingCaseReportCall(
                ClosingReport,
                caseDatastate?.id,
                caseDatastate.reopened,
                caseDatastate.Company,
                closingReportExist,
                translatedDataObj
            )
                .then((res) => {
                    if (res?.Case) {
                        message.error("Case Closing Report already exists");
                    } else {
                        //set errro msg dynamic
                        message.success(res);
                        navigate(conditionalNaviagte);
                    }
                    updateEventMetrics(
                        "caseDashboardSubmit",
                        "Case Dashboard- Submit"
                    );
                })

                .catch((error) => {
                    //handeling the error if the errorMessag is not coming from backend
                    error?.response?.data?.errorMessage
                        ? message.warning(error?.response?.data?.errorMessage)
                        : message.warning(
                              "Unable to save closing report ! Try again later"
                          );
                });
        }
    }
    const handleCancelResolve = () => {
        setIsResolveModalVisible(false);
    };

    const showModalCancel = () => {
        setIsCancelModalVisible(true);
    };

    const handleOkCancelModal = () => {
        setIsCancelModalVisible(false);
        navigate(previousRoute.defaultRoute);
    };

    const handleCancel = () => {
        setIsCancelModalVisible(false);
    };

    const closingReportExistsFalse = () => {
        setClosingReportExist(false);
    };

    const closingReportExistsTrue = () => {
        setClosingReportExist(true);
    };
    // MODAL LOGIC

    useEffect(() => {
        if (token && caseData?.caseData?.id) {
            getCaseDetailsByID(caseData?.caseData.id).then((res) => {
                setcaseDatastate(res.data);
            });
        }
    }, []);

    function saveDetailsandComments(values) {
        let data = {
            CaseNumber: caseDatastate.CaseNumber,
            Company: caseDatastate.Company,
            Factory: caseDatastate.Factory,
            ReportingMedium: caseDatastate.ReportingMedium,
        };
        switch (user.role) {
            case "CR":
                // Mandatory fields are case details, priority, category, sub cat, case nature, case manager
                data.User = "CR";
                data.RegionalAdmin = values.regionalAdmin
                    ? values.regionalAdmin
                    : "";
                data.CommentsByRep = values.commentsByRep;
                data.CaseDetails = values.caseDetails;
                data.Priority = values.priority;
                data.CaseCategory = values.category;
                data.SubCategory = values.subCategory;
                data.CaseValidation = values.caseValidation;
                data.CaseNature = values.caseNature;
                data.CurrentStatus = "Submit";
                data.CaseManager = values.caseManager;
                data.workerLanguage = values.workersLanguage;
                message.loading({ content: "Loading...", key });

                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        {
                            data.CaseCategory === "POSH" ||
                            data.CaseCategory === "Special Cases"
                                ? message.success({
                                      content:
                                          "Hey CR ! Case is being send to Regional Admin",
                                      key,
                                  })
                                : message.success({
                                      content:
                                          "Hey CR ! Case data and Case manager are updated",
                                      key,
                                  });
                        }

                        navigate(conditionalNaviagte);
                    })

                    .catch((error) => {
                        error?.response?.data?.errorMessage
                            ? message.error(error?.response?.data?.errorMessage)
                            : message.warning(
                                  "Unable to update Case manager ! Try again later"
                              );
                    });
                break;

            case "CM":
                //Mandatory fields are Case troubleshooter and comments
                data.Priority = values.priority;
                data.CaseCategory = values.category;
                data.SubCategory = values.subCategory;
                data.CommentsByMan = values.commentsByMan;
                data.CaseValidation = values.caseValidation;
                data.CaseNature = values.caseNature;
                data.User = "CM";
                data.CurrentStatus = "Submit";
                data.CaseTroubleShooter = values.caseTroubleShooter;

                message.loading({ content: "Loading...", key });

                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content:
                                "Hey CM ! Case data and Case Troubleshooter are updated",
                            key,
                        });
                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case troubleshooter ! Try again later"
                        );
                    });
                break;
            case "CT":
                data.User = "CT";
                data.CurrentStatus = "Submit";
                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success("Hey CT, Case data is updated, ");
                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case data ! Try again later"
                        );
                    });
                break;
        }
    }

    function saveDraftDetailsandComments(values) {
        let data = {
            CaseNumber: caseDatastate.CaseNumber,
            Company: caseDatastate.Company,
            Factory: caseDatastate.Factory,
            ReportingMedium: caseDatastate.ReportingMedium,
        };
        switch (user.role) {
            case "CR":
                data.User = "CR";
                data.CommentsByRep = values.commentsByRep
                    ? values.commentsByRep
                    : "";
                data.CaseDetails = values.caseDetails ? values.caseDetails : "";
                data.Priority = values.priority ? values.priority : "";
                data.CaseCategory = values.category ? values.category : "";
                data.SubCategory = values.subCategory ? values.subCategory : "";
                data.CaseValidation = values.caseValidation;
                data.CaseManager = values.caseManager ? values.caseManager : "";
                data.RegionalAdmin = values.RegionalAdmin
                    ? values.RegionalAdmin
                    : "";

                data.CaseNature = values.caseNature ? values.caseNature : "";
                data.workerLanguage = values.workersLanguage
                    ? values.workersLanguage
                    : "";
                data.CurrentStatus = "Draft";
                message.loading({ content: "Loading...", key });

                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: "Hey CR ! Case data are updated",
                            key,
                        });

                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case Data ! Try again later"
                        );
                    });
                break;
            case "CM":
                data.Priority = values.priority ? values.priority : "";
                data.CaseCategory = values.category ? values.category : "";
                data.SubCategory = values.subCategory ? values.subCategory : "";
                data.CommentsByMan = values.commentsByMan
                    ? values.commentsByMan
                    : "";
                data.CaseValidation = values.caseValidation
                    ? values.caseValidation
                    : "";
                data.CaseNature = values.caseNature ? values.caseNature : "";
                data.User = "CM";
                data.CaseTroubleShooter = values.caseTroubleShooter
                    ? values.caseTroubleShooter
                    : "";
                data.CurrentStatus = "Draft";
                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: "Hey CM ! Case data are updated",
                            key,
                        });
                        navigate(previousRoute.defaultRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case Data ! Try again later"
                        );
                    });
                break;
            case "CT":
                putCaseData(caseDatastate.id, data)
                    .then((res) => {
                        message.success({
                            content: "Hey CT ! Case data are updated",
                            key,
                        });
                        navigate(previousRoute);
                    })
                    .catch((err) => {
                        message.warning(
                            "Unable to update Case Data ! Try again later"
                        );
                    });
        }
    }

    function handleStateChangeOFTRB(value) {
        setTroubleShooter(value);
    }

    return (
        <>
            <CaseDetailsGeneral caseData={caseDatastate} />
            <Divider className={styles.caseRepDivider} />
            <CaseReportDetails
                setIsResetModalVisible={setIsResetModalVisible}
                caseData={caseDatastate}
                showModalCancel={showModalCancel}
                saveDetailsandComments={saveDetailsandComments}
                saveDraftDetailsandComments={saveDraftDetailsandComments}
                user={user}
                TRBData={TRBData}
                handleStateChangeOFTRB={handleStateChangeOFTRB}
                caseDatastate={caseDatastate}
                Manager={Manager}
                ManagerData={ManagerData}
                RAData={RAData}
            />
            <Modal
                title="Cancel"
                visible={isCancelModalVisible}
                onOk={handleOkCancelModal}
                onCancel={handleCancel}>
                <p>Do you want to exit the case report ? </p>
            </Modal>
            <Modal
                title="Draft"
                visible={isDraftModalVisible}
                onOk={handleOkDraftModal}
                onCancel={hideModalDraft}>
                <p>Do you want to save the case as Draft?</p>
            </Modal>
            <Modal
                title="Close"
                visible={isResolveModalVisible}
                onOk={handleOkCloseModal}
                onCancel={handleCancelResolve}>
                <p>
                    Do you want to Close Case ? Please ensure you have received
                    positive feedback(
                    <Link to={`/ViewLogs/${caseDatastate?.id}`}>
                        Check Logs
                    </Link>
                    ) to Close Case.
                </p>
            </Modal>
            <Modal
                title="Reset"
                visible={isResetModalVisible}
                onOk={handleOKresetModal}
                onCancel={hideModalReset}>
                <p>Do you want to clear Input data ?</p>
            </Modal>
            <Modal
                title={!RAFieldflagValues.closeModalFlag ? "Resolve" : "Close"}
                visible={isCloseModalVisible}
                onOk={handleOkResolveModal}
                onCancel={handleCancelOfClose}>
                <p>
                    {!RAFieldflagValues.closeModalFlag
                        ? "Do you want to Resolve Case ?"
                        : "Do you want to Close the Case ?"}
                </p>
            </Modal>
            <Modal
                title="Reopen"
                visible={isReopenModalVisible}
                onOk={handleOkReopenModal}
                onCancel={handleCancelReopen}>
                <p>Do you want to Reopen Case ?</p>
            </Modal>
            {caseDatastate.CaseValidation && (
                <Divider className={styles.caseRepDivider} />
            )}

            {/* for status Resolved and more */}

            {(user.role === "CT" ||
                ((user.role === "REGIONAL_ADMIN" ||
                    user.role === "SUPER_ADMIN" ||
                    user.role === "FACTORY_ADMIN") &&
                    (getCounterFromCaseStatus(caseDatastate.CaseStatus) >= 6 ||
                        //previousRoute.defaultRoute.includes("critical=true")
                        caseDatastate.CaseCategory == "POSH" ||
                        caseDatastate.CaseCategory == "Special Cases") &&
                    caseDatastate.CaseValidation)) && (
                <>
                    <CaseClosing
                        user={user}
                        caseData={caseDatastate}
                        inacheNo={fact[0]}
                        showModalDraft={showModalDraft}
                        showModalClose={showModalClose}
                        showModalResolve={showModalResolve}
                        setIsReopenModalVisible={setIsReopenModalVisible}
                        closingReportExistsFalse={closingReportExistsFalse}
                        closingReportExistsTrue={closingReportExistsTrue}
                        translatedDataObj={translatedDataObj}
                        setTranslatedDataObj={setTranslatedDataObj}
                        setRAFieldflagValues={setRAFieldflagValues}
                    />
                </>
            )}
        </>
    );
}
