import React from "react";
import { Select, Form, Row, Col } from "antd";
import styles from "../../pages/CaseReport/CaseReport.module.css";

const BasicSendMessage = ({
    template,
    allTemplates,
    settemplate,
    setLanguage,
    getVariableData,
    setActiveTab,
}) => {
    const onFormLayoutChange = (values) => {
        if ("template" in values) {
            settemplate(values.template);
        } else if ("language" in values) {
            setLanguage(values.language);
        }
    };

    return (
        <>
            <Form onValuesChange={onFormLayoutChange}>
                <div className={styles.mainWrapper}>
                    <Row>
                        <Col span={24}>
                            <div
                                style={{
                                    paddingRight: "2rem",
                                }}>
                                <Form.Item
                                    label={
                                        <label className={styles.label}>
                                            Template
                                        </label>
                                    }
                                    name="template"
                                    className={styles.lable}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Template!",
                                        },
                                    ]}>
                                    <Select>
                                        {Object.keys(allTemplates).map(
                                            (item, index) => {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={item}>
                                                        {item}
                                                    </Select.Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div
                                style={{
                                    paddingRight: "2rem",
                                }}>
                                <Form.Item
                                    label={
                                        <label className={styles.label}>
                                            Language
                                        </label>
                                    }
                                    className={styles.lable}
                                    name="language"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Language!",
                                        },
                                    ]}>
                                    <Select>
                                        {Object.keys(allTemplates).length &&
                                            template &&
                                            Object.keys(
                                                allTemplates[template]
                                            ).map((val) => (
                                                <Select.Option
                                                    key={val}
                                                    value={val}>
                                                    {val}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row justify="end">
                    <Col>
                        <button
                            className={styles.solidButton}
                            onClick={() => (
                                getVariableData(), setActiveTab("2")
                            )}>
                            Next
                        </button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default BasicSendMessage;
