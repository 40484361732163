import React from "react";
import styles from "../../CaseReport.module.css";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";

import {
    Button,
    Select,
    Popover,
    Form,
    Input,
    Radio,
    Row,
    Col,
    Divider,
} from "antd";
import UserDisplayComp from "../../../../containers/UserDisplayComp";
import { NestedCategories } from "../../../../utils/shahiCategories";
import { Upload, message } from "antd";
import { fileUrlState } from "../../../../RecoilState/fileUrlState";
import { useRecoilState } from "recoil";
import {
    caseReportFileUpload,
    getCaseDetailsByID,
} from "../../../../Adapters/CaseCRUDCalls";
import { useState, useEffect } from "react";
import CaseReportBlock from "../../../../components/Utils/CaseReportBlock";
import { caseReportDataState } from "../../../../RecoilState/caseReportDataState";
import { valueUpdateFlag } from "../../../../RecoilState/valueUpdateFlag";
import { getCounterFromCaseStatus } from "../../../../hooks/getCounterFromCaseStatus";
import UploadComponent from "../../../../components/UploadComponent/UploadHeaderFile";
import DocumentViewer from "../../../../components/UploadComponent/ShowDocHeaderFile";
import {
    getLanguageList,
    ResolveCaseByCT,
} from "../../../../Adapters/CaseReportCalls";
import { useNavigate } from "react-router";
import { previousRouteData } from "../../../../RecoilState/previousRouteData";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { debounce } from "lodash";
const { Option } = Select;
const { Dragger } = Upload;

export default function CaseReportDetails({
    saveDetailsandComments,
    saveDraftDetailsandComments,
    user,
    TRBData,
    ManagerData,
    RAData,
    // getCounterFromCaseStatus,
}) {
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const [fileRecoil, setfileRecoil] = useRecoilState(fileUrlState);
    const categoryOptions = [];
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [grievanceOptions, setGrievanceOptions] = useState([]);
    const [caseDetailsForm] = Form.useForm();
    const [caseDatastate, setCaseDataState] =
        useRecoilState(caseReportDataState);
    const [caseValueUpdateFlag, setCaseValueUpdateFlag] =
        useRecoilState(valueUpdateFlag);
    const [uploadedFiles, setUploadedFiles] = useState(caseDatastate.File);
    const refreshFile = () => {
        getCaseDetailsByID(caseDatastate.id).then((res) => {
            setUploadedFiles(res.data.File);
            setCaseDataState(res.data);
        });
    };
    const [caseValidation, setCaseValidation] = useState(true);
    const { updateEventMetrics } = useEventMetricsUpdater();
    let navigate = useNavigate();
    useEffect(() => {
        if (
            caseDatastate.CaseCategory !== null &&
            caseDatastate.CaseCategory !== undefined &&
            caseDatastate.CaseCategory !== "" &&
            caseDatastate.CaseCategory !== "Invalid"
        ) {
            for (const j in NestedCategories[caseDatastate?.CaseCategory]) {
                setSubCategoryOptions((prev) => [
                    ...prev,
                    {
                        label: j,
                        value: j,
                    },
                ]);
            }
            for (const j in NestedCategories[caseDatastate?.CaseCategory][
                caseDatastate?.SubCategory
            ]) {
                setGrievanceOptions((prev) => [
                    ...prev,
                    {
                        label: NestedCategories[caseDatastate?.CaseCategory][
                            caseDatastate?.SubCategory
                        ][j],
                        value: NestedCategories[caseDatastate?.CaseCategory][
                            caseDatastate?.SubCategory
                        ][j],
                    },
                ]);
            }
        }
    }, []);
    for (let i in NestedCategories) {
        categoryOptions.push({ label: i, value: i });
    }
    const handleCategoryChange = (event) => {
        setSubCategoryOptions([]);

        for (const j in NestedCategories[event]) {
            setSubCategoryOptions((prev) => [
                ...prev,
                {
                    label: j,
                    value: j,
                },
            ]);
        }
        caseDetailsForm.setFieldsValue({ subCategory: null });
        caseDetailsForm.setFieldsValue({ priority: null });
    };

    const handleSubCategoryChange = (event) => {
        setGrievanceOptions([]);
        for (const j in NestedCategories[
            caseDetailsForm.getFieldValue("category")
        ][caseDetailsForm.getFieldValue("subCategory")]) {
            setGrievanceOptions((prev) => [
                ...prev,
                {
                    label: NestedCategories[
                        caseDetailsForm.getFieldValue("category")
                    ][caseDetailsForm.getFieldValue("subCategory")][j],
                    value: NestedCategories[
                        caseDetailsForm.getFieldValue("category")
                    ][caseDetailsForm.getFieldValue("subCategory")][j],
                },
            ]);
        }
        caseDetailsForm.setFieldsValue({ priority: null });
    };

    const handleSubmission = (event, eventName, actionType) => {
        if (!(event.caseValidation === false && user.role === "CR")) {
            saveDetailsandComments(caseDetailsForm.getFieldsValue(true));
            setCaseValueUpdateFlag((prevFlag) => !prevFlag);
        }
        updateEventMetrics(eventName, actionType);
    };

    const handleDraft = (eventName, actionType) => {
        saveDraftDetailsandComments(caseDetailsForm.getFieldsValue(true));
        setCaseValueUpdateFlag(!caseValueUpdateFlag);

        updateEventMetrics(eventName, actionType);
    };

    const fileUploadProps = {
        name: "file",
        onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(
                    `${info.file.name} file uploaded successfully.`
                );
            }
        },
        beforeUpload(file) {
            //clearing the pre-file data
            caseDatastate.CaseFileupload = "";
            let formData = new FormData();
            formData.append("CaseFileupload", file);
            formData.append("Factory", caseDatastate.Factory);
            formData.append("CaseNumber", caseDatastate.CaseNumber);
            formData.append("CaseStatus", caseDatastate.CaseStatus);
            formData.append("Company", caseDatastate.Company);

            formData.append(
                "ReportingMedium",
                caseDatastate.ReportingMedium
                    ? caseDatastate.ReportingMedium
                    : "None"
            );

            caseReportFileUpload(formData, caseDatastate)
                .then((res) => {
                    message.success("File is uploaded");
                    setfileRecoil(
                        `${process.env.REACT_APP_BASE_URL_API}${res.data.CaseFileupload}`
                    );
                })
                .then((res) => {})
                .catch((err) => {
                    message.warning("Unable to upload file ! Try again later");
                });
        },
    };
    //setting all the initial values of form
    useEffect(() => {
        setCaseValidation(caseDatastate.CaseValidation);

        caseDetailsForm.setFieldsValue({
            caseDetails: caseDatastate.CaseDetails,
            caseValidation: caseDatastate.CaseValidation,
            caseNature: caseDatastate.CaseNature,
            category: caseDatastate.CaseCategory,
            Document: caseDatastate.CaseFileupload,
            caseReporter: caseDatastate.CaseReporter,
            caseManager: caseDatastate.CaseManager,
            subCategory: caseDatastate.SubCategory,
            commentsByRep: caseDatastate.CommentsByRep,
            priority: caseDatastate.Priority,
            caseTroubleShooter: caseDatastate.CaseTroubleShooter,
            commentsByMan: caseDatastate.CommentsByMan,
            workersLanguage: caseDatastate.workerLanguage,
        });
        setUploadedFiles(caseDatastate?.File);
    }, [caseDatastate, RAData]);

    // The function to close an invalid case
    const closeInvalidCase = async () => {
        try {
            const res = await ResolveCaseByCT(caseDatastate.id);
            if (res) {
                message.success("Hey CR! The case is closed now!");
                navigate(previousRoute.defaultRoute);
            } else {
                message.error("Failed to close the case. Please try again.");
            }
        } catch (error) {
            message.error(
                "An error occurred while closing the case. Please try again later."
            );
            console.error(error);
        }
    };
    const [languageList, setLanguageList] = useState("");

    useEffect(() => {
        if (caseDatastate?.ReportingMedium === "Call") {
            const fetchData = async () => {
                try {
                    const res = await getLanguageList();

                    setLanguageList(res.data);
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            };

            fetchData();
        }
    }, [caseDatastate?.ReportingMedium]);
    const onValuesChangeHandler = () => {
        const selectedCategory = caseDetailsForm.getFieldValue("category");
    };

    const debouncedHandleDraft = debounce(handleDraft, 500);
    const debounceHandleSubmission = debounce(handleSubmission, 500);
    return (
        <>
            <div style={{ position: "relative" }}>
                <Form
                    name="Case Details Form"
                    form={caseDetailsForm}
                    initialValues={caseDatastate}
                    onValuesChange={onValuesChangeHandler}
                    onFinish={(e) =>
                        debounceHandleSubmission(
                            e,
                            "caseDashboardSubmit",
                            "Case Dashboard- Submit"
                        )
                    }>
                    <Row gutter={[16, 48]}>
                        {caseDatastate?.ReportingMedium === "Call" &&
                        getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                            2 &&
                        user.role === "CR" ? (
                            <Col span={12}>
                                <Form.Item
                                    initialValue={caseDatastate.workerLanguage}
                                    name="workersLanguage"
                                    label="Worker's Language"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Language",
                                        },
                                    ]}>
                                    <Select>
                                        {/* <Option>abc</Option> */}
                                        {languageList?.languages?.map(
                                            (lang, index) => (
                                                <>
                                                    <Option
                                                        key={index}
                                                        value={lang}>
                                                        {lang}
                                                    </Option>
                                                </>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            <Col span={12}>
                                <CaseReportBlock
                                    label="Worker's Language"
                                    displayMessage={
                                        caseDatastate.workerLanguage
                                    }
                                />
                            </Col>
                        )}
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role === "CR") ||
                            user.role === "SUPER_ADMIN" ||
                            user.role === "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Case Reporter"
                                            displayMessage={
                                                <UserDisplayComp
                                                    userid={
                                                        caseDatastate[
                                                            "CaseReporter"
                                                        ]
                                                    }
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="caseReporter"
                                    label="Reporter"
                                    className={styles.label}>
                                    <Select
                                        placeholder="Select Reporter"
                                        disabled={true}
                                        options={[
                                            {
                                                label: (
                                                    <UserDisplayComp
                                                        userid={
                                                            caseDatastate[
                                                                "CaseReporter"
                                                            ]
                                                        }
                                                    />
                                                ),
                                                value: caseDatastate[
                                                    "CaseReporter"
                                                ],
                                            },
                                        ]}></Select>
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]} className={styles.topPadding}>
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role == "CR") ||
                            user.role === "CM" ||
                            user.role === "SUPER_ADMIN" ||
                            user.role == "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Case Details"
                                            displayMessage={
                                                caseDatastate.CaseDetails
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="caseDetails"
                                    label="Case Details"
                                    className={styles.label}
                                    initialValue={caseDatastate.CaseDetails}
                                    rules={[
                                        {
                                            required:
                                                caseValidation &&
                                                user.role === "CR",
                                            message:
                                                "Please enter the Case Details",
                                        },
                                    ]}>
                                    <Input.TextArea
                                        disabled={!caseValidation}
                                        readOnly={
                                            user.role === "CR" ? false : true
                                        }
                                        rows={4}
                                        cols={50}
                                    />
                                </Form.Item>
                            )}
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role === "CR") ||
                            user.role === "CM" ||
                            user.role === "SUPER_ADMIN" ||
                            user.role == "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={12}>
                                        <CaseReportBlock
                                            label="Case Valid"
                                            displayMessage={caseDatastate?.CaseValidation?.toString()}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="caseValidation"
                                    label="Case Valid"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                caseValidation &&
                                                user.role === "CR",
                                            message:
                                                "Please enter the Case Validation",
                                        },
                                    ]}>
                                    <Radio.Group
                                        onChange={(e) => {
                                            setCaseValidation(e.target.value);
                                        }}>
                                        <Radio
                                            value={true}
                                            disabled={
                                                user.role !== "CR"
                                                    ? true
                                                    : false
                                            }>
                                            Yes
                                        </Radio>
                                        <Radio
                                            value={false}
                                            disabled={
                                                user.role !== "CR"
                                                    ? true
                                                    : false
                                            }>
                                            No
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="Document"
                                label="Document"
                                className={styles.label}>
                                <UploadComponent
                                    document_delete_on={
                                        (getCounterFromCaseStatus(
                                            caseDatastate.CaseStatus
                                        ) > 3 &&
                                            !(
                                                (getCounterFromCaseStatus(
                                                    caseDatastate.CaseStatus
                                                ) === 5 ||
                                                    getCounterFromCaseStatus(
                                                        caseDatastate.CaseStatus
                                                    ) === 4) &&
                                                (user.role === "CT" ||
                                                    user.role ===
                                                        "REGIONAL_ADMIN")
                                            )) ||
                                        (getCounterFromCaseStatus(
                                            caseDatastate.CaseStatus
                                        ) >= 2 &&
                                            user.role === "CR") ||
                                        user.role === "SUPER_ADMIN" ||
                                        user.role == "FACTORY_ADMIN" ||
                                        (user.role === "REGIONAL_ADMIN" &&
                                            // previousRoute.defaultRoute.includes(
                                            //     "critical=false"
                                            // )
                                            caseDatastate.CaseCategory !=
                                                "POSH" &&
                                            caseDatastate.CaseCategory !=
                                                "Special Cases") ||
                                        (user.role === "CM" &&
                                            getCounterFromCaseStatus(
                                                caseDatastate.CaseStatus
                                            ) > 2)
                                            ? false
                                            : true
                                    }
                                    document_download_on={
                                        (getCounterFromCaseStatus(
                                            caseDatastate.CaseStatus
                                        ) > 3 &&
                                            !(
                                                (getCounterFromCaseStatus(
                                                    caseDatastate.CaseStatus
                                                ) === 5 ||
                                                    getCounterFromCaseStatus(
                                                        caseDatastate.CaseStatus
                                                    ) === 4) &&
                                                (user.role === "CT" ||
                                                    user.role ===
                                                        "REGIONAL_ADMIN")
                                            )) ||
                                        (getCounterFromCaseStatus(
                                            caseDatastate.CaseStatus
                                        ) >= 2 &&
                                            user.role === "CR") ||
                                        user.role === "SUPER_ADMIN" ||
                                        user.role == "FACTORY_ADMIN" ||
                                        (user.role === "REGIONAL_ADMIN" &&
                                            // previousRoute.defaultRoute.includes(
                                            //     "critical=false"
                                            // )
                                            caseDatastate.CaseCategory !=
                                                "POSH" &&
                                            caseDatastate.CaseCategory !=
                                                "Special Cases") ||
                                        (user.role === "CM" &&
                                            getCounterFromCaseStatus(
                                                caseDatastate.CaseStatus
                                            ) > 2)
                                            ? false
                                            : true
                                    }
                                    disableUpload={
                                        (getCounterFromCaseStatus(
                                            caseDatastate.CaseStatus
                                        ) > 3 &&
                                            !(
                                                (getCounterFromCaseStatus(
                                                    caseDatastate.CaseStatus
                                                ) === 5 ||
                                                    getCounterFromCaseStatus(
                                                        caseDatastate.CaseStatus
                                                    ) === 4) &&
                                                (user.role === "CT" ||
                                                    user.role ===
                                                        "REGIONAL_ADMIN")
                                            )) ||
                                        (getCounterFromCaseStatus(
                                            caseDatastate.CaseStatus
                                        ) >= 2 &&
                                            user.role === "CR") ||
                                        user.role === "SUPER_ADMIN" ||
                                        user.role == "FACTORY_ADMIN" ||
                                        (user.role === "REGIONAL_ADMIN" &&
                                            // previousRoute.defaultRoute.includes(
                                            //     "critical=false"
                                            // )
                                            caseDatastate.CaseCategory !=
                                                "POSH" &&
                                            caseDatastate.CaseCategory !=
                                                "Special Cases") ||
                                        (user.role === "CM" &&
                                            getCounterFromCaseStatus(
                                                caseDatastate.CaseStatus
                                            ) > 2)
                                            ? true
                                            : false
                                    }
                                    Files={uploadedFiles}
                                    maxFiles="7"
                                    scrollable={true}
                                    companyFk={user.company_fk}
                                    model="case"
                                    allowedFileTypes={[
                                        "jpeg",
                                        "jpg",
                                        "png",
                                        "svg",
                                        "pdf",
                                        "csv",
                                        "pps",
                                        "ppt",
                                        "pptx",
                                        "txt",
                                        "xls",
                                        "xlsx",
                                        "xlsm",
                                        "gif",
                                        "doc",
                                        "docx",
                                        "tex",
                                        "rtf",
                                        "mp4",
                                        "mpg",
                                        "mpeg",
                                        "mov",
                                        "avi",
                                        "log",
                                        "aif",
                                        "mp3",
                                        "mpa",
                                        "wav",
                                        "wma",
                                    ]}
                                    id={caseDatastate.id}
                                    refresh={refreshFile}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role === "CR") ||
                            user.role === "SUPER_ADMIN" ||
                            user.role === "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Case Nature"
                                            displayMessage={
                                                caseDatastate.CaseNature
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <>
                                    <Form.Item
                                        name="caseNature"
                                        label="Case Nature"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter the Case Nature",
                                            },
                                        ]}>
                                        <Select
                                            disabled={
                                                user.role === "CT" ||
                                                !caseValidation
                                            }>
                                            <Option value="Complaint">
                                                Complaint
                                            </Option>
                                            <Option value="Query">Query</Option>
                                            <Option value="Suggestion">
                                                Suggestion
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role === "CR") ||
                            user.role === "SUPER_ADMIN" ||
                            user.role === "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Category"
                                            displayMessage={
                                                caseDatastate.CaseCategory
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="category"
                                    label="Category"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                caseValidation &&
                                                user.role === "CR",
                                            message:
                                                "Please enter the Category",
                                        },
                                    ]}>
                                    <Select
                                        disabled={
                                            user.role === "CT" ||
                                            !caseValidation
                                        }
                                        defaultValue={
                                            caseDatastate.CaseCategory
                                        }
                                        onChange={handleCategoryChange}
                                        options={categoryOptions}></Select>
                                </Form.Item>
                            )}
                        </Col>

                        {caseDetailsForm.getFieldValue("category") !== "POSH" &&
                        caseDetailsForm.getFieldValue("category") !==
                            "Special Cases" ? (
                            <Col span={12}>
                                {getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 3 ||
                                (getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 2 &&
                                    user.role === "CR") ||
                                user.role === "SUPER_ADMIN" ||
                                user.role == "FACTORY_ADMIN" ||
                                user.role === "REGIONAL_ADMIN" ? (
                                    <Row gutter={[16, 48]}>
                                        <Col span={22}>
                                            <CaseReportBlock
                                                label="Case Manager"
                                                displayMessage={
                                                    <UserDisplayComp
                                                        userid={
                                                            caseDatastate[
                                                                "CaseManager"
                                                            ]
                                                        }
                                                    />
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Form.Item
                                        name="caseManager"
                                        label="Manager"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    caseValidation &&
                                                    user.role === "CR" &&
                                                    caseDetailsForm.getFieldValue(
                                                        "category"
                                                    ) !== "POSH" &&
                                                    caseDetailsForm.getFieldValue(
                                                        "category"
                                                    ) !== "Special Cases",
                                                message:
                                                    "Please enter the Manager",
                                            },
                                        ]}>
                                        <Select
                                            placeholder="Select Manager"
                                            disabled={
                                                caseValidation === false ||
                                                user.role == "CT" ||
                                                user.role == "CM" ||
                                                caseDetailsForm.getFieldValue(
                                                    "category"
                                                ) === "POSH" ||
                                                caseDetailsForm.getFieldValue(
                                                    "category"
                                                ) === "Special Cases"
                                                    ? true
                                                    : false
                                            }>
                                            {ManagerData?.length > 0 &&
                                                ManagerData?.map(
                                                    (item, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={
                                                                    item.role_id
                                                                }>
                                                                {
                                                                    item.user
                                                                        .email
                                                                }
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Col>
                        ) : (
                            <Col span={12}>
                                {getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 3 ||
                                (getCounterFromCaseStatus(
                                    caseDatastate.CaseStatus
                                ) >= 2 &&
                                    user.role === "CR") ||
                                user.role === "SUPER_ADMIN" ||
                                user.role == "FACTORY_ADMIN" ||
                                user.role === "REGIONAL_ADMIN" ? (
                                    <Row gutter={[16, 48]}>
                                        <Col span={22}>
                                            <CaseReportBlock
                                                label="Regional Admin"
                                                displayMessage={
                                                    caseDatastate.RegionalAdmin && (
                                                        <UserDisplayComp
                                                            userid={
                                                                caseDatastate[
                                                                    "RegionalAdmin"
                                                                ]
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Form.Item
                                        name="regionalAdmin"
                                        label="Regional Admin"
                                        className={styles.label}
                                        rules={[
                                            {
                                                required:
                                                    caseValidation &&
                                                    user.role === "CR" &&
                                                    caseDetailsForm.getFieldValue(
                                                        "category"
                                                    ) !== "POSH" &&
                                                    caseDetailsForm.getFieldValue(
                                                        "category"
                                                    ) !== "Special Cases",
                                                message:
                                                    "Please enter the Regional Admin",
                                            },
                                        ]}>
                                        {RAData?.length > 0 ? (
                                            <div>
                                                {/* Set the value of 'regionalAdmin' field in the form */}
                                                {caseDetailsForm.setFieldsValue(
                                                    {
                                                        regionalAdmin:
                                                            RAData[0].role_id,
                                                    }
                                                )}
                                                <Input
                                                    value={RAData[0].user.email}
                                                    placeholder="Regional Admin"
                                                    disabled={
                                                        !caseValidation ||
                                                        user.role === "CT" ||
                                                        user.role === "CM" ||
                                                        caseDetailsForm.getFieldValue(
                                                            "category"
                                                        ) === "POSH" ||
                                                        caseDetailsForm.getFieldValue(
                                                            "category"
                                                        ) === "Special Cases"
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <p>
                                                No Regional Admin data available
                                            </p>
                                        )}
                                    </Form.Item>
                                )}
                            </Col>
                        )}
                    </Row>

                    <Row gutter={[16, 48]}>
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role === "CR") ||
                            user.role === "SUPER_ADMIN" ||
                            user.role === "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Sub-Category"
                                            displayMessage={
                                                caseDatastate.SubCategory
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="subCategory"
                                    label="Sub-Category"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                caseValidation &&
                                                user.role === "CR",
                                            message:
                                                "Please enter the Category",
                                        },
                                    ]}>
                                    <Select
                                        disabled={
                                            user.role === "CT" ||
                                            !caseValidation
                                        }
                                        onSelect={handleSubCategoryChange}>
                                        {subCategoryOptions.map(
                                            ({ value, label }) => (
                                                <options
                                                    key={label}
                                                    label={label}
                                                    value={value}></options>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            )}
                        </Col>
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role == "CR") ||
                            user.role === "CM" ||
                            user.role === "SUPER_ADMIN" ||
                            user.role == "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Comments By Reporter"
                                            displayMessage={
                                                caseDatastate.CommentsByRep
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="commentsByRep"
                                    label="Comments By Reporter"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                caseValidation &&
                                                user.role === "CR",
                                            message: "Please enter the Comment",
                                        },
                                    ]}>
                                    <Input.TextArea
                                        disabled={!caseValidation}
                                        readOnly={
                                            user.role === "CR" ? false : true
                                        }
                                        rows={4}
                                        cols={50}
                                        maxLength={300}
                                        showCount
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 48]}>
                        <Col span={12}>
                            {getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 3 ||
                            (getCounterFromCaseStatus(
                                caseDatastate.CaseStatus
                            ) >= 2 &&
                                user.role === "CR") ||
                            user.role === "SUPER_ADMIN" ||
                            user.role === "FACTORY_ADMIN" ||
                            user.role === "REGIONAL_ADMIN" ? (
                                <Row gutter={[16, 48]}>
                                    <Col span={22}>
                                        <CaseReportBlock
                                            label="Grievance Type"
                                            displayMessage={
                                                caseDatastate.Priority
                                            }
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item
                                    name="priority" //Change name to Grievance type
                                    label="Grievance Type"
                                    className={styles.label}
                                    rules={[
                                        {
                                            required:
                                                caseValidation &&
                                                user.role === "CR" &&
                                                caseDetailsForm.getFieldValue(
                                                    "category"
                                                ) !== "POSH" &&
                                                caseDetailsForm.getFieldValue(
                                                    "category"
                                                ) !== "Special Cases",
                                            message:
                                                "Please enter the Priority",
                                        },
                                    ]}>
                                    <Select
                                        disabled={
                                            user.role === "CT" ||
                                            !caseValidation ||
                                            caseDetailsForm.getFieldValue(
                                                "category"
                                            ) === "POSH" ||
                                            caseDetailsForm.getFieldValue(
                                                "category"
                                            ) === "Special Cases"
                                        }
                                        options={grievanceOptions}></Select>
                                </Form.Item>
                            )}
                        </Col>
                    </Row>

                    {getCounterFromCaseStatus(caseDatastate.CaseStatus) >= 2 &&
                        (user.role == "CM" || user.role == "CT") && (
                            <>
                                <Divider className={styles.caseRepDivider} />
                            </>
                        )}
                    {getCounterFromCaseStatus(caseDatastate.CaseStatus) > 2 &&
                        (user.role === "SUPER_ADMIN" ||
                            user.role === "FACTORY_ADMIN") && (
                            <>
                                <Divider className={styles.caseRepDivider} />
                            </>
                        )}

                    {caseDatastate.CaseValidation === true && (
                        <Row gutter={[16, 48]} className={styles.topPadding}>
                            <Col span={12}>
                                {user.role === "CM" &&
                                    getCounterFromCaseStatus(
                                        caseDatastate.CaseStatus
                                    ) === 2 && (
                                        <Form.Item
                                            name="caseTroubleShooter"
                                            label="TroubleShooter"
                                            className={styles.label}
                                            rules={[
                                                {
                                                    required:
                                                        caseValidation &&
                                                        user.role === "CR",
                                                    message:
                                                        "Please enter the TroubleShooter",
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Select TroubleShooter"
                                                disabled={
                                                    user.role === "CM"
                                                        ? false
                                                        : true
                                                }>
                                                {TRBData?.length > 0
                                                    ? TRBData?.map(
                                                          (item, index) => {
                                                              return (
                                                                  <Option
                                                                      key={
                                                                          index
                                                                      }
                                                                      value={
                                                                          item.role_id
                                                                      }>
                                                                      {
                                                                          item
                                                                              .user
                                                                              .email
                                                                      }
                                                                  </Option>
                                                              );
                                                          }
                                                      )
                                                    : ""}
                                            </Select>
                                        </Form.Item>
                                    )}
                                {(user.role === "CT" ||
                                    user.role === "CM" ||
                                    user.role === "SUPER_ADMIN" ||
                                    user.role === "FACTORY_ADMIN" ||
                                    user.role === "REGIONAL_ADMIN") &&
                                    getCounterFromCaseStatus(
                                        caseDatastate.CaseStatus
                                    ) >= 3 && (
                                        <Row gutter={[16, 48]}>
                                            <Col span={22}>
                                                <CaseReportBlock
                                                    label="TroubleShooter"
                                                    displayMessage={
                                                        <UserDisplayComp
                                                            userid={
                                                                caseDatastate[
                                                                    "CaseTroubleShooter"
                                                                ]
                                                            }
                                                        />
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                            </Col>
                            <Col span={12}>
                                {user.role === "CM" &&
                                    getCounterFromCaseStatus(
                                        caseDatastate.CaseStatus
                                    ) === 2 && (
                                        <Form.Item
                                            name="commentsByMan"
                                            label="Comments By Manager"
                                            className={styles.label}
                                            rules={[
                                                {
                                                    required: caseValidation,
                                                    message:
                                                        "Please enter the Comments",
                                                },
                                            ]}>
                                            <Input.TextArea
                                                readOnly={
                                                    user.role === "CR" ||
                                                    user.role === "CT"
                                                        ? true
                                                        : false
                                                }
                                                rows={4}
                                                cols={50}
                                                maxLength={300}
                                                showCount
                                            />
                                        </Form.Item>
                                    )}
                                {(user.role === "CT" ||
                                    user.role === "CM" ||
                                    user.role === "SUPER_ADMIN" ||
                                    user.role === "FACTORY_ADMIN" ||
                                    user.role === "REGIONAL_ADMIN") &&
                                    getCounterFromCaseStatus(
                                        caseDatastate.CaseStatus
                                    ) >= 3 && (
                                        <Row gutter={[16, 48]}>
                                            <Col span={22}>
                                                <CaseReportBlock
                                                    label="Comments By Manager"
                                                    displayMessage={
                                                        caseDatastate.CommentsByMan
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                            </Col>
                        </Row>
                    )}
                    {((user.role === "CM" &&
                        getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                            3) ||
                        (user.role === "CR" &&
                            getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                                2)) && (
                        <Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}>
                                <Popover
                                    content={
                                        <div>
                                            <p>
                                                All the filled information on
                                                the Case Report will get erased.
                                            </p>
                                        </div>
                                    }
                                    title="Clear All">
                                    <button
                                        className="secondaryButton"
                                        onClick={(e) => {
                                            caseDetailsForm.resetFields();
                                        }}>
                                        Clear All
                                    </button>
                                </Popover>
                                <Popover
                                    content={
                                        <div>
                                            <p>
                                                All the entered information will
                                                be saved in the case report.
                                            </p>
                                        </div>
                                    }
                                    title="Submit">
                                    {caseValidation === false &&
                                    user.role === "CR" ? (
                                        <button
                                            onClick={() => closeInvalidCase()}
                                            className="primaryButton">
                                            Close the Case
                                        </button>
                                    ) : (
                                        <button
                                            htmltype="submit"
                                            className="primaryButton">
                                            Submit Report
                                        </button>
                                    )}
                                </Popover>
                            </div>
                        </Form.Item>
                    )}
                </Form>
            </div>
            <div
                style={{
                    position: "absolute",
                    left: "2.5rem",
                    bottom: " 4.9rem",
                }}>
                {((user.role === "CM" &&
                    getCounterFromCaseStatus(caseDatastate.CaseStatus) < 3) ||
                    (user.role === "CR" &&
                        getCounterFromCaseStatus(caseDatastate.CaseStatus) <
                            2)) && (
                    <Popover
                        content={
                            <div>
                                <p>
                                    All the entered information will be saved as
                                    draft in the case report.
                                </p>
                            </div>
                        }
                        title="Draft">
                        <button
                            onClick={() =>
                                debouncedHandleDraft(
                                    "caseDashboardSaveDraft",
                                    "Case Dashboard- Save draft"
                                )
                            }
                            className="secondaryButton">
                            Save Draft
                        </button>
                    </Popover>
                )}
            </div>
        </>
    );
}
