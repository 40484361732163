import {
    Form,
    Layout,
    Row,
    Col,
    Select,
    Button,
    message,
    Input,
    Space,
} from "antd";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
    getFactoriesByRegion,
    setFactoryToRegion,
    createRegion,
    renameRegion,
} from "../../../../Adapters/RegionCalls";
import LoggedInComponent from "../../../../containers/LoggedInComponent/LoggedInComponent";
import CollapsableMenu from "../../../CollapsableMenu";
import { useRecoilState } from "recoil";
import { regionState } from "../../../../RecoilState/regionState";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import styles from "../../AdminDashboard.module.css";
import RegionFactoryTable from "./RegionFactoryTable";
import { PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import useFetchRegion from "../RegionHooks/FetchRegionHook";
import ConfirmationModal from "./ConfirmationModal";
import { useFetchFactories } from "../../UserDashboard/UserAction/UserHooks/useFetchFactories";
import { EditRegionFooter, AddRegionFooter } from "./UpdateRegionFooter";
import ChangesConfirmationModal from "./ChangesConfirmationModal";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";
import { userState } from "../../../../RecoilState/userState";
import { tokenState } from "../../../../RecoilState/tokenState";

const EditRegion = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const lodash = require("lodash");
    const [user] = useRecoilState(userState);
    const [token] = useRecoilState(tokenState);

    const navigate = useNavigate();
    const inputRef = useRef(null);

    const [regions] = useRecoilState(regionState);
    const [factories, setFactories] = useRecoilState(FactoryData);

    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(state?.id);
    const [selectedFactory, setSelectedFactory] = useState([]);
    const [selectedFactoryName, setSelectedFactoryName] = useState([]);
    const [disableInput, setDisableInput] = useState(true);
    const [defaultFactory, setDefaultFactory] = useState([]);
    const [isOpenConfirmFactories, setIsOpenConfirmFactories] = useState(false);
    const [isOpenConfirmRegion, setIsOpenConfirmRegion] = useState(false);
    const [newRegionName, setNewRegionName] = useState("");
    const [refreshRegion, setRefreshRegion] = useState(true);
    const [regionrename, setRegionrename] = useState("");
    const [addRegion, setAddRegion] = useState("");

    const { fetchRegionData, data, loading, error } = useFetchRegion();
    const { fetchFactoryData, Loading } = useFetchFactories();

    const { Option } = Select;
    const { updateEventMetrics } = useEventMetricsUpdater();
    const [regionmap] = useState(new Map());
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [regionDetail, setRegionDetail] = useState();
    const [showEdit, setShowEdit] = useState(null);
    const [handleDropdown, setHandleDropdown] = useState({
        open: false,
        edit: false,
        editId: null,
    });
    const [modalData, setModalData] = useState({
        eventName: "",
        actionType: "",
    });
    useEffect(() => {
        if (refreshRegion && token) {
            fetchRegionData();

            setRefreshRegion(false);
        }
    }, [refreshRegion, user?.role]);

    useEffect(() => {
        if (factories) {
            let factoryArray;
            factoryArray = factories.map((factory) => {
                return { ...factory, key: factory.id };
            });
            setFactories(factoryArray);
        }
    }, []);

    useEffect(() => {
        if (data) {
            data.forEach((value) => {
                regionmap.set(value.id, value.Name);
            });
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setIsLoading(true);
                try {
                    const response = await getFactoriesByRegion(id);

                    const regionFactory = lodash.uniqBy(
                        response.data.message_body.Factories,
                        "id"
                    );
                    setRegionDetail(response.data.message_body);
                    const Factory = [];
                    regionFactory.forEach((factory) => {
                        Factory.push(factory.id);
                    });
                    setSelectedFactory([]);
                    setDefaultFactory(Factory);
                } catch (error) {
                    if (error.response.status == 401) {
                        setRefreshRegion(true);
                        fetchData();
                    } else {
                        console.error(
                            "Error fetching region factories :",
                            error
                        );
                        if (error.response && error.response.data) {
                            message.error(error.response.data.errorMessage);
                        }
                    }
                } finally {
                    setIsLoading(false);
                }
            } else {
                setSelectedFactory([]);
                setDefaultFactory([]);
            }
        };
        if (token) {
            fetchData();
        }
    }, [user?.role, id]);

    const AddFactory = async () => {
        if (selectedFactory.length > 0) {
            let FilterFactories = factories.filter((factory) =>
                selectedFactory.includes(factory.id)
            );
            let result = FilterFactories.map((factory) => factory.Code);
            setSelectedFactoryName(result.join(", "));
            setIsOpenConfirmFactories(true);
        } else {
            ConfirmModal(id);
        }
    };

    const ConfirmModal = async (id) => {
        setIsOpenConfirmFactories(false);
        setIsLoading(true);
        try {
            const response = await setFactoryToRegion(id, selectedFactory);
            setRefreshRegion(true);
            fetchFactoryData();
            message.success(response.data.message);
            closeEditRegionScreen();
        } catch (error) {
            console.error("Error Updating Region Factories event:", error);
            if (error.response && error.response.data) {
                message.error(error.response.data.errorMessage);
            } else {
                message.error(
                    "An error occurred while updating region factories."
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    const closeEditRegionScreen = () => {
        navigate("/AdminTableView?key=1");
    };

    const AddRegionFactory = (eventName, actionType) => {
        setIsOpenConfirmRegion(true);
        setModalData({ eventName, actionType });
    };

    const ConfirmRegion = async () => {
        setIsOpenConfirmRegion(false);
        try {
            const response = await createRegion(newRegionName);
            setId(response.data.message_body.id);
            setDisableInput(true);
            setNewRegionName("");
            ConfirmModal(response.data.message_body.id);
            updateEventMetrics(modalData.eventName, modalData.actionType);
        } catch (error) {
            console.error("Error creating region:", error);
            if (error.response && error.response.data) {
                message.error(error.response.data.errorMessage);
                if (
                    error.response.data.errorMessage ===
                    `Region with Name ${newRegionName} already exists`
                ) {
                    form.setFields([
                        {
                            name: "Region",
                            errors: ["Region Name already exists"],
                        },
                    ]);
                }
            } else {
                message.error("An error occurred while creating new region.");
            }
        }
    };

    const RenameRegion = async (id, name) => {
        try {
            const response = await renameRegion(id, name);
            regionmap.set(id, name);
            setRefreshRegion(true);
            message.success(response.data.message);
        } catch (error) {
            console.error("Error renaming region:", error);
            if (error.response && error.response.data) {
                message.error(error.response.data.errorMessage);
                if (
                    error.response.data.errorMessage ===
                    `Region with Name ${name} already exists`
                ) {
                    form.setFields([
                        {
                            name: "Region",
                            errors: ["Region Name already exists"],
                        },
                    ]);
                }
            } else {
                message.error("An error occurred while renaming region.");
            }
        }
    };

    const CloseModal = () => {
        if (newRegionName || selectedFactory > 0) {
            setOpenCancelModal(true);
        } else {
            closeEditRegionScreen();
        }
    };

    return (
        <Layout>
            <LoggedInComponent>
                <CollapsableMenu> </CollapsableMenu>
                <div className={styles.TableContainer}>
                    <div className={styles.header}>
                        <h4>
                            {/* back button to go back to the Manage Dashboard */}
                            <span>
                                <img
                                    type="link"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    src="/assets/images/back/Group 4495.svg"
                                    alt="back-logo"
                                    onClick={() => CloseModal()}
                                />
                            </span>
                            <span style={{ marginLeft: "20px" }}>
                                Add/Edit Region
                            </span>
                        </h4>
                    </div>
                    <div className={styles.divider}></div>
                    <Form
                        id="RegionEditForm"
                        name="RegionEditForm"
                        form={form}
                        onFinish={() => {
                            id
                                ? AddFactory()
                                : AddRegionFactory(
                                      "createRegion",
                                      "Create Region - Create Region"
                                  );
                        }}
                        onMouseLeave={() => {
                            setHandleDropdown((prev) => ({
                                ...prev,
                                open: false,
                                edit: false,
                            }));
                        }}
                        initialValues={{ region: id }}
                        className={isLoading ? ` ${styles.blur}` : null}>
                        <Col
                            style={{
                                width: "50%",
                                paddingTop: "30px",
                                paddingLeft: "50px",
                            }}>
                            <Form.Item
                                label="Region"
                                name="Region"
                                initialValue={id}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a Region",
                                        transform: (value) => {
                                            if (!id) {
                                                newRegionName &&
                                                    value.set(newRegionName);
                                            } else {
                                                value.set(id);
                                            }
                                        },
                                    },
                                ]}>
                                <Select
                                    style={{
                                        width: "350px",
                                        border: "1px solid",
                                        borderRadius: "4px",
                                    }}
                                    value={id}
                                    optionLabelProp="label"
                                    open={
                                        handleDropdown.open ||
                                        handleDropdown.edit
                                    }
                                    onClick={() => {
                                        setHandleDropdown((prev) => ({
                                            ...prev,
                                            open: !prev.open,
                                        }));
                                    }}
                                    placeholder={id ? "" : newRegionName}
                                    onChange={(value) => {
                                        if (handleDropdown.editId !== value) {
                                            setHandleDropdown((prev) => ({
                                                ...prev,
                                                open: true,
                                            }));
                                        } else {
                                            setHandleDropdown((prev) => ({
                                                ...prev,
                                                open: true,
                                            }));
                                        }
                                        setDisableInput(true);
                                        setNewRegionName("");
                                        setId(value);
                                    }}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Space
                                                style={{
                                                    backgroundColor: "#bfbfbf",
                                                    width: "100%",
                                                    padding: disableInput
                                                        ? "4px"
                                                        : "0px",
                                                }}
                                                onClick={() => {
                                                    setHandleDropdown(
                                                        (prev) => ({
                                                            open: false,
                                                            edit: true,
                                                            editId: null,
                                                        })
                                                    );
                                                    setDisableInput(false);
                                                }}>
                                                <Button
                                                    style={{
                                                        position: "sticky",
                                                    }}
                                                    type="text"
                                                    icon={
                                                        <PlusCircleOutlined />
                                                    }></Button>
                                                {disableInput ? (
                                                    <>Add New </>
                                                ) : (
                                                    <Input
                                                        style={{
                                                            background:
                                                                "transparent",
                                                            border: "none",
                                                            width: "250px",
                                                            textColor: "black",
                                                        }}
                                                        onClick={() =>
                                                            setHandleDropdown(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    edit: true,
                                                                })
                                                            )
                                                        }
                                                        ref={inputRef}
                                                        value={addRegion}
                                                        onChange={(event) => {
                                                            setAddRegion(
                                                                event.target.value.trim()
                                                            );
                                                        }}
                                                        onPressEnter={() => {
                                                            setNewRegionName(
                                                                addRegion
                                                            );
                                                            setHandleDropdown(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    open: false,
                                                                    edit: false,
                                                                })
                                                            );
                                                            setDisableInput(
                                                                false
                                                            );
                                                            setId(null);
                                                            form.setFieldValue(
                                                                null
                                                            );
                                                        }}
                                                        disabled={disableInput}
                                                        onKeyDown={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                )}
                                            </Space>
                                        </>
                                    )}>
                                    {regions.map((value, key) => (
                                        <Option
                                            key={value.id}
                                            value={value.id}
                                            label={value.Name}
                                            onMouseEnter={() =>
                                                setShowEdit(value.id)
                                            }
                                            onMouseLeave={() =>
                                                setShowEdit(null)
                                            }>
                                            <Row
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}>
                                                {handleDropdown.editId ==
                                                value.id ? (
                                                    <Input
                                                        type="text"
                                                        value={regionrename}
                                                        onClick={() => {
                                                            inputRef.current.focus(
                                                                {
                                                                    cursor: "end",
                                                                }
                                                            );
                                                        }}
                                                        onChange={(e) =>
                                                            setRegionrename(
                                                                e.target.value.trim()
                                                            )
                                                        }
                                                        ref={inputRef}
                                                        onKeyDown={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onPressEnter={() => {
                                                            setHandleDropdown(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    open: false,
                                                                    edit: false,
                                                                    editId: null,
                                                                })
                                                            );
                                                            RenameRegion(
                                                                value.id,
                                                                regionrename
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <Col></Col>
                                                        <Col>
                                                            <label>
                                                                {" "}
                                                                {value.Name}
                                                            </label>
                                                        </Col>
                                                    </>
                                                )}
                                                <Col style={{ width: "25px" }}>
                                                    {showEdit == value.id &&
                                                        handleDropdown.editId !=
                                                            value.id && (
                                                            <Button
                                                                icon={
                                                                    <EditOutlined />
                                                                }
                                                                type="text"
                                                                onClick={() => {
                                                                    setAddRegion(
                                                                        ""
                                                                    );
                                                                    setHandleDropdown(
                                                                        (
                                                                            prev
                                                                        ) => ({
                                                                            ...prev,
                                                                            editId: value.id,
                                                                            edit: true,
                                                                        })
                                                                    );
                                                                    setRegionrename(
                                                                        value.Name
                                                                    );
                                                                }}></Button>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col style={{ padding: "30px" }}>
                            <Form.Item
                                label="Factories"
                                name="Factories"
                                style={{ padding: "0px 20px", width: "400px" }}
                                rules={[
                                    {
                                        required: id ? false : true,
                                        transform: (value) => {
                                            if (selectedFactory.length > 0) {
                                                value.set(
                                                    selectedFactory.length
                                                );
                                            }
                                        },
                                        message:
                                            "Please select at least one factory",
                                    },
                                ]}></Form.Item>

                            <RegionFactoryTable
                                factories={factories}
                                id={id}
                                regionmap={regionmap}
                                defaultFactory={defaultFactory}
                                selectedFactory={selectedFactory}
                                setSelectedFactory={setSelectedFactory}
                            />
                        </Col>
                        <div className={styles.divider}></div>
                        <Row
                            justify="space-between"
                            style={{ padding: "10px 50px" }}>
                            <Col>
                                <span style={{ color: "red" }}>*</span> means
                                mandatory
                            </Col>
                            <Row justify="end" gutter={[16, 16]}>
                                <Col>
                                    <Button
                                        type="secondary"
                                        className="secondaryButton"
                                        onClick={() => {
                                            CloseModal();
                                        }}
                                        style={{
                                            width: "100%",
                                            height: 40,
                                        }}>
                                        Cancel
                                    </Button>
                                </Col>
                                {id ? (
                                    <EditRegionFooter
                                        region={regionDetail}
                                        disable={
                                            defaultFactory.length > 0 ||
                                            selectedFactory.length > 0
                                        }
                                    />
                                ) : (
                                    <AddRegionFooter />
                                )}
                            </Row>
                        </Row>
                    </Form>
                </div>
                <ChangesConfirmationModal
                    open={openCancelModal}
                    onCancel={() => setOpenCancelModal(false)}
                    onExit={() => closeEditRegionScreen()}
                />
                <ConfirmationModal
                    title="Confirm Transfer"
                    open={isOpenConfirmFactories}
                    onCancel={() => setIsOpenConfirmFactories(false)}
                    onClickConfirm={() => ConfirmModal(id)}
                    desription={`${selectedFactoryName} will be transferred to ${regionmap.get(
                        id
                    )} Region`}
                />
                <ConfirmationModal
                    title="Confirm"
                    open={isOpenConfirmRegion}
                    onCancel={() => {
                        setIsOpenConfirmRegion(false);
                    }}
                    onClickConfirm={() => ConfirmRegion()}
                    desription={`Are you sure to add "${newRegionName}" ?`}
                />
            </LoggedInComponent>
        </Layout>
    );
};

export default EditRegion;
