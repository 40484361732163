import { Modal } from "antd";
import React, { useState } from "react";
const GuidelinesModal = ({ modalOpen, setModalOpen }) => {
    return (
        <>
            <Modal
                title="Guidelines"
                centered
                visible={modalOpen}
                footer={null}
                onCancel={() => setModalOpen(false)}
                className="guidelineModal">
                {/* css comming from antd-overrid.scss file */}
                <p>
                    The incentive is calculated for the complete team based on
                    the compliance criteria as below :
                    <ol>
                        <li>Team should close at least 3 cases in a month.</li>
                        <li>
                            All compliance variables(T0, T1, T2, T3) should be
                            followed 100%.
                        </li>
                        <li>
                            Team should conduct at least 2 Inache awareness
                            programs per month.
                        </li>
                    </ol>
                </p>
            </Modal>
        </>
    );
};

export { GuidelinesModal };
