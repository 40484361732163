import React, { useState, useEffect } from "react";
import styles from "./TableData.module.css";
import { getCases } from "../../../../Adapters/TableDataByUser";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import useTableSchema from "../../../../hooks/useTableSchema";
import NewCaseTable from "./NewCaseTable";
import InProgressCaseTable from "./InProgressCaseTable";
import ResolvedCaseTable from "./ResolvedCaseTable";
import ClosedCaseTable from "./ClosedCaseTable";
import { Tabs } from "antd";
import { getCaseDetailsByID } from "../../../../Adapters/CaseCRUDCalls";
import { caseReportDataState } from "../../../../RecoilState/caseReportDataState";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { valueUpdateFlag } from "../../../../RecoilState/valueUpdateFlag";
import { flagState } from "../../../../RecoilState/flagState";
import { currentSelectedRoleState } from "../../../../RecoilState/GlobalUserRoleStates/currentSelectedRoleState";
import { factoryState } from "../../../../RecoilState/FactoryState";
import { previousRouteData } from "../../../../RecoilState/previousRouteData";
import { FactoryData } from "../../../../RecoilState/FactoryData";
import useEventMetricsUpdater from "../../../../hooks/useEventMetricsUpdater";

export default function TableData({ user }) {
    const location = useLocation();
    const [previousRoute, setPreviousRoute] = useRecoilState(previousRouteData);
    const [searchParams] = useSearchParams();
    const [FactoryList, setFactoryList] = useRecoilState(FactoryData);
    const isCritical = searchParams.get("critical");
    const [mainTabKey, setMainTabKey] = useState();
    const [caseDatastate, setcaseDatastate] =
        useRecoilState(caseReportDataState);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const navigate = useNavigate();
    const [cases, setCases] = useState([]);
    const [flag, setFlagState] = useRecoilState(flagState);
    const { updateEventMetrics } = useEventMetricsUpdater();
    const onTabChange = (key) => {
        setPreviousRoute((prev) => ({
            defaultRoute: prev.defaultRoute,
            activeTab: key,
        }));
        setMainTabKey(key);
    };
    useEffect(() => {
        let key = previousRoute.activeTab;
        if (location?.state?.tabKey) {
            key = location?.state.tabKey;
        } else if (!previousRoute.activeTab) {
            key = !isCritical || isCritical === "true" ? "1" : "2";
        }
        setPreviousRoute((prev) => ({
            defaultRoute: location.pathname + location.search,
            activeTab: key,
        }));
        setMainTabKey(key);
    }, [location]);

    const [filterTableData, setFilterTable] = useState([]);
    const [FactorySelected, setFactorySelected] = useRecoilState(factoryState);
    const [updateValue, setUpdateValue] = useRecoilState(valueUpdateFlag);
    const tabMap = {
        1: "new_cases",
        2: "in_progress_cases",
        3: "resolved_cases",
        4: "closed_cases",
        5: "approved_cases",
    };
    const columns = useTableSchema(cases, previousRoute.activeTab);

    const [currentSelectedRole, setCurrentSelectedRole] = useRecoilState(
        currentSelectedRoleState
    );

    const filteredColumns = columns.filter((column) => {
        // check if any row in dataSource has non-empty value for the column's dataIndex
        return cases.some((data) => data[column.dataIndex]);
    });

    const handleRowClick = (id) => {
        setUpdateValue(!updateValue);
        getCaseDetailsByID(id).then((res) => {
            setcaseDatastate(res.data);
            navigate(`/CaseReport/${id}`, { state: { myData: res.data } });
        });
        updateEventMetrics(
            "caseClick",
            "Case clicked from the dashborad",
            "caseID",
            id
        );
    };

    useEffect(() => {
        // If FactorySelected already has a value, do nothing
        if (FactorySelected) return;

        // If user is SUPER_ADMIN or REGIONAL_ADMIN and FactorySelected is not set
        if (user.role === "SUPER_ADMIN" || user.role === "REGIONAL_ADMIN") {
            setFactorySelected(FactoryList[0]);
        } else {
            // Otherwise, set FactorySelected based on the user's factory_fk
            const selectedFactory =
                FactoryList &&
                FactoryList?.find((f) => f.id === user.factory_fk);
            if (selectedFactory) {
                setFactorySelected(selectedFactory);
            }
        }
    }, [FactoryList, FactorySelected, user]);

    useEffect(() => {
        setActiveTab(tabMap[mainTabKey] || "default");
        setFilterTable([]);
    }, [user, mainTabKey]);
    useEffect(() => {
        if (user && (currentSelectedRole != null || undefined || "")) {
            setLoading(true);
            //need to refactor this code based on User RA aand critical false as there we will not have factoryFK

            FactorySelected?.id &&
                activeTab !== "default" &&
                getCases(
                    isCritical,
                    activeTab,
                    user.role === "SUPER_ADMIN" ||
                        user.role === "REGIONAL_ADMIN"
                        ? FactorySelected?.id
                        : user.factory_fk,
                    user.company_fk
                )
                    .then((res) => {
                        setLoading(false);
                        setCases(res.data);
                    })
                    .catch((err) => setCases([]));
        }
    }, [user, activeTab, flag, FactorySelected?.id]);

    const onSearch = (value) => {
        const filterTableData = cases.filter((record) => {
            return Object.keys(record).some((key) =>
                String(record[key]).toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilterTable(filterTableData);
    };

    const items = [
        user.role !== "SUPER_ADMIN" &&
            user.role !== "FACTORY_ADMIN" &&
            location.search !== "?critical=false" && {
                key: "1",
                label: `New / Active`,
                children: (
                    <NewCaseTable
                        loading={loading}
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filterTableData}
                    />
                ),
            },
        // In-progress tab will not be visible in CTs dashboard
        user.role !== "CT" &&
            location.search !== "?critical=true" && {
                key: "2",
                label: `In-progress`,
                children: (
                    <InProgressCaseTable
                        loading={loading}
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filterTableData}
                    />
                ),
            },
        user.role !== "SUPER_ADMIN" &&
            user.role !== "FACTORY_ADMIN" &&
            location.search !== "?critical=false" && {
                key: "3",
                label: `Resolved`,
                children: (
                    <ResolvedCaseTable
                        loading={loading}
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filterTableData}
                    />
                ),
            },
        {
            key: "4",
            label: `Closed`,
            children: (
                <ClosedCaseTable
                    loading={loading}
                    columns={filteredColumns}
                    cases={cases}
                    handleRowClick={handleRowClick}
                    filterTableData={filterTableData}
                />
            ),
        },
        (user.role === "SUPER_ADMIN" ||
            user.role === "FACTORY_ADMIN" ||
            user.role === "REGIONAL_ADMIN") &&
            location.search === "?critical=false" && {
                key: "5",
                label: `Approved`,
                children: (
                    <ClosedCaseTable
                        columns={filteredColumns}
                        cases={cases}
                        handleRowClick={handleRowClick}
                        filterTableData={filterTableData}
                    />
                ),
            },
    ];

    return (
        <>
            <div className={styles.TableData}>
                <div
                    style={{
                        position: "relative",
                        minHeight: "90%",
                    }}>
                    <Tabs
                        activeKey={mainTabKey || "1"}
                        items={items}
                        onChange={onTabChange}
                    />
                    <div style={{ position: "absolute", top: 7, right: 10 }}>
                        <Input
                            placeholder="Search Table"
                            prefix={<SearchOutlined />}
                            allowClear
                            onChange={(e) => onSearch(e.target.value)}
                            style={{
                                width: 200,
                                marginRight: 8,
                                border: 0,
                                borderBottom: "1px solid #1B2559",
                            }}
                        />
                    </div>
                </div>

                {(mainTabKey === "1" && user.role === "CR") ||
                user.role === "REGIONAL_ADMIN" ? null : (
                    <div className={styles.PriorityDisplay}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}></div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <div
                                style={{
                                    background: "red",
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    margin: "5px",
                                }}></div>
                            High Priority
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <div
                                style={{
                                    background: "orange",
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    margin: "5px",
                                }}></div>
                            Medium Priority
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                            <div
                                style={{
                                    background: "yellow",
                                    width: "8px",
                                    height: "8px",
                                    borderRadius: "50%",
                                    margin: "5px",
                                }}></div>
                            Low Priority
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
